import {DISPLAY_AVAILABILITY_PANEL, HIDE_AVAILABILITY_PANEL} from '../constants/actionTypes';
import {makeTechnicianAvailable, makeTechnicianUnavailable} from '../services/unavailability';
import {updateBookingsSuccess} from './calendarActions';
import {loadBookingsFromDb, updateBookings} from '../api/bookingsApi';
import {addAudit} from "../api/auditApi";
import {AuditAction} from "../model/dtos/AuditAction";
import {BookingPage} from "../model/BookingPage";

export function showAvailabilityPanel (){
  return(dispatch => {
    dispatch({type:DISPLAY_AVAILABILITY_PANEL});
  });
}

export function hideAvailabilityPanel (){
  return(dispatch => {
    dispatch({type:HIDE_AVAILABILITY_PANEL});
  });
}

export function makeUnavailable (bookingPage:BookingPage,technician, slotNumber) {
  return async (dispatch,getState) => {
    const serverBooking = await loadBookingsFromDb(bookingPage.date);
    const newBookings = makeTechnicianUnavailable(serverBooking,technician,slotNumber);
    const newBookingPage = {...bookingPage,bookings:newBookings};
    await updateBookings(newBookingPage);
    const {authentication} = getState();
    await addAudit(authentication.userName, AuditAction.SetUnavailable, bookingPage.date.format("YYYY-MM-DD"), {
      technician,
      slotNumber
    });
    dispatch(updateBookingsSuccess(newBookingPage));
  };
}

export function makeAvailable (bookingPage:BookingPage,technician, slotNumber) {
  return async (dispatch,getState) => {
    const serverBooking = await loadBookingsFromDb(bookingPage.date);
    const newBookings = makeTechnicianAvailable(serverBooking,technician,slotNumber);
    const newBookingPage = {...bookingPage,bookings:newBookings};
    await updateBookings(newBookingPage);
    const {authentication} = getState();
    await addAudit(authentication.userName, AuditAction.SetAvailable, bookingPage.date.format("YYYY-MM-DD"), {
      technician,
      slotNumber
    });
    dispatch(updateBookingsSuccess(newBookingPage));
  };
}
