import fetchJson from './fetchJson';
import {organizationalUrl} from "../services/urlProvider";
import {putToBackend} from "./backendApi";

export const loadTreatmentsFromDb =  () => {
  const url = organizationalUrl();
    return fetchJson(`${url}/treatments.json`);
};

export const updateTreatmentsToDb = treatments => {
  const url = organizationalUrl();
  return putToBackend(`${url}/treatments.json`,treatments);
};
