import React from 'react';
import PropTypes from 'prop-types';

const TipsySubmitButton = ({text,onClick,disabled}) => {
    return (
      <input className="tipsy-submit-button" type="submit" onClick={onClick} value={text} disabled={disabled} />
    );
};

TipsySubmitButton.propTypes = {
  text : PropTypes.string.isRequired,
  onClick : PropTypes.func.isRequired,
  disabled : PropTypes.bool
};

export default TipsySubmitButton;
