import styled from 'styled-components';

export const ModalWindow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.6);
  left: 0px;
  top:0;
  width: 100%;
  height: 100%;
  z-index: 100;
  animation-name: modal-dim;
  animation-duration: 0.3s;
`;

export const ModalBackdrop = styled.div`
  width: ${props => props.wide?'80%':'55%'};
  height: 60%;
  margin: 15% auto;
  background-color: whitesmoke;
  animation-name: modal-enter;
  animation-duration: 0.5s;
  border: 2px solid black;
  border-radius:5px;
  min-height: 250px;
  @media screen and (min-width: 361px) {
    width: 50%;
    height: 55%;
    margin: 5% auto;
  }
  @media screen and (min-width: 413px) {
    width: 40%;
    height: 35%;
    margin: 5% auto;
  }
  overflow:auto;
  @media screen and (min-width: 1025px) {
    width: 30%;
    height: 25%;
  }
`;
