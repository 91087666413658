import React from "react";
import {TreatmentButtonBarWrapper} from "./Atoms";
import TipsyButton from '../../common/TipsyButton';

const TreatmentButtonBar = ({onAdd})=>{
  return <TreatmentButtonBarWrapper>
    <TipsyButton text="Add Group" onClick={onAdd}>Hello</TipsyButton>
  </TreatmentButtonBarWrapper>
}

export default TreatmentButtonBar
