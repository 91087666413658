import fetchJson from './fetchJson';
import bookingToSummaryMapper from '../services/bookingToSummaryMapper';
import {organizationalUrl} from "../services/urlProvider";
import {mapDtoToBookings} from "../services/mappers/mapDtoToBookings";

export const fetchDailyReportSummary = (date) => {
  const bookingDate = date.format("YYYY-MM-DD");
  const url = organizationalUrl();
    return fetchJson(`${url}/bookings/${bookingDate}.json`).then(bookingDto => {
        const booking = mapDtoToBookings(bookingDto);
        const summary = bookingToSummaryMapper(booking);
        return summary;
  });
};
