import React from 'react';
import PropTypes from 'prop-types';
import {TECHNICIAN_AVAILABLE_PAGE, TECHNICIAN_UNAVAILABLE_PAGE} from '../../constants/editTechnican';
import {WizardButton, WizardButtonContainer} from '../common/Atoms';
import {WizardStartText, WizardStartWrapper} from '../editWizard/wizardStartPage/Atoms';

const EditTechnicianStartPage = ({technician,setWizardPage,closeModal}) => {
    const makeUnavailable = ()=> {
        setWizardPage(TECHNICIAN_UNAVAILABLE_PAGE);
    };

    const makeAvailable = ()=> {
        setWizardPage(TECHNICIAN_AVAILABLE_PAGE);
    };
    return (
      <WizardStartWrapper>
        <WizardStartText>
          <span>Technician: {technician}</span>
        </WizardStartText>
        <WizardButtonContainer>
          <WizardButton  type="button" value="Unavailable all day" onClick={makeUnavailable} />
          <WizardButton type="button" value="Available all day"  onClick={makeAvailable} />
          <WizardButton type="button" value="Return to Calendar" onClick={closeModal}/>
        </WizardButtonContainer>
      </WizardStartWrapper>
    );
};

EditTechnicianStartPage.propTypes = {
    setWizardPage : PropTypes.func.isRequired,
    closeModal : PropTypes.func.isRequired,
    technician : PropTypes.string.isRequired
};

export default EditTechnicianStartPage;
