export interface IndexableObject { [index:string]:any}

export const deleteObjectProperty = (theObject : IndexableObject,path : string):any=>{
  if(path.indexOf(".")<0){
    const {[path]:omit,...objectWithoutProperty} = theObject;
    //omit;
    return objectWithoutProperty;
  }
  const childPath = path.split('.')[0];
  const restPath = path.substring(childPath.length+1);
  const childObj = theObject[childPath];
  if(!childObj){
    return theObject;
  }
  const newChild = deleteObjectProperty(childObj,restPath);
  return {...theObject,[childPath]:newChild};
};

export const updateObjectProperty = (theObject : Object,path : string,newValue:any):any=>{
  if(path.indexOf(".")<0) {
    return {...theObject, [path]: newValue};
  }
  const childPath = path.split('.')[0];
  const restPath = path.substring(childPath.length+1);
  const childObj = theObject[childPath];
  if(!childObj){
    return theObject;
  }
  const newChild = updateObjectProperty(childObj,restPath,newValue);
  return {...theObject,[childPath]:newChild};
};
