import styled from 'styled-components';

export const SystemDatePickerWrapper = styled.div`
  z-index: 99;
  width: 220px;
  margin-left: 20px;
  margin-top: 5px;
`;

export const SystemDatePickerTextInput = styled.input`
  width:110px;
  border: 1px solid black;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 2px 2px 3px #666;
`;
