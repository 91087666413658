import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import SidePanelMenu from '../common/sidePanelMenu/SidePanelMenu';
import SidePanelButton from '../common/sidePanelMenu/SidePanelButton';
import AdminPanel from './AdminPanel';
import {AdminPageWrapper} from "./Atoms";
import MobileNavBar from "../common/MobileNavBar";
import {TREATMENT_ADMIN_PAGE, USER_ADMIN_PAGE} from "../../constants/adminPages";
import {bindActionCreators} from "redux";
import * as actions from '../../actions/adminAction';

export class AdminPage extends React.Component {
    constructor(props, context) {
        super(props, context);
      this.state = {
        showMobileSidePanel : false
      };
      this.hideSidePanel = this.hideSidePanel.bind(this);
      this.showSidePanel = this.showSidePanel.bind(this);
      this.showUserPanel = this.showUserPanel.bind(this);
      this.showTreatmentPanel = this.showTreatmentPanel.bind(this);
    }

  isMobile = {
    Android: function() {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
      return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
      return (this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows());
    }
  };

  showSidePanel (){
    this.setState (state => {return {...state,showMobileSidePanel:true};});
  }

  hideSidePanel(){
    this.setState({showMobileSidePanel:false});
  }

  showUserPanel(){
    this.hideSidePanel();
    this.props.actions.setAdminPage(USER_ADMIN_PAGE);
  }

  showTreatmentPanel(){
    this.hideSidePanel();
    this.props.actions.setAdminPage(TREATMENT_ADMIN_PAGE);
  }

    render() {
        return (
          <AdminPageWrapper>
            {(!this.isMobile.any() || this.state.showMobileSidePanel) && <SidePanelMenu>
              <SidePanelButton text="Users" onClick={this.showUserPanel}/>
              <SidePanelButton text="Treatment" onClick={this.showTreatmentPanel}/>
            </SidePanelMenu>}
            {(!this.state.showMobileSidePanel) && <AdminPanel page={this.props.page}>
              {this.isMobile.any() && <MobileNavBar onClick={this.showSidePanel} />}
            </AdminPanel>}
          </AdminPageWrapper>
        );
    }
}

AdminPage.propTypes = {
    page : PropTypes.string.isRequired,
    actions : PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        page: state.admin.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
