import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {displayTreatmentPanel} from '../../../actions/treatmentPanelActions';
import {showAvailabilityPanel} from '../../../actions/availabilityActions';
import SystemDatePicker from '../systemDatePicker/SystemDatePicker';
import {setDate} from '../../../actions/systemDateActions';
import {loadBookings} from '../../../actions/calendarActions';
import TipsyButton from '../TipsyButton';

export class Toolbar extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.setDate=this.setDate.bind(this);
    }

    setDate (date){
      this.props.actions.setDate(date);
      this.props.actions.loadBookings(date);
    }

    render() {
        return (
          <div className="toolbar">
            <div className="main-header">{this.props.title}</div>
            <div className="button-bar">
              <TipsyButton text="Make a Booking" onClick={this.props.actions.displayTreatmentPanel}/>
              <TipsyButton text="Availability" onClick={this.props.actions.displayAvailability} disabled={false} />
            </div>
            <SystemDatePicker viewDate={this.props.viewDate} setDate={this.setDate} />
          </div>
        );
    }
}

Toolbar.propTypes = {
    actions : PropTypes.object.isRequired,
    viewDate : PropTypes.object.isRequired,
    title : PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {
        viewDate: state.viewDate,
        title: state.theme.title
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
          displayTreatmentPanel : () => {dispatch(displayTreatmentPanel());},
          displayAvailability : () => {dispatch(showAvailabilityPanel());},
          setDate: newDate => {dispatch(setDate(newDate));},
          loadBookings: newDate => {dispatch(loadBookings(newDate));},
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
