import React from 'react';
import AuditLogItemHeader from './AuditLogItemHeader';
import AuditLogItemBody from './AuditLogItemBody';
import {AuditLogItemWrapper} from "./Atoms";
import {AuditEntry} from "../../../model/AuditEntry";

export interface AuditLogItemProps {
  audit : AuditEntry
}

const AuditLogItem: React.FunctionComponent<AuditLogItemProps> = ({audit}) => {
    const hasDetail = (audit.type !== 'Cancel Booking' && audit.type !== 'Set Available All Day'&& audit.type !== 'Set Unavailable All Day');
    return (
        <AuditLogItemWrapper>
          <AuditLogItemHeader audit={audit} />
          {hasDetail && <AuditLogItemBody audit={audit} />}
        </AuditLogItemWrapper>
    );
};

export default AuditLogItem;
