import {Bookings} from "../model/Bookings";
import {Treatment} from "../model/Treatment";

const equipmentTypeOccupancy = (bookings:Bookings,treatments:Treatment[],equipmentTypeCode:string):number[]=>{
  const result = new Array(40).fill(0);
  const technicianKeys = Object.keys(bookings);
  technicianKeys.forEach(key => {
    const bookingSlots = bookings[key].slots;
    const slotKeys = Object.keys(bookingSlots);
    slotKeys.forEach(slotkey => {
      const slot = bookingSlots[slotkey];
      const treatment = treatments.filter(tm => tm.name === slot.treatment)[0];
      if (treatment?.equipmentTypeCode&&treatment.equipmentTypeCode===equipmentTypeCode){
        for(let i = slot.start; i < slot.end+1;i++){
          result[i]++;
        }
      }
    })
  })
  return result;
}

export default equipmentTypeOccupancy;
