import styled from 'styled-components';

export const WizardStartWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 2fr 1fr;
  height:100%;
`;

export const WizardStartText = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  font-size: 2em;
`;
