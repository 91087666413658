import {CLOSE_MODAL, SET_WIZARD_PAGE} from '../constants/actionTypes';
import {START_PAGE} from '../constants/editPages';

export const setWizardPage = page => {
  return dispatch => {
    dispatch({type:SET_WIZARD_PAGE,page});
  };
};

export const closeWizardPage = () => {
  return dispatch => {
    dispatch({type:SET_WIZARD_PAGE,page:START_PAGE});
    dispatch({type:CLOSE_MODAL});
  };
};
