import React from 'react';
import PropTypes from 'prop-types';
import CalendarCell from './CalendarCell';
import moment from 'moment';
import {DatePickerCalendarBody, DatePickerCalendarHeader} from './Atoms';

const CalendarPanel = ({startDay,endDay,updateDay}) => {
    const divs = ['S','M','T','W','T','F','S'].map((day,idx) => {
      return <DatePickerCalendarHeader key={day+idx}>{day}</DatePickerCalendarHeader>;
    });

    let currentDay = startDay;
     while(currentDay <= endDay){
       divs.push(<CalendarCell value={currentDay} updateField={updateDay} key={currentDay}  />);
       currentDay = moment(currentDay).add(1,'days');
     }

    return (
      <DatePickerCalendarBody>
        {divs}
      </DatePickerCalendarBody>
    );
};

CalendarPanel.propTypes = {
  startDay : PropTypes.object.isRequired,
  endDay : PropTypes.object.isRequired,
  updateDay : PropTypes.func.isRequired
};

export default CalendarPanel;
