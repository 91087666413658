import {BookingsSet} from "../model/BookingsSet";
import {Booking} from "../model/Booking";
import {Rule} from "../model/Rule";
import {calculateTechnicianUnavailabilityFromRules} from "./unavailability";
import * as moment from "moment";

export const createBookingSet = (technicians:string[],date:moment.Moment,rules?:Rule[]):BookingsSet=>{
  const bookingsSet = {};
  technicians.forEach((technician,index) => {
    const booking = new Booking(technician,{});
    booking.sortOrder = index;
    booking.unavailability = calculateTechnicianUnavailabilityFromRules(technician,date,rules);
    bookingsSet[technician] = booking;
  });
  return bookingsSet;
};
