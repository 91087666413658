import styled from 'styled-components';

export const AcceptPaymentPageWrapper = styled.div`
  display : flex;
  flex-direction: column;
  height:100%;
  align-items: center;
`;

export const AcceptPaymentHeader = styled.div`
  font-size: 1.6em ;
  text-align: center;
  margin:10px;
`;

export const AcceptPaymentBody = styled.div`
  flex-grow:1;
  display: flex;
    flex-direction: column;
    align-items: center;
`;

export const AcceptPaymentButtonPanel = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  height:30px;
  margin:10px;
  width: 100%;
`;

export const AcceptPaymentGrouping = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin:10px;
  &> input {
    width: 70px;
  }
  width:100%;
`;


