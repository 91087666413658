import * as React from 'react';
import {NavLink} from 'react-router-dom';
import NavBarWrapper from "./NavBarWrapper";

const NavBar = ({userRoles}) => {
    return (
        <NavBarWrapper>
          <NavLink exact to="/" activeClassName="nav-item-active">Home</NavLink>
          {userRoles["reports"]&&<NavLink to="/Reports" activeClassName="nav-item-active">Reports</NavLink>}
          {userRoles["search"]&&<NavLink to="/Search" activeClassName="nav-item-active">Search</NavLink>}
          {userRoles["admin"]&&<NavLink to="/Admin" activeClassName="nav-item-active">Admin</NavLink>}
        </NavBarWrapper>
    );
};

export default NavBar;
