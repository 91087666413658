import React from 'react';
import './app.scss';
import {BrowserRouter as Router} from 'react-router-dom';
import '@babel/polyfill';
import TipsyApp from './TipsyApp';
import {hot} from 'react-hot-loader/root';

const App = () => {
  return (
      <Router>
        <TipsyApp/>
      </Router>
  );
};

export default hot(App);
