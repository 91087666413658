import React from 'react';
import {connect} from 'react-redux';
import {NORMAL_MODE} from '../../common/modes';
import {openModal} from '../../actions/modalActions';
import {CalendarCellButton, CalendarCellWrapper, ConfirmedIcon} from './Atoms';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {SlotCell} from "../../model/SlotCell";
import EditWizard from "../editWizard/EditWizard";
import {BookingSlot} from "../../model/BookingSlot";

interface MyProps{
  mode : string;
  cellData : SlotCell;
  slot : BookingSlot;
  technician:string;
}

interface DispatchProps{
  openModal : typeof openModal
}

type Props = MyProps & DispatchProps


export const CalendarCellOccupied = ({cellData,slot,technician,mode,openModal}:Props)=>{
  const editSlot= ()=>{
    const payment = (slot.paid) ;
    openModal(()=> {return(
      <EditWizard data={{technician,customerName:slot.customerName,
        slotNumber:cellData.index,customerTelephone:slot.customerTelephone,
        noShow:slot.noShow,confirmed:slot.confirmed,comments:slot.comments,paid:payment}} />
    );});
  };

  const calculateColor = (noShow:boolean,multiPayment:boolean,paid:boolean,text:string):string=>{
    if(noShow && text.includes('NO SHOW')){
      return 'red';
    }
    if(multiPayment){
      return '#00aa99';
    }
    if(paid){
      return 'green';
    }
    return '';
  };

  const showConfirmedIcon = cellData.isFirstCell && slot.confirmed;
  const showNotesIcon = cellData.isFirstCell && slot.comments;
  const showButton = (mode == NORMAL_MODE);
  const textColor = calculateColor(slot.noShow,cellData.multiPayment,cellData.paid,cellData.text);
  return (
    <CalendarCellWrapper occupied first={cellData.isFirstCell} last={cellData.isLastCell} textcolor={textColor}>
      {!showButton && cellData.text}
      {showButton && <CalendarCellButton textcolor={textColor} onClick={editSlot} >
        {showConfirmedIcon && <ConfirmedIcon>*</ConfirmedIcon>}
        {showNotesIcon && <FontAwesomeIcon data-testid="todo-item-delete" icon={faEnvelope} color="white" size="sm"/>}
        {showNotesIcon && <span>&nbsp;</span>}
        {cellData.text}
      </CalendarCellButton>}
    </CalendarCellWrapper>
  );
};

function mapStateToProps(state) {
    return {
        state: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        openModal :(renderFunction,modalParameters)=> dispatch(openModal(renderFunction,modalParameters))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarCellOccupied);
