import styled from 'styled-components';
import {color2} from '../../../constants/color';

export const ConfirmationPageWrapper = styled.div`
  height: 100%;
  width:100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
`;

export const ConfirmationDetails = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: center;
  display:grid;
  grid-template-rows: 1fr 1fr;
`;

export const ConfirmationPrompt = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: center;
  font-size: 1.3em;
`;

export const ConfirmationButtonBar = styled.div`
 display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const ConfirmationButtonContainer = styled.div`
 display: flex;
  justify-content: space-around;
`;

export const ConfirmationButtonWrapper = styled.div`
 border: 1px solid black;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 2px 2px 3px #666;
  width:100px;
  height:30px;
  text-align: center;
  &> button{
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
  }
  background-color:${props=>props.id==='yes'?color2:'#ff4d4d'};
  &>button:hover {
    cursor: pointer;
  }
  &> button > i:after {
    content : ${props=>props.id==='yes'?'"\\2713"':'"\\0058"'};
    font-weight: bold;
  }
`;
