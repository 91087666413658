import initialState from './initialState';
import {SET_ADMIN_PAGE, USERS_LOADED_SUCCESS, USER_UPDATED} from "../constants/actionTypes";

export default (state = initialState.admin,action) =>{
  switch(action.type){
    case SET_ADMIN_PAGE:
      return {...state,page:action.page};
    case USERS_LOADED_SUCCESS:
      return{...state,users:action.users};
    case USER_UPDATED:
      return{...state,users:[...state.users.filter(user => user.name != action.updatedUser.name),action.updatedUser]};
    default:
      return state;
  }
};
