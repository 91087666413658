import moment from 'moment';
import _ from 'lodash';

const lastDayOfMonth = (month,year) => {
  if (month ===1){
    if (moment([year]).isLeapYear()){
      return 29;
    }
    return 28;
  }
  if (_.indexOf([0,2,4,6,7,9,11],month) != -1){
    return 31;
  }
  return 30;
};

const datePanelService = (date) => {
  const current = date;
  const lastMonth = moment(current).subtract(1,'months');
  const previousMonthLastDay = moment(lastMonth).date(lastDayOfMonth(lastMonth.month(),lastMonth.year()));
  const firstDayOfTheCurrentMonth = moment(current).date(1);
  let daysInPreviousMonth = firstDayOfTheCurrentMonth.weekday();
  const startDate = moment(previousMonthLastDay).subtract(daysInPreviousMonth-1,'days');
  const currentMonthLastDay = moment(current).date(lastDayOfMonth(current.month(),current.year()));
  const daysInNextMonth = 35-daysInPreviousMonth-currentMonthLastDay.date();
  const lastDate = moment(currentMonthLastDay).add(daysInNextMonth,'days');
  return {
    startDate,
    endDate : lastDate
  };
};

export default datePanelService;
