import React from 'react';
import TipsyCheckBoxWrapper from './atoms/TipsyInputWrapper';

export interface TipsyCheckboxProps{
  name? : string;
  value : boolean;
  onChange : (e:any)=>void;
  testid? : number;
}

const TipsyCheckbox = ({name,value,onChange,testid}:TipsyCheckboxProps) => {
  return (
    <TipsyCheckBoxWrapper name={name} type="checkbox" checked={value} onChange={onChange} data-testid={testid} />
  );
};

export default TipsyCheckbox;
