import initialState from './initialState';
import {Theme} from "../model/Theme";
import {ThemeAction} from "../actions/themeActions";
import {THEME_LOADED_SUCCESS} from '../constants/actionTypes';

type State = Theme;

const themeReducer = (state:State = initialState.theme,action:ThemeAction) : State=>{
  switch (action.type) {
    case THEME_LOADED_SUCCESS:
      return action.theme;
    default:
      return state;
  }
};

export default themeReducer;
