import initialState from './initialState';
import {LOAD_DAILY_REPORT_SUMMARY_SUCCESS} from '../constants/actionTypes';

export default function (state=initialState.dailySummary,action){
  switch (action.type){
    case LOAD_DAILY_REPORT_SUMMARY_SUCCESS:
      return action.summary;
    default:
      return state;
  }
}
