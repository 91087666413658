import React from 'react';
import TimelineTimeSlot from './TimelineTimeSlot';
import {CalendarTimeLineSpacer, CalendarTimeLineWrapper} from './Atoms';

const Timeline = () => {

  const timeTicks = () => {
    const divs = [];
    for (let i = 8; i < 20; i++){
      const hour = i<13?i:i-12;
      divs.push(<TimelineTimeSlot hour={hour} key={i} />);
    }
    return divs;
  };

    return (
      <CalendarTimeLineWrapper >
        <CalendarTimeLineSpacer />

        {timeTicks()}
      </CalendarTimeLineWrapper>
    );
};

export default Timeline;
