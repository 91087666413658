import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import DatePicker from '../../common/datePicker/DatePicker';
import {DailyReportsToolbarWrapper, ReportDateControlWrapper, ReportDatePickerWrapper} from './Atoms';

class DailyReportToolbar extends React.Component {
  constructor(){
    super();
    this.state = {
      currentDate: moment(),
      showDatePicker: false
    };
    this.goBackOneDay = this.goBackOneDay.bind(this);
    this.goForwardOneDay = this.goForwardOneDay.bind(this);
    this.closeDatePicker = this.closeDatePicker.bind(this);
    this.showDatePicker = this.showDatePicker.bind(this);
    this.setDateAndClosePicker = this.setDateAndClosePicker.bind(this);
  }

  componentDidMount() {
    this.props.setDate(this.state.currentDate);
  }

  goBackOneDay()  {
    this.props.setDate(this.state.currentDate.add(-1, 'day'));
  }

  goForwardOneDay () {
    this.props.setDate(this.state.currentDate.add(1, 'day'));
  }

  closeDatePicker(e) {
    const currentTarget = e.currentTarget;

    let that = this;
    setTimeout(function () {
      if (!currentTarget.contains(document.activeElement)) {
        that.setState(state => {
          return {...state, showDatePicker: false};
        });
      }
    }, 1);
  }

  showDatePicker() {
    this.setState(state => {
      return {...state, showDatePicker: true};
    });
  }

  setDateAndClosePicker(date){
      this.props.setDate(date);
      this.setState(state => {
        return {...state, showDatePicker: false,currentDate:date};
      });
  }

  render() {
    return (
      <DailyReportsToolbarWrapper>
        <div onBlur={this.closeDatePicker}>
          <ReportDateControlWrapper>
            <button className="triangle-left" onClick={this.goBackOneDay}/>
            <ReportDatePickerWrapper type="text" value={this.state.currentDate.format("ddd DD MMM YYYY")}
                   onClick={this.showDatePicker} readOnly={true}/>
            <button className="triangle-right" onClick={this.goForwardOneDay}/>
          </ReportDateControlWrapper>
          {this.state.showDatePicker && <DatePicker date={this.state.currentDate} setDate={this.setDateAndClosePicker}/>}
        </div>
      </DailyReportsToolbarWrapper>
    );
  }
}

DailyReportToolbar.propTypes = {
  setDate : PropTypes.func.isRequired
};

export default DailyReportToolbar;
