import {RuleCandidate} from "../model/RuleCandidate";
import {Rule} from "../model/Rule";
import * as _ from 'lodash';

const matchRules = (candidate : RuleCandidate,rules  : Rule[]):any=>{
  let matchedRuleValue = null;
  _.orderBy(rules,'priority','desc').forEach((rule:Rule) => {
    if (!matchedRuleValue && matchRule(candidate,rule)){
      matchedRuleValue = rule.result;
    }
  });
  return matchedRuleValue||{};
};

const propertyMatch = (ruleValue :string|string[],propertyValue:string):boolean=> {
  if(!Array.isArray(ruleValue)){
    return (ruleValue === propertyValue || ruleValue==='*');
  }
  return ruleValue.some(ruleProperty => ruleProperty === propertyValue);
};

const matchRule = (candidate : RuleCandidate,rule  : Rule):boolean=>{
  let matched = true;
  Object.keys(rule.matchingProperties).forEach(key => {
    matched = matched && propertyMatch(rule.matchingProperties[key],candidate[key]);
  });
  return matched;
};

export const resolveRules = (candidate : RuleCandidate,rules  : Rule[]):any=>{
  if(!rules || !rules.length){
    return {};
  }
  return matchRules(candidate,rules);
};
