import styled from 'styled-components';

export const SidePanelMenuWrapper = styled.div`
   background-color: pink;
  display: flex;
  flex-direction: column;
  padding: 10px 5px 10px 3px;
  border: 1px solid grey;
  z-index: 20;
  flex:1;
  @media screen and (min-width: 361px) {
    width: 150px;
    flex:none;
  }
`;

export const SidePanelButtonWrapper = styled.button`
   background-color: lightgrey;
  border: 1px solid black;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 2px 2px 3px #666;
  margin-bottom: 10px;
  cursor: pointer;
`;
