import styled from 'styled-components';

const TipsyInputWrapper = styled.input`
  background-color: white;
  border: 1px solid black;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 2px 2px 3px #666;
`;

export default TipsyInputWrapper;
