import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from '../datePicker/DatePicker';
import {SystemDatePickerTextInput, SystemDatePickerWrapper} from './Atoms';

class SystemDatePicker extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
          showDatePicker : false
        };
      this.setDate = this.setDate.bind(this);
        this.showPicker=this.showPicker.bind(this);
        this.closeDatePicker=this.closeDatePicker.bind(this);
    }

  closeDatePicker(e) {
    const currentTarget = e.currentTarget;

    let that = this;
    setTimeout(function() {
      if (!currentTarget.contains(document.activeElement)) {
        that.setState({showDatePicker : false});
      }
    }, 1);
  }

    setDate (newDate){
      this.props.setDate(newDate);
      this.setState({showDatePicker:false});
    }

    showPicker (){
      this.setState({showDatePicker:true});
    }

    render() {
        return (
          <SystemDatePickerWrapper onBlur={this.closeDatePicker} tabIndex="-1" >
            <SystemDatePickerTextInput type="input" readOnly value={this.props.viewDate.format("ddd DD MMM YYYY")} onClick={this.showPicker} />
            {this.state.showDatePicker && <DatePicker date={this.props.viewDate} setDate={this.setDate}/>}
          </SystemDatePickerWrapper>
        );
    }
}

SystemDatePicker.propTypes = {
    viewDate: PropTypes.object.isRequired,
    setDate: PropTypes.func.isRequired,
};

export default SystemDatePicker;
