import React, {FunctionComponent, useEffect} from 'react';
import {TreatmentsAdminPanel, TreatmentsGroupHeader} from "./Atoms";
import {connect} from "react-redux";
import TreatmentGroupItem from "./TreatmentGroupItem";
import TreatmentButtonBar from "./TreatmentButtonBar";
import {closeModal, openModal} from "../../../actions/modalActions";
import {loadTreatments, addTreatmentGroup,deleteTreatmentGroup,addTreatmentToGroup,deleteTreatmentFromGroup} from "../../../actions/treatmentPanelActions";
import AddTreatmentGroupPanel from "./AddTreatmentGroupPanel";
import YesNoConfirmation from '../../common/confirmation/YesNoConfirmation';
import TreatmentGroupPanel from "./TreatmentGroupPanel";
import AddTreatmentPanel from "./AddTreatmentPanel";
import {Treatment} from "../../../model/Treatment";

interface TreatmentAdminProps{
  treatments:any[],
  actions:any
}

const TreatmentAdmin:FunctionComponent<TreatmentAdminProps> = ({treatments,actions}) => {
  useEffect(()=>{
      actions.loadTreatments();
  },[])

  const addGroupClicked = ()=>{
    const namesInuse = treatments.map(group => group.name);
    actions.openModal(()=> <AddTreatmentGroupPanel exclusionList={namesInuse} onAdd={addGroup} onClose={actions.closeModal}  />);
  }

  const addGroup = (newGroup:String):void=>{
    actions.addTreatmentGroup(newGroup);
    actions.closeModal();
  }

  const showDeletePrompt = (groupName:String)=>{
    actions.openModal(()=> <YesNoConfirmation onYes={()=>deleteGroup(groupName)}
                                              onNo={actions.closeModal}
                                              message="Are you sure you want to delete this group and all its items?"
    >
      <span>Group: {groupName}</span>
    </YesNoConfirmation>);

  }

  const deleteGroup = (groupName:String)=>{
    actions.deleteTreatmentGroup(groupName);
    actions.closeModal();
  }

  const addTreatment = (groupName:String,treatment:Treatment)=>{
    actions.addTreatmentToGroup(groupName,treatment);
    actions.closeModal();
    actions.closeModal();
  }

  const showAddTreatmentPanel = (groupName)=>{
    const namesInUse = (treatments.filter(group => group.name == groupName)[0].items||[]).map(item=>item.name);
    actions.openModal(()=> <AddTreatmentPanel groupName={groupName} exclusionList={namesInUse} onAdd={addTreatment} onClose={actions.closeModal}  />);
  }

  const showGroupDetail = (groupName)=>{
    actions.openModal(()=> <TreatmentGroupPanel onAdd={showAddTreatmentPanel} items={treatments.filter(treatment=>treatment.name===groupName)[0].items||[]} name={groupName} onClose={actions.closeModal} onDelete={deleteTreatment}  />);
  }

  const deleteTreatment = (groupName:String,treatmentName:String)=>{
    actions.deleteTreatmentFromGroup(groupName,treatmentName);
    actions.closeModal();
  }
  return <TreatmentsAdminPanel>
    <TreatmentsGroupHeader>Treatment Groups</TreatmentsGroupHeader>
    {treatments.map ((treatmentGroup,index) => <TreatmentGroupItem key={treatmentGroup.name} onDelete={showDeletePrompt} onEdit={showGroupDetail} evenRow={index%2==0} name={treatmentGroup.name}/>)}
    <TreatmentButtonBar onAdd={addGroupClicked} />
  </TreatmentsAdminPanel>;
};



const mapStateToProps = (state)=>{
  return {
    treatments: state.treatments
  }
};

const mapDispatchToProps = (dispatch)=>{
  return {
    actions: {
      openModal : (renderFunction) => {dispatch(openModal(renderFunction));},
      closeModal : () => {dispatch(closeModal());},
      addTreatmentGroup : (newGroup) => {dispatch(addTreatmentGroup(newGroup));},
      deleteTreatmentGroup : (groupName) => {dispatch(deleteTreatmentGroup(groupName));},
      addTreatmentToGroup : (groupName,treatment) => {dispatch(addTreatmentToGroup(groupName,treatment));},
      loadTreatments : () => {dispatch(loadTreatments());},
      deleteTreatmentFromGroup : (groupName:boolean,treatmentName:boolean)=>{dispatch(deleteTreatmentFromGroup(groupName,treatmentName));}
    }
  };
};


export default connect(mapStateToProps,mapDispatchToProps)(TreatmentAdmin);
