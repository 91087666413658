import React, {FunctionComponent} from "react";
import {TreatmentAdminMenuButton, TreatmentGroupItemWrapper} from "./Atoms";
import {Treatment} from "../../../model/Treatment";

interface TreatmentItemProps{
  treatment:Treatment;
  groupName:String;
  evenRow:boolean;
  onDelete:(group:String,treatment:String)=>void;
}

const durationToDurationString = (duration) =>{
  switch (duration) {
    case 1:
      return "15 minutes";
    case 2:
      return "30 minutes";
    case 3:
      return "45 minutes";
    case 4:
      return "1 hour";
    case 5:
      return "1 hour 15 minutes";
    case 6:
      return "1 hour 30 minutes";
    case 7:
      return "1 hour 45 minutes";
    case 8:
      return "2 hours";
    case 9:
      return "2 hours 15 minutes";
    case 10:
      return "2 hours 30 minutes";
    default:
      return "Who knows";
  }
}

const TreatmentItem:FunctionComponent<TreatmentItemProps> = ({treatment,groupName,evenRow,onDelete})=>{
  const handleDelete = ()=>{
    onDelete(groupName,treatment.name);
  }
  const handleEdit = ()=>{}
  const durationString = durationToDurationString(treatment.duration);
  return <>
    <TreatmentGroupItemWrapper data-testid="treatment-item" evenRow={evenRow}>
      <div>{treatment.name}</div>
    </TreatmentGroupItemWrapper>
    <TreatmentGroupItemWrapper evenRow={evenRow}>
      <div>{durationString}</div>
    </TreatmentGroupItemWrapper>
    <TreatmentGroupItemWrapper evenRow={evenRow}>
      <TreatmentAdminMenuButton data-testid="delete-treatment" style={{color:'red'}} onClick={handleDelete}>X</TreatmentAdminMenuButton>
    </TreatmentGroupItemWrapper>
    <TreatmentGroupItemWrapper evenRow={evenRow}>
      <TreatmentAdminMenuButton style={{color:'green'}} onClick={handleEdit} ></TreatmentAdminMenuButton>
    </TreatmentGroupItemWrapper>
  </>
};

export default TreatmentItem;
