import styled from 'styled-components';

export const AvailabilityPanelWrapper = styled.div`
  background-color: grey;
  height:40px;
  padding  :15px;
  display:grid;
  grid-template-columns: 2fr 1fr;
`;

export const AvailabilityPanelText = styled.span`
  color: lightgray;
  font-size: 1.2em;
`;
