import {Treatment} from "../model/Treatment";
import {BookingSlot} from "../model/BookingSlot";
import {Bookings} from "../model/Bookings";
import {deleteObjectProperty, updateObjectProperty} from "./objectPropertyEditor";
import {getBookingStart} from "./slotUtils";
import {Payment} from "../model/Payment";
import {BookingPage} from "../model/BookingPage";
import {BookingSlotUpdate} from "../model/BookingSlotUpdate";

export const addBooking = (bookingPage:BookingPage, technician:string, customerName:string, treatment:Treatment, startSlot:number,
                           extraTime:number, customerTelephone:string,firstTime:boolean):BookingPage => {
  const newSlot : BookingSlot = {
    start: startSlot,
    end: startSlot + treatment.duration - 1  + extraTime,
    customerName,
    treatment:treatment.name,
    customerTelephone,
    paid:new Payment(),
    noShow:false,
    confirmed:false,
    comments:firstTime?'New client':'',
    firstTime:false
  };
  return updateObjectProperty(bookingPage,`bookings.${technician}.slots.${startSlot}`,newSlot);
};

export const removeBooking = (bookings:Bookings, technician:string, slotNumber:number):Bookings => {
  const startSlot = getBookingStart(technician,slotNumber,bookings);
  return deleteObjectProperty(bookings,`${technician}.slots.${startSlot}`);
};

export const updateCalendarBooking = (bookings:Bookings,technician:string,slotNumber:number,slotUpdates:BookingSlotUpdate):Bookings => {
  let bookingForTechnician = bookings[technician];

  if (!bookingForTechnician){
    return bookings;
  }

  const startSlot = getBookingStart(technician,slotNumber,bookings);
  const originalSlot = bookings[technician].slots[startSlot];
  const updatedSlot = {...originalSlot,...slotUpdates};
  let resultingBookings = bookings;
  if(originalSlot.start != updatedSlot.start){
    resultingBookings = deleteObjectProperty(resultingBookings,`${technician}.slots.${startSlot}`)
  }
  return updateObjectProperty(resultingBookings,`${technician}.slots.${updatedSlot.start}`,updatedSlot);
};

export const moveSlotToDifferentTechnician = (bookings: Bookings,fromTechnician:string,toTechnician:string,slotNumber:number):Bookings=>{
  const theFromTechnician = bookings[fromTechnician];
  if(!theFromTechnician){
    return bookings;
  }

  const theToTechnician = bookings[toTechnician];
  if(!theToTechnician){
    return bookings;
  }

  const startSlot = getBookingStart(fromTechnician,slotNumber,bookings);
  const theSlotToMove = theFromTechnician.slots[startSlot];
  const bookingsWithSlotRemoved = deleteObjectProperty(bookings,`${fromTechnician}.slots.${startSlot}`);
  return updateObjectProperty(bookingsWithSlotRemoved,`${toTechnician}.slots.${startSlot}`,theSlotToMove);
};
