import React from 'react';
import {TREATMENT_ADMIN_PAGE, USER_ADMIN_PAGE} from '../../constants/adminPages';
import {AdminPanelWrapper} from "./Atoms";
import UserAdmin from "./userAdmin/UserAdmin";
import TreatmentAdmin from "./treatmentAdmin/TreatmentAdmin";

export interface AdminPanelProps{
  page : number;
  children : number;
}

const AdminPanel = ({page,children}:AdminPanelProps) => {
    return (
      <AdminPanelWrapper>
        {children}
        {(page === USER_ADMIN_PAGE) && <UserAdmin />}
        {(page === TREATMENT_ADMIN_PAGE) && <TreatmentAdmin />}
      </AdminPanelWrapper>
    );
};

export default AdminPanel;
