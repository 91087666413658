import {CLOSE_MODAL, SET_TECHNICIAN_WIZARD_PAGE} from '../constants/actionTypes';
import {START_PAGE} from '../constants/editPages';
import {showSpinner} from './spinnerActions';
import {updateBookings} from '../api/bookingsApi';
import {updateBookingsSuccess} from './calendarActions';
import {Bookings} from "../model/Bookings";
import {updateObjectProperty} from "../services/objectPropertyEditor";
import {addAudit} from "../api/auditApi";
import {AuditAction} from "../model/dtos/AuditAction";
import {BookingPage} from "../model/BookingPage";

export const closeTechnicianWizard = () => {
  return dispatch => {
    dispatch({type:SET_TECHNICIAN_WIZARD_PAGE,page:START_PAGE});
    dispatch({type:CLOSE_MODAL});
  };
};

export const setTechnicianWizardPage = page => {
  return dispatch => {
    dispatch({type:SET_TECHNICIAN_WIZARD_PAGE,page});
  };
};

const setAvailable = (bookings, technician):Bookings => {
  return updateObjectProperty(bookings,`${technician}.unavailability`,[]);
};

const setUnavailable = (bookings:Bookings, technician:string):Bookings => {
  return updateObjectProperty(bookings,`${technician}.unavailability`,[{start:0,end:47}]);
};

export function makeTechnicianAvailable (bookingPage:BookingPage,technician){
  return async (dispatch,getState) => {
    dispatch(showSpinner());
    const newBookings = setAvailable(bookingPage.bookings,technician);
    const newBookingPage = {...bookingPage,bookings:newBookings};
    await updateBookings(newBookingPage);
    const {authentication} = getState();
    await addAudit(authentication.userName, AuditAction.SetAvailableAllDay, bookingPage.date.format("YYYY-MM-DD"), {
      technician
    });
      dispatch(updateBookingsSuccess(newBookingPage));
      dispatch({type:SET_TECHNICIAN_WIZARD_PAGE,page:START_PAGE});
      dispatch({type:CLOSE_MODAL});
  };
}

export function makeTechnicianUnavailable (bookingPage:BookingPage,technician){
  return async (dispatch,getState) => {
    dispatch(showSpinner());

    const newBookings = setUnavailable(bookingPage.bookings,technician);
    const newBookingPage : BookingPage = {...bookingPage,bookings:newBookings}
    await updateBookings(newBookingPage);
    const {authentication} = getState();
    await addAudit(authentication.userName, AuditAction.SetUnavailableAllDay, bookingPage.date.format("YYYY-MM-DD"), {
      technician
    });
    dispatch(updateBookingsSuccess(newBookingPage));
    dispatch({type:SET_TECHNICIAN_WIZARD_PAGE,page:START_PAGE});
    dispatch({type:CLOSE_MODAL});
  };
}


