export class Payment{
  cashValue : number;
  cardValue : number;
  voucherValue : number;
  note:string;

  constructor() {
    this.cashValue=0;
    this.cardValue=0;
    this.voucherValue=0;
    this.note='';
  }
}
