import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions/authActions';
//import './loginPage.scss';
import TipsySubmitButton from '../common/TipsySubmitButton';
import {LoginForm, LoginPageError, LoginPageInput, LoginPageText, LoginSection} from './Atoms';

export class LoginPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.doLogin = this.doLogin.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
    }

    doLogin (e){
      e.preventDefault();
      this.props.actions.login(this.props.userName.trim(),this.props.password.trim());
    }

    updateUser(e){
      this.props.actions.updateLoginUser(e.target.value);
    }

    updatePassword(e){
      this.props.actions.updateLoginPassword(e.target.value);
    }

    render() {
        return (
          <LoginSection>
            <LoginPageText>You need to login to use this site</LoginPageText>
            <LoginForm name="login-form" target="#here" onSubmit={this.doLogin}>
              <LoginPageInput type="text" value={this.props.userName} placeholder="User Name" onChange={this.updateUser} />
              <LoginPageInput type="password" value={this.props.password} placeholder="Password" onChange={this.updatePassword} />
              <TipsySubmitButton onClick={this.doLogin} text="Login"/>
            </LoginForm>

            <LoginPageError>{this.props.loginError}</LoginPageError>
          </LoginSection>

        );
    }
}

LoginPage.propTypes = {
    actions : PropTypes.object.isRequired,
    userName : PropTypes.string.isRequired,
    password : PropTypes.string.isRequired,
    loginError : PropTypes.string.isRequired,
};

function mapStateToProps(state) {
    return {
        userName: state.authentication.userName,
        password: state.authentication.password,
        loginError: state.authentication.error
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
