import React from 'react';
import {connect} from 'react-redux';
import Timeline from './Timeline';
import BookingCalendarColumn from './BookingCalendarColumn';
import * as _ from 'lodash';
import {loadBookings, loadBookingsSuccess, makeBooking} from '../../actions/calendarActions';
import {makeAvailable, makeUnavailable} from '../../actions/availabilityActions';
import {loadTreatments, resetTreatmentPanel} from '../../actions/treatmentPanelActions';
import {openModal} from '../../actions/modalActions';
import {addNewCustomer, loadCustomers} from '../../actions/customerActions';
import EditTechnician from '../editTechnician/EditTechnician';
import {CalendarBody, CalendarWrapper} from './Atoms';
import startRtu from "../../common/rtu";
import {loadTheme} from "../../actions/themeActions";
import {mapDtoToBookings} from "../../services/mappers/mapDtoToBookings";
import {equipmentTypeMaxedSlots} from "../../services/equipmentTypeMaxedSlots";
import equipmentTypeOccupancy from "../../services/equipmentTypeOccupancy";
import moment from "moment";
import {BookingPage} from "../../model/BookingPage";
import {Customer} from "../../model/Customer";
import {Treatment} from "../../model/Treatment";

interface MyProps {
  viewDate: moment.Moment;
  bookingPage: BookingPage;
  mode: string;
  treatmentDuration: number;
  newCustomerName: string;
  extraTime: number;
  newCustomerTelephone: string;
  firstTime: boolean;
  customers : Customer[],
  treatment : Treatment,
  treatments : Treatment[]
}

type MyState = {
  count: number;
};

interface DispatchProps {
  loadBookings: typeof loadBookings;
  loadTreatments: typeof loadTreatments;
  loadCustomers: typeof loadCustomers;
  loadTheme: typeof loadTheme;
  loadBookingsSuccess: typeof loadBookingsSuccess;
  makeUnavailable: typeof makeUnavailable;
  makeAvailable: typeof makeAvailable;
  makeBooking: typeof makeBooking;
  resetTreatmentPanel: typeof resetTreatmentPanel;
  addNewCustomer: typeof addNewCustomer;
  openModal: typeof openModal;
}

export type BookingCalendarProps = MyProps & DispatchProps

export class BookingCalendar extends React.Component<BookingCalendarProps,MyState> {
    constructor(props, context) {
        super(props, context);
        this.makeBooking = this.makeBooking.bind(this);
        this.makeUnavailable = this.makeUnavailable.bind(this);
        this.makeAvailable = this.makeAvailable.bind(this);
        this.editTechnician = this.editTechnician.bind(this);
        this.recieveDataFromRtu = this.recieveDataFromRtu.bind(this);
    }

  componentDidMount() {
    this.props.loadBookings(this.props.viewDate);
    this.props.loadTreatments();
    this.props.loadCustomers();
    this.props.loadTheme();
    startRtu(this.recieveDataFromRtu);
  }


    recieveDataFromRtu(key, dto) {
      if (this.props.viewDate.format("YYYY-MM-DD") === key) {
        const bookings:BookingPage = {bookings:mapDtoToBookings(dto),date:this.props.viewDate};
        this.props.loadBookingsSuccess(bookings);
      }
    }

    makeUnavailable(technician, slotNumber) {
      this.props.makeUnavailable(this.props.bookingPage, technician, slotNumber);
    }

    makeAvailable(technician, slotNumber) {
      this.props.makeAvailable(this.props.bookingPage,technician, slotNumber);
    }

    makeBooking (technician,startSlot) {
      this.props.makeBooking(this.props.bookingPage,technician,this.props.newCustomerName,this.props.treatment,
        startSlot,this.props.extraTime,this.props.newCustomerTelephone,this.props.firstTime);
      this.props.resetTreatmentPanel();
      if(this.props.customers.filter (customer => {
          return customer.name === this.props.newCustomerName && customer.telephone === this.props.newCustomerTelephone;
      }).length === 0){
        this.props.addNewCustomer({name:this.props.newCustomerName,telephone:this.props.newCustomerTelephone});
      }
    }

    editTechnician (technician){
      this.props.openModal(()=> {return(
        <EditTechnician data={{technician}} />
      );});
    }

    render() {
      const treatment = this.props.treatments.filter(tr => tr.name == this.props.treatment.name)[0];
      const equipmentOccupancy = equipmentTypeOccupancy(this.props.bookingPage.bookings,this.props.treatments,treatment?.equipmentTypeCode);
      const equipmentSlots = equipmentTypeMaxedSlots(equipmentOccupancy,1);
        return (
          <CalendarWrapper >
            <Timeline />
            <CalendarBody>
              {_.orderBy(this.props.bookingPage.bookings,'sortOrder').map(booking=>{
                return(
                  <BookingCalendarColumn title={booking.technician}
                                         slots={booking.slots||{}}
                                         treatmentDuration={this.props.treatmentDuration+this.props.extraTime}
                                         unavailability={booking.unavailability||{}}
                                         key={booking.technician}
                                         mode={this.props.mode}
                                         onMakeBooking={this.makeBooking}
                                         onMakeUnavailable={this.makeUnavailable}
                                         onMakeAvailable={this.makeAvailable}
                                         onEditTechnician={this.editTechnician}
                                         equipmentSlots={equipmentSlots}
                  />
                );
              })}
            </CalendarBody>
          </CalendarWrapper>
        );
    }
}

function mapStateToProps(state) {
  const flatMap = (f,xs) =>
    xs.reduce((acc,x) =>
      acc.concat(f(x)), []);
    return {
        bookingPage: state.bookings,
        treatmentDuration : state.treatmentPanel.treatment.duration,
        treatment : state.treatmentPanel.treatment,
        newCustomerName : state.treatmentPanel.customerName,
        mode: state.mode,
        viewDate: state.viewDate,
        extraTime : state.treatmentPanel.extraTime,
        newCustomerTelephone : state.treatmentPanel.customerTelephone,
        customers: state.customers,
        treatments : flatMap(group => group.items,state.treatments),
      firstTime : state.treatmentPanel.firstTime
    };
}

function mapDispatchToProps(dispatch) {
    return {
          loadBookings : (date) => {dispatch(loadBookings(date));},
          loadBookingsSuccess: data => dispatch(loadBookingsSuccess(data)),
          loadTreatments : () => {dispatch(loadTreatments());},
          loadCustomers : () => {dispatch(loadCustomers());},
          makeBooking : (bookings,technician,customerName,treatment,startSlot,extraTime,phoneNumber,firstTime) => {dispatch(makeBooking(bookings,technician,customerName,treatment,startSlot,extraTime,phoneNumber,firstTime));},
          makeUnavailable : (bookings, technician, slotNumber) => {dispatch(makeUnavailable(bookings, technician, slotNumber));},
          makeAvailable : (bookings,technician, slotNumber) => {dispatch(makeAvailable(bookings,technician, slotNumber));},
          resetTreatmentPanel : () => {dispatch(resetTreatmentPanel());},
          openModal : (render) => {dispatch(openModal(render));},
          addNewCustomer : (customer) => {dispatch(addNewCustomer(customer));},
          loadTheme : () => {dispatch(loadTheme());}
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingCalendar);
