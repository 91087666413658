
type State = BookingPage;

import initialState from './initialState';
import {LOAD_BOOKING_SUCCESS, UPDATE_BOOKINGS_SUCCESS} from '../constants/actionTypes';
import {BookingAction} from "../actions/calendarActions";
import {BookingPage} from "../model/BookingPage";

export default function bookings(state:State = initialState.bookings, action:BookingAction): State {
  switch (action.type) {
    case LOAD_BOOKING_SUCCESS:
      return action.bookingPage;
    case UPDATE_BOOKINGS_SUCCESS:
      return action.bookingPage;
    default :
      return state;
  }
}
