import React from 'react';
import {connect} from 'react-redux';
import WizardStartPage from './wizardStartPage/WizardStartPage';
import {
  ACCEPT_PAYMENT_PAGE, CANCEL_BOOKING_PAGE, EDIT_BOOKING_PAGE, MOVE_BOOKING_TECHNICIAN_PAGE, NO_SHOW_PAGE,
  START_PAGE, TOGGLE_CONFIRMATION_PAGE
} from '../../constants/editPages';
import {closeWizardPage, setWizardPage} from '../../actions/editWizardAction';
import {cancelBooking, moveSlotToOtherTechnician, updateBooking} from '../../actions/calendarActions';
import EditBookingPage from './editBookingPage/EditBookingPage';
import AcceptPaymentPage from './acceptPayment/AcceptPaymentPage';
import {getBookingStart, getSlotTime} from '../../services/slotUtils';
import YesNoConfirmation from '../common/confirmation/YesNoConfirmation';
import MoveBookingToTechnician from "./moveBooking/MoveBookingToTechnician";
import {getAvailableTechnicians} from "../../services/technicianAvailability";
import {getSlotByIdAndTechnician} from "../../services/bookingServices";
import {Payment} from "../../model/Payment";
import {BookingPage} from "../../model/BookingPage";

export interface EditWizardProps {
  actions : EditWizardActions,
  page : string,
  bookingPage : BookingPage,
  data : any,
}

export interface EditWizardActions {
  setWizardPage:typeof setWizardPage;
  closeWizard:typeof closeWizardPage;
  cancelBooking:typeof cancelBooking;
  updateBooking:typeof updateBooking;
  moveSlotToOtherTechnician:typeof moveSlotToOtherTechnician;
}

export class EditWizard extends React.Component<EditWizardProps> {
  constructor(props) {
    super(props);
    this.returnToStartPage = this.returnToStartPage.bind(this);
    this.cancelBooking = this.cancelBooking.bind(this);
    this.toggleConfirmation = this.toggleConfirmation.bind(this);
    this.acceptPayment = this.acceptPayment.bind(this);
    this.setNoShow = this.setNoShow.bind(this);
    this.moveSlotToTechnician = this.moveSlotToTechnician.bind(this);
  }

  returnToStartPage () {
    this.props.actions.setWizardPage(START_PAGE);
  }

  cancelBooking () {
    this.props.actions.cancelBooking(this.props.bookingPage,this.props.data.technician,this.props.data.slotNumber);
    this.props.actions.closeWizard();
  }


  toggleConfirmation () {
    this.props.actions.updateBooking(this.props.bookingPage,this.props.data.technician,this.props.data.slotNumber,{confirmed:!this.props.data.confirmed});
    this.props.actions.closeWizard();
  }

  acceptPayment (payment:Payment){
    this.props.actions.updateBooking(this.props.bookingPage,this.props.data.technician,this.props.data.slotNumber,{paid:payment});
    this.props.actions.closeWizard();
  }

  setNoShow(){
    this.props.actions.updateBooking(this.props.bookingPage,this.props.data.technician,this.props.data.slotNumber,{noShow:true,paid:null});
    this.props.actions.closeWizard();
  }

  moveSlotToTechnician(newTechnician){
    this.props.actions.moveSlotToOtherTechnician(this.props.bookingPage,this.props.data.technician,newTechnician,this.props.data.slotNumber);
    this.props.actions.closeWizard();
  }

  render() {
    const startSlot = getBookingStart (this.props.data.technician,this.props.data.slotNumber,this.props.bookingPage.bookings);
    switch (this.props.page) {
      case START_PAGE:
        return (
          <WizardStartPage noShow={this.props.data.noShow}
                           closeModal={this.props.actions.closeWizard}
                           setWizardPage={this.props.actions.setWizardPage}
                           confirmed={this.props.data.confirmed}
          />
        );
      case CANCEL_BOOKING_PAGE:{

        return (
          <YesNoConfirmation message="Are you sure you want to cancel this booking?"
                             onNo={this.returnToStartPage}
                             onYes={this.cancelBooking}>
            <span>{this.props.data.customerName} booked with {this.props.data.technician}</span>
            <span>At: {getSlotTime(startSlot)}</span>
          </YesNoConfirmation>
        );
      }
      case NO_SHOW_PAGE:
        return (
          <YesNoConfirmation message="Are you sure you want to set this booking as a NO SHOW?"
                             onNo={this.returnToStartPage}
                             onYes={this.setNoShow}>
            <span>{this.props.data.customerName} booked with {this.props.data.technician}</span>
            <span>At: {getSlotTime(startSlot)}</span>
          </YesNoConfirmation>
        );
      case TOGGLE_CONFIRMATION_PAGE:
        return (
          <YesNoConfirmation message={this.props.data.confirmed?"Are you sure you want to un-confirm this booking?":"Are you sure you want to confirm this booking?"}
                             onNo={this.returnToStartPage}
                             onYes={this.toggleConfirmation}>
            <span>{this.props.data.customerName} booked with {this.props.data.technician}</span>
            <span>At: {getSlotTime(startSlot)}</span>
          </YesNoConfirmation>
        );
      case EDIT_BOOKING_PAGE:
        return (
          <EditBookingPage bookingPage={this.props.bookingPage}
                           technician={this.props.data.technician}
                           slotNumber={this.props.data.slotNumber}
                           customerName={this.props.data.customerName}
                           customerTelephone={this.props.data.customerTelephone}
                           comments={this.props.data.comments}
          />
        );
      case MOVE_BOOKING_TECHNICIAN_PAGE:
      {
        const {start,end} =getSlotByIdAndTechnician(this.props.bookingPage.bookings,this.props.data.slotNumber,this.props.data.technician);
        return (
          <MoveBookingToTechnician
                                   availableTechnicians={getAvailableTechnicians(this.props.bookingPage.bookings,start,end)}
                                   onExit={this.returnToStartPage}
                                   onSelectTechnician={this.moveSlotToTechnician}
          >
            <span>{this.props.data.customerName} booked with {this.props.data.technician}</span>
            <span>At: {getSlotTime(startSlot)}</span>
          </MoveBookingToTechnician>
        );}
      case ACCEPT_PAYMENT_PAGE:
        return(
          <AcceptPaymentPage paid={this.props.data.paid} onClose={this.returnToStartPage} onAccept={this.acceptPayment} />
        );
      default:
        return (
          <div/>
        );
    }

  }
}

function mapStateToProps(state) {
  return {
    page: state.editWizard.page,
    bookingPage : state.bookings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      closeWizard: () => { dispatch(closeWizardPage());},
      setWizardPage: (page) => {dispatch(setWizardPage(page));},
      cancelBooking: (bookingPage:BookingPage,technician,slotNumber) => {dispatch(cancelBooking(bookingPage,technician,slotNumber));},
      updateBooking: (bookingPage:BookingPage,technician,slotNumber,newBooking) => {dispatch(updateBooking(bookingPage,technician,slotNumber,newBooking));},
      moveSlotToOtherTechnician: (bookings,oldTechnician,newTechnician,slotNumber)=>{dispatch(moveSlotToOtherTechnician(bookings,oldTechnician,newTechnician,slotNumber));}
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditWizard);
