import React from 'react';
import BookingCalendar from './calendar/BookingCalendar';
import AvailabilityPanel from './unavailability/AvailabilityPanel';
import TreatmentPanel from './treatmentPanel/TreatmentPanel';
import Toolbar from './common/toolbar/Toolbar';

const BookingPage = () => {
    return (
        <div>
          <Toolbar/>
          <TreatmentPanel/>
          <AvailabilityPanel/>
          <BookingCalendar/>
          <div className="footer"/>
        </div>
    );
};

BookingPage.propTypes = {};

export default BookingPage;
