import React from 'react';

import SidePanelMenu from '../common/sidePanelMenu/SidePanelMenu';
import MobileNavBar from '../common/MobileNavBar';
import DailyReportPage from './dailyReport/DailyReportPage';
import ReportsBody from './ReportsBody';
import {ReportsPageWrapper} from './Atoms';
import {SidePanelButtonWrapper} from '../common/sidePanelMenu/Atoms';

class ReportsPage extends React.Component {

  constructor (){
    super();
    this.state = {
      showMobileSidePanel : false
    };
    this.showSidePanel = this.showSidePanel.bind(this);
    this.hideSidePanel = this.hideSidePanel.bind(this);
  }

  isMobile = window.innerWidth < 361;

  showSidePanel (){
    this.setState (state => {return {...state,showMobileSidePanel:true};});
  }

  hideSidePanel (){
    this.setState (state => {return {...state,showMobileSidePanel:false};});
  }

  render (){
    return (
      <React.Fragment>

        <ReportsPageWrapper>
          {(!this.isMobile || this.state.showMobileSidePanel)  && <SidePanelMenu>
            <SidePanelButtonWrapper onClick={this.hideSidePanel}>Daily Report</SidePanelButtonWrapper>
          </SidePanelMenu>}
          {(!this.state.showMobileSidePanel) &&<ReportsBody>
            {this.isMobile && <MobileNavBar onClick={this.showSidePanel} />}
            <DailyReportPage />
          </ReportsBody>}
        </ReportsPageWrapper>
      </React.Fragment>
    );
  }
}

ReportsPage.propTypes = {};

export default ReportsPage;
