import moment from "moment";
import db from '../services/indexedb';

const api_key = 'AIzaSyDl8VlJ1HPqF38cWPGUcTZzuTBYCZlXiSE';
const url = `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${api_key}`;

export const authenticateWithFireBase = async ()=>{
  try{
    const dbToken = await db.table('token').get('activeToken');
    const {token,expiry} = dbToken?JSON.parse(dbToken) : {};
    const expiryDate = expiry?moment(new Date(expiry)):null;
    if(!token || !expiry || moment().isAfter(expiryDate)){
      const response = await fetch(url,{
        method:'POST',
        cache:'no-cache',
        headers:{
          'Content-Type': 'application/json'
        },
        body : JSON.stringify({returnSecureToken:true})
      });
      const responseBody = await response.json();
      const newToken = responseBody.idToken;
      const safeExpiryPeriod =  responseBody.expiresIn -15;
      const now = moment();
      const newExpiry = now.add(safeExpiryPeriod,'seconds');
      await db.table('token').put(JSON.stringify({token:newToken,expiry:newExpiry}),'activeToken');
      return newToken;
    }
    return token;

  }catch{
    return null;
  }


};
