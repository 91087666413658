import {TimeSlot} from "../model/TimeSlot";
import {deleteObjectProperty} from "./objectPropertyEditor";

export function removeSlot (slots:{[index:number]:TimeSlot}, slotNumber:number):{[index:string]:TimeSlot} {
  let slotContainingSlotnumber = null;
  Object.keys(slots).forEach(slotKey => {
    const slot = slots[slotKey];
    if(slot.start <= slotNumber && slot.end >= slotNumber){
      slotContainingSlotnumber = slot;
    }
  });
  if(!slotContainingSlotnumber){
    return slots;
  }
  const slotsWithoutContainingSlot = deleteObjectProperty(slots,`${slotContainingSlotnumber.start}`);
  if(slotContainingSlotnumber.start === slotNumber && slotContainingSlotnumber.end === slotNumber){
    return slotsWithoutContainingSlot;
  }
  if(slotContainingSlotnumber.start === slotNumber){
    return {...slotsWithoutContainingSlot,[slotContainingSlotnumber.start+1]:{start:slotContainingSlotnumber.start+1,end:slotContainingSlotnumber.end}};
  }
  if(slotContainingSlotnumber.end === slotNumber){
    return {...slotsWithoutContainingSlot,[slotContainingSlotnumber.start]:{start:slotContainingSlotnumber.start,end:slotContainingSlotnumber.end-1}};
  }
  return {
    ...slotsWithoutContainingSlot,
    [slotContainingSlotnumber.start]:{start:slotContainingSlotnumber.start,end:slotNumber-1},
    [slotNumber+1]:{start:slotNumber+1,end:slotContainingSlotnumber.end}
  };
}

export const removeSlots=(slots:{[index:number]:TimeSlot}, slotStart:number, slotEnd:number):{[index:string]:TimeSlot} =>{
  let theSlots = slots;
  for(let slotNumber=slotStart;slotNumber<=slotEnd;slotNumber++){
    theSlots = removeSlot(theSlots,slotNumber);
  }
  return theSlots;
}
