
import _ from 'lodash';
import {Bookings} from "../model/Bookings";

export function getAvailableTechnicians (bookings : Bookings,start:number,end:number):string[]{
  if(bookings){
    return Object.keys(bookings).filter(key => {
      return _.every(bookings[key].slots,slot => {
        return (start < slot.start && end <slot.start) || (start >slot.end);
      }) && _.every(bookings[key].unavailability,slot => {
        return (start < slot.start && end <slot.start) || (start >slot.end);
      });
    }).map(key => {
      return bookings[key].technician;
    });
  }
  return [];
}
