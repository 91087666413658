import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {SpinnerBackPlane, SpinnerBody, SpinnerText} from './Atoms';

export class Spinner extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
      if(this.props.spinnerShowing){
        return (
          <SpinnerBackPlane >
            <SpinnerBody />
            <SpinnerText>Please Wait</SpinnerText>
          </SpinnerBackPlane>
        );
      }else{
        return (
          <div/>
        );
      }
    }
}

Spinner.propTypes = {
    spinnerShowing : PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        spinnerShowing: state.spinner
    };
}

function mapDispatchToProps() {
    return {
        //actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Spinner);
