import {LOAD_DAILY_REPORT_SUMMARY_SUCCESS} from '../constants/actionTypes';
import {fetchDailyReportSummary} from '../api/reportsApi';
import {hideSpinner, showSpinner} from './spinnerActions';

export const loadDailyReportSummarySuccess = summary => {
    return {type:LOAD_DAILY_REPORT_SUMMARY_SUCCESS,summary};
};

export const loadDailyReportSummary = (date) => {
    return dispatch => {
      dispatch(showSpinner());
        return fetchDailyReportSummary(date).then(summary => {
          dispatch(loadDailyReportSummarySuccess(summary));
          dispatch(hideSpinner());
        }).catch(() => {
          dispatch(loadDailyReportSummarySuccess([]));
          dispatch(hideSpinner());
        });
    };
};
