import {Booking} from "../../model/Booking";
import {BookingPage} from "../../model/BookingPage";

export const mapBookingsToDto = (bookingsPage:BookingPage):Booking[]=>{
  const bookingsDto = [];
  Object.keys(bookingsPage.bookings).forEach(key => {
    const booking = bookingsPage.bookings[key];
    const slotsDto = [];
    Object.keys(booking.slots).forEach(slotKey => {
      slotsDto.push(booking.slots[slotKey]);
    });
    const unavailabilityDto = [];
    if(booking.unavailability){
      Object.keys(booking.unavailability).forEach(unavailabilityKey => {
        unavailabilityDto.push(booking.unavailability[unavailabilityKey]);
      });
    }
    const bookingDto = {...booking,slots:slotsDto,unavailability:unavailabilityDto};
    bookingsDto.push(bookingDto);
  });
  return bookingsDto;
};
