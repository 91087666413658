export const signatureTuesdayWednesdayTemplate = [
  {
    technician:"Rachel" ,
    sortOrder:2,
    slots:[],
    unavailability:[]
  },
  {
    technician:"Rina" ,
    sortOrder:1,
    slots:[],
    unavailability:[
      {start:20,end:47}
    ]
  },
  {
    technician:"Karen",
    sortOrder:3,
    slots:[],
    unavailability:[]
  }
];
