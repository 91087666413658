export const DISPLAY_TREATMENT_PANEL="DISPLAY_TREATMENT_PANEL";
export const HIDE_TREATMENT_PANEL="HIDE_TREATMENT_PANEL";
export const UPDATE_INPUT_CUSTOMER_NAME="UPDATE_INPUT_CUSTOMER_NAME";
export const UPDATE_INPUT_CUSTOMER_TELEPHONE="UPDATE_INPUT_CUSTOMER_TELEPHONE";
export const UPDATE_INPUT_TREATMENT="UPDATE_INPUT_TREATMENT";
export const UPDATE_INPUT_TREATMENT_TYPE="UPDATE_INPUT_TREATMENT_TYPE";
export const UPDATE_INPUT_EXTRA_TIME="UPDATE_INPUT_EXTRA_TIME";
export const UPDATE_INPUT_CUSTOMER_FIRST_TIME="UPDATE_INPUT_CUSTOMER_FIRST_TIME";
export const CANCEL_BOOKING_SUCCESS="CANCEL_BOOKING_SUCCESS";
export const LOAD_BOOKING_SUCCESS="LOAD_BOOKING_SUCCESS";
export const UPDATE_BOOKINGS_SUCCESS="UPDATE_BOOKINGS_SUCCESS";
export const DISPLAY_AVAILABILITY_PANEL="DISPLAY_AVAILABILITY_PANEL";
export const HIDE_AVAILABILITY_PANEL="HIDE_AVAILABILITY_PANEL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const CLOSE_ALL_MODALS = "CLOSE_ALL_MODALS";
export const OPEN_MODAL = "OPEN_MODAL";
export const SHOW_SPINNER = "SHOW_SPINNER";
export const HIDE_SPINNER = "HIDE_SPINNER";
export const SET_WIZARD_PAGE = "SET_WIZARD_PAGE";
export const SET_SYSTEM_DATE = "SET_SYSTEM_DATE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const SET_LOGIN_USER = "SET_LOGIN_USER";
export const SET_LOGIN_PASSWORD = "SET_LOGIN_PASSWORD";
export const SET_LOGIN_ROLES = "SET_LOGIN_ROLES";
export const SET_LOGIN_ORGANIZATION = "SET_LOGIN_ORGANIZATION";
export const LOAD_TREATMENTS_SUCCESS = "LOAD_TREATMENTS_SUCCESS";
export const RESET_TREATMENT_PANEL = "RESET_TREATMENT_PANEL";
export const HIDE_CUSTOMER_LOOKUP = "HIDE_CUSTOMER_LOOKUP";
export const SET_TECHNICIAN_WIZARD_PAGE = "SET_TECHNICIAN_WIZARD_PAGE";
export const CUSTOMERS_LOADED_SUCCESS = "CUSTOMERS_LOADED_SUCCESS";
export const CUSTOMER_ADDED_SUCCESS = "CUSTOMER_ADDED_SUCCESS";
export const LOAD_DAILY_REPORT_SUMMARY_SUCCESS = "LOAD_DAILY_REPORT_SUMMARY_SUCCESS";
export const ERROR_OCCURRED = "ERROR_OCCURRED";
export const SET_ADMIN_PAGE = "SET_ADMIN_PAGE";
export const USERS_LOADED_SUCCESS = "USERS_LOADED_SUCCESS";
export const USER_UPDATED = "USER_UPDATED";
export const THEME_LOADED_SUCCESS = "THEME_LOADED_SUCCESS";
export const UPDATE_TREATMENTS_LIST_SUCCESS = "UPDATE_TREATMENTS_LIST_SUCCESS";
