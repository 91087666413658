import styled from 'styled-components';

export const UserList = styled.div`
  display:grid;
  grid-template-columns : 6fr 6fr 3fr 1fr;
  padding:10px;
`;

export const UserListItemWrapper = styled.div`
  background-color:${props=>props.evenRow?'lightgrey':'white'};
  padding-left:2px;
`;

export const UserListCheckMark = styled.div`
  color : ${props => !props.checked ? 'green' : 'red'};
  font-weight : bold;
  &> i:after {
    content : ${props => !props.checked  ? '"\\2713"' : '"\\0058"'};
    font-weight: bold;
  }
`;

export const UserAdminPageWrapper = styled.div`
  background-color:pink;
  height:100%
`;

export const UserAdminListHeader = styled.div`
  font-weight:bold;
`;

export const UserAdminMenuButton = styled.button`
  border:none;
  background-color : transparent;
  cursor:pointer;
  &:focus {outline:0;}
  font-weight:bold;
`;

export const ActionMenuWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 2fr 1fr;
  height:100%;
`;

export const ActionMenuText = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  font-size: 2em;
`;
