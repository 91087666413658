import React from 'react';
import PropTypes from 'prop-types';
import {UserAdminMenuButton, UserListCheckMark, UserListItemWrapper} from "./Atoms";

const jsUcfirst= (string)=>
{
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const UserListItem = ({user,evenRow,onClickActionMenu})=>{
  const handleButtonClick = ()=>{
    onClickActionMenu(user);
  };

  return (<React.Fragment>
    <UserListItemWrapper  data-testid="admin-user-list-item" evenRow={evenRow}>
      {jsUcfirst(user.name)}
    </UserListItemWrapper>
    <UserListItemWrapper evenRow={evenRow}>
      {user.lastLoggedIn!=='1970-01-01'?user.lastLoggedIn:'Never'}
    </UserListItemWrapper>
    <UserListItemWrapper evenRow={evenRow}>
      <UserListCheckMark checked={user.disabled} ><i/></UserListCheckMark>
    </UserListItemWrapper>
    <UserListItemWrapper evenRow={evenRow}>
      <UserAdminMenuButton data-testid="user-admin-menu" onClick={handleButtonClick}>:</UserAdminMenuButton>
    </UserListItemWrapper>

  </React.Fragment>);
};

UserListItem.propTypes = {
  user : PropTypes.object.isRequired,
  evenRow : PropTypes.bool.isRequired,
  onClickActionMenu : PropTypes.func.isRequired
};

export default UserListItem;
