import {Bookings} from "../../model/Bookings";
import {BookingDto} from "../../model/dtos/BookingDto";
import {Payment} from "../../model/Payment";
import mapDtoToPayment from "./mapDtoToPayment";

export const mapDtoToBookings = (bookingsDto : BookingDto[]):Bookings=>{
  const bookings = {};
  bookingsDto.forEach(booking => {
    const bookingSlots = {};
    if(booking.slots && booking.slots.length){
      booking.slots.forEach(slot => {
        bookingSlots[slot.start] = slot;

        const payment = mapDtoToPayment(slot.paid||new Payment());
        slot.paid =payment;
      });
    }
    const unavilabilitySlots = {};
    if(booking.unavailability && booking.unavailability.length){
      booking.unavailability.forEach(slot => {
        unavilabilitySlots[slot.start] = slot;
      });
    }
    bookings[booking.technician] = {
      technician:booking.technician,
      slots : bookingSlots,
      unavailability:unavilabilitySlots,
      sortOrder : booking.sortOrder
    };
  });
  return bookings;
};
