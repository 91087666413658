import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {ModalBackdrop, ModalWindow} from './Atoms';

export class ModalContainer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

  getContent(){
        if (this.props.modalState.render && this.props.modalState.render.length>0){
          return (this.props.modalState.render[this.props.modalState.render.length-1]());
        }else{
          return(<div/>);
        }
  }

    render() {
      const wideScreen = this.props.modalState.modalParameters[this.props.modalState.modalParameters.length-1] && this.props.modalState.modalParameters[this.props.modalState.modalParameters.length-1].wide;
      if(this.props.modalState.render.length){
        return (
          <ModalWindow>
            <ModalBackdrop wide={wideScreen}>{this.getContent()}</ModalBackdrop>
          </ModalWindow>
        );
      }else {
        return (
          <div />
        );
      }
    }
}

ModalContainer.propTypes = {
    modalState : PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
      modalState: state.modalState
    };
}

function mapDispatchToProps() {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
