import initialState from './initialState';
import {LOAD_TREATMENTS_SUCCESS, UPDATE_TREATMENTS_LIST_SUCCESS} from '../constants/actionTypes';


export default function  (state=initialState.treatments,action){

  switch (action.type){
    case LOAD_TREATMENTS_SUCCESS:
      return action.treatments;
    case UPDATE_TREATMENTS_LIST_SUCCESS:
      return action.treatments;
    default:
      return state;
  }
}
