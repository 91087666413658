import styled from 'styled-components';

export const AuditLogFilterPanel = styled.div`
   background-color: pink;
  padding: 5px;
  display:grid;
  flex-wrap: wrap;
  justify-content: space-around;
  grid-template-columns: 1fr 1fr;
  & .tipsy-input {
    width:100px;
  margin-top: 5px;
  }
  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const AuditLogItemPanel = styled.div`
   background-color: orange;
  display: flex;
  flex-direction: column;
`;

export const AuditLogItemWrapper = styled.div`
   background-color: lightgrey;
  border:2px solid black;
`;

export const AuditLogItemHeaderWrapper = styled.div`
   height: 70px;
  border-bottom: 1px solid gray;
  display:flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
  @media screen and (min-width: 361px) {
    height: 50px;
  }
`;

export const AuditLogItemBodyWrapper = styled.div`
   flex:1;
  display:flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
`;
