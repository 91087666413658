import {Provider} from 'react-redux';
import store from './store/storeProvider';
import {render} from 'react-dom';
import * as React from 'react';
import App from './components/App';

render(
  <Provider store={store} >
    <App />
  </Provider>
,document.getElementById('app')
);

