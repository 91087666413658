import Dexie from 'dexie/dist/dexie';

const db = new Dexie('Tipsy');
db.version(1).stores({
  users: '',
});
db.version(2).stores({
  users: '',
  bookings : ''
});
db.version(3).stores({
  users: '',
  bookings : '',
  token:''
});

export default db;
