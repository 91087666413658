import styled from 'styled-components';

export const WizardButton = styled.input`
   margin:5px;
  background-color: ${props=>props.alert?'red':'lightpink'};
  border: 1px solid black;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 3px 3px 4px #666;
  cursor: pointer;
  &:active {
    box-shadow: 1px 1px 2px #999;
    transform: translateY(2px);
  }  
`;

export const WizardButtonContainer = styled.div`
    grid-column-start: 2;
  grid-column-end: 2;
  vertical-align: middle;
  display: inline-block;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

