import moment from 'moment';
import {AuditDto} from "../model/dtos/audit/AuditDto";
import {AuditEntry} from "../model/AuditEntry";
import {AuditAction} from "../model/dtos/AuditAction";

const calculateTime = (offset:number):string => {
  const date = moment(`2010-01-01T08:00:00`);
  date.add(offset*15,'minutes');
  return date.format("HH:mm");
};

const mapObjectToKeyValuePairs = obj => {
  const keys = Object.keys(obj);
  const result = [];
  keys.forEach(key => {
    result.push({field:key,value:obj[key]});
  });
  return result;
};

function isBookingAction(action: AuditAction) {
  return action===AuditAction.UpdateBooking || action===AuditAction.MakeBooking || action===AuditAction.RemoveBooking ;
}

function mapBookingAudit(dto: AuditDto):AuditEntry {
  const audit : any = {

  };
  switch (dto.action){
    case 'Remove Booking':
      audit.bookingTime=calculateTime(dto.data.slotNumber);
      audit.type = 'Cancel Booking';
      break;
    case 'Make Booking':
      audit.customerName=dto.data.customerName;
      audit.bookingTime=calculateTime(dto.data.startSlot);
      audit.duration=dto.data.treatment.duration*15;
      audit.treatment=dto.data.treatment.name;
      audit.extraTime=dto.data.extraTime*15;
      audit.telephone=dto.data.telephone;
      break;
    case 'Update Booking':
      audit.bookingTime=calculateTime(dto.data.slotNumber);
      audit.changes=mapObjectToKeyValuePairs(dto.data.changes);
      break;
  }
  return audit;
}

function mapAvailabilityAudit(dto: AuditDto) {
  const audit={
    slotNumber:dto.data.slotNumber,
    slotStartTime:calculateTime(dto.data.slotNumber)
  }
  return audit;
}

export const mapFromDtoToAudit=(dto:AuditDto):AuditEntry=>{
  let audit;
  if(isBookingAction(dto.action)){
    audit= mapBookingAudit(dto);
  }else{
    audit= mapAvailabilityAudit(dto);
  }
  audit.type=audit.type||dto.action;
  audit.user=dto.user;
  audit.timeStamp=moment(dto.timestamp);
  audit.technician=dto.data.technician;
  audit.key=dto.key;
  audit.bookingDate = dto.entityId;
  return audit;
};
