import React, {useState} from "react";
import {
  ActionMenuText,
  AddTreatmentInputGroup,
  AddTreatmentPanelWrapper,
  TreatmentButtonBar,
  TreatmentButtonBox
} from "./Atoms";
import TipsyButton from '../../common/TipsyButton';
import TipsyInput from '../../common/TipsyInput';
import {color2} from '../../../constants/color';
import {Treatment} from "../../../model/Treatment";
import uniqid from 'uniqid';

interface AddTreatmentPanelProps{
  groupName:String,
  exclusionList:String[],
  onAdd:(groupName:String,treatment:Treatment)=>any,
  onClose:()=>{}
}

const AddTreatmentPanel:React.FunctionComponent<AddTreatmentPanelProps> = ({groupName,exclusionList,onAdd,onClose})=>{
  const [newTreatment,setNewTreatment] = useState('');
  const [addEnabled,setAddEnabled] = useState(false);
  const [duration,setDuration] = useState(0);
  const handleSubmit = ()=>{
    onAdd(groupName,{name:newTreatment,duration,id:uniqid()});
  }
  const handleChange = (e)=>{
    const treatmentName = e.target.value;
    setNewTreatment(treatmentName);
    setAddEnabled(validToSubmit(treatmentName,duration));
  }
  const validToSubmit=(name,duration)=>{
    return name!=='' && !exclusionList.includes(name)&& duration>0
  }

  const handleSelect = e=>{
    const newDuration=parseInt(e.target.value, 10);
    setDuration(newDuration);
    setAddEnabled(validToSubmit(newTreatment,newDuration))
  }

  return <AddTreatmentPanelWrapper>
    <ActionMenuText ><span>Add New Treatment</span></ActionMenuText>
    <AddTreatmentInputGroup>
      <label htmlFor="treatment-name">Treatment Name:</label>
      <TipsyInput id="treatment-name" value={newTreatment} onChange={handleChange} ></TipsyInput>
    </AddTreatmentInputGroup>
    <AddTreatmentInputGroup>
      <label htmlFor="duration">Duration:</label>
      <select id ="duration" value={duration} onChange={handleSelect}>
        <option value="0"></option>
        <option value="1">15 minutes</option>
        <option value="2">30 minutes</option>
        <option value="3">45 minutes</option>
        <option value="4">1 hour</option>
        <option value="5">1 hour 15 minutes</option>
        <option value="6">1 hour 30 minutes</option>
        <option value="7">1 hour 45 minutes</option>
        <option value="8">2 hours</option>
        <option value="9">2 hours 15 minutes</option>
        <option value="10">2 hours 30 minutes</option>
      </select>
    </AddTreatmentInputGroup>
    <TreatmentButtonBar>
      <TreatmentButtonBox>
        <TipsyButton text="Add" disabled={!addEnabled} color={color2} onClick={handleSubmit} />
      </TreatmentButtonBox>
      <TreatmentButtonBox>
        <TipsyButton text="Close" color="#ff4d4d" onClick={onClose} />
      </TreatmentButtonBox>
    </TreatmentButtonBar>
  </AddTreatmentPanelWrapper>
}

export default AddTreatmentPanel;
