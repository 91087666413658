import {regularTemplate} from './templates/regularTemplate';
import {sundayTemplate} from './templates/sundayTemplate';
import {thursdayTemplate} from './templates/thursdayTemplate';
import {tuesdayTemplate} from './templates/tuesdayTemplate';
import {signatureTemplate} from './templates/signatureTemplate';
import {signatureSundayMondayTemplate} from './templates/singatureSundayMondayTemplate';
import {signatureTuesdayWednesdayTemplate} from './templates/signatureTuesdayWednesdayTemplate';
import {signatureThursdayFridayTemplate} from './templates/signatureThursdayFridayTemplate';
import store from "../store/storeProvider";

export const bookingTemplate =  (date)=>{
  const {authentication} = store.getState();
  const organization = authentication.organization;
  if(organization === "signature"){
    if (date.weekday() == 0 || date.weekday() == 1){
      return signatureSundayMondayTemplate;
    }
    if (date.weekday() == 2 || date.weekday() == 3){
      return signatureTuesdayWednesdayTemplate;
    }
    if (date.weekday() == 4 || date.weekday() == 5){
      return signatureThursdayFridayTemplate;
    }
    return signatureTemplate;
  }
  if (date.weekday() == 0 || date.weekday() == 1){
    return sundayTemplate;
  }
  if (date.weekday() == 2 || date.weekday() == 3){
    return tuesdayTemplate;
  }
  if (date.weekday() == 4 || date.weekday() == 5){
    return thursdayTemplate;
  }
  return regularTemplate;
};
