import React from 'react';
import PropTypes from 'prop-types';
import {SidePanelButtonWrapper} from './Atoms';

const SidePanelButton = ({text,onClick}) => {
    return (
        <SidePanelButtonWrapper onClick={onClick}>{text}</SidePanelButtonWrapper>
    );
};

SidePanelButton.propTypes = {
  text : PropTypes.string.isRequired,
  onClick : PropTypes.func.isRequired
};

export default SidePanelButton;
