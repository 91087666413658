import React from 'react';
import {readAudits} from '../../../api/auditApi';
import AuditLogItem from './AuditLogItem';
import TipsyInput from '../../common/TipsyInput';
import {AuditLogFilterPanel, AuditLogItemPanel} from "./Atoms";
import {connect} from 'react-redux';
import {hideSpinner, showSpinner} from "../../../actions/spinnerActions";
import {AuditEntry} from "../../../common/types/audit";
import {AuditFilter} from "../../../model/audit/AuditFilter";

interface MyProps {

}

interface MyState {
  telephoneFilter  :string;
  audits : AuditEntry[];
  filters : AuditFilter[];
}

interface DispatchProps {
  showSpinner : typeof showSpinner;
  hideSpinner : typeof hideSpinner;
}

type Props = MyProps & DispatchProps;

const TECHNICIAN_KEY='technician';
const CUSTOMER_KEY='customerName';
const USER_KEY='user';
const ITEMS_TO_DISPLAY=250;

export class AuditLogs extends React.Component<Props,MyState>{

    constructor(props:Props){
      super(props);
      this.state = {
        audits : [],
        filters: [],
        telephoneFilter:''
      };
      this.setBookingDateFilter=this.setBookingDateFilter.bind(this);
      this.setTechnicianFilter=this.setTechnicianFilter.bind(this);
      this.setCustomerFilter=this.setCustomerFilter.bind(this);
      this.setUserFilter=this.setUserFilter.bind(this);
      this.setTelephoneFilter=this.setTelephoneFilter.bind(this);
      this.filterAudit=this.filterAudit.bind(this);
    }

    componentDidMount(){
      this.props.showSpinner();
      readAudits().then((audits:AuditEntry[])=>{
        this.setState({...this.state,audits});
        this.props.hideSpinner();
      });
    }

    setBookingDateFilter(e){
      const newBookingDateFilter = e.target.value.toString();
      this.updateFilter('bookingDate',newBookingDateFilter);
    }

    setTechnicianFilter(e){
      const newBookingDateFilter = e.target.value.toString();
      this.updateFilter(TECHNICIAN_KEY,newBookingDateFilter);
    }

    setTelephoneFilter(e){
      const newTelephoneFilter = e.target.value.toString();
      this.setState({...this.state, telephoneFilter: newTelephoneFilter});
    }


    setCustomerFilter(e){
      const newCustomerFilter = e.target.value.toString();
      this.updateFilter(CUSTOMER_KEY,newCustomerFilter);
    }

    setUserFilter(e){
      const newUserFilter = e.target.value.toString();
      this.updateFilter(USER_KEY,newUserFilter);
    }

    updateFilter(filterName:string, newBookingDateFilter) {
    let newFilters = this.state.filters.filter(filter => filter.field !== filterName);
    if (newBookingDateFilter) {
      newFilters.push({field: filterName, value: newBookingDateFilter});
    }
    this.setState({...this.state, filters: newFilters});
  }

  getFilterValue(fieldName:string){
      const field = this.state.filters.find(filter=>filter.field===fieldName);
      if(field && field.value){
        return field.value;
      }
      return "";
  }

  filterAudit(audit){
    const telephone = this.state.telephoneFilter;
      if (this.state.filters.length === 0 && !telephone){
        return true;
      }
      let valid = true;
      this.state.filters.forEach(filter=>{
        if(!audit[filter.field] || audit[filter.field].indexOf(filter.value) < 0){
          valid = false;
        }
      });

      const telePhoneKeys = ['telephone','customerTelephone'];
      let validPhone=false;
      telePhoneKeys.forEach(key=>{
        if(telephone && audit[key] && audit[key].indexOf(telephone) >= 0){
          validPhone = true;
        }
        if(telephone && audit.changes ){
          audit.changes.forEach(change => {
            if(change.field === key && change.value.indexOf(telephone) >= 0){
              validPhone = true;
            }
          });
        }
      });
      return valid && (!telephone || validPhone);
  }

  render(){
      const bookingDateFilter = this.getFilterValue('bookingDate');
      const technicianFilter = this.getFilterValue(TECHNICIAN_KEY);
      const customerFilter = this.getFilterValue(CUSTOMER_KEY);
      const userFilter = this.getFilterValue(USER_KEY);
      const telephoneFilter = this.state.telephoneFilter;
      const filteredAudits = this.state.audits.filter(this.filterAudit);
      const audits = filteredAudits.slice(0,ITEMS_TO_DISPLAY);
      return (
        <div>
          <AuditLogFilterPanel>
            <span><strong>Booking Date</strong>:<TipsyInput value={bookingDateFilter} onChange={this.setBookingDateFilter}/></span>
            <span><div><strong>Technician</strong>:</div><TipsyInput value={technicianFilter} onChange={this.setTechnicianFilter}/></span>
            <span><div><strong>Customer</strong>:</div><TipsyInput value={customerFilter} onChange={this.setCustomerFilter}/></span>
            <span><div><strong>Logged in user</strong>:</div><TipsyInput value={userFilter} onChange={this.setUserFilter}/></span>
            <span><div><strong>Telephone</strong>:</div><TipsyInput value={telephoneFilter} onChange={this.setTelephoneFilter}/></span>
          </AuditLogFilterPanel>
          <AuditLogItemPanel>
              {audits.map(audit => {
                return(
                  <AuditLogItem key={audit.key} audit={audit}  />
                );
              })}
          </AuditLogItemPanel>
        </div>
      );
    }
}

function mapStateToProps() {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
      showSpinner : () => {dispatch(showSpinner());},
      hideSpinner : () => {dispatch(hideSpinner());}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogs);

