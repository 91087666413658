import React, {FunctionComponent} from 'react';
import {TreatmentAdminMenuButton, TreatmentGroupItemLabel, TreatmentGroupListItemWrapper} from "./Atoms";

interface TreatmentGroupItemProps{
  name:String;
  evenRow:boolean;
  onDelete:(groupName:String)=>void;
  onEdit:(groupName:String)=>void;
}

const TreatmentGroupItem:FunctionComponent<TreatmentGroupItemProps> = ({name,evenRow,onDelete,onEdit})=>{
  const handleDelete = ()=>{
    onDelete(name);
  }

  const handleEdit = ()=>{
    onEdit(name);
  }

  return <TreatmentGroupListItemWrapper data-testid="treatment-group-id" evenRow={evenRow} >
    <TreatmentGroupItemLabel>{name}</TreatmentGroupItemLabel>
    <TreatmentAdminMenuButton data-testid="delete-group" style={{color:'red'}} onClick={handleDelete}>X</TreatmentAdminMenuButton>
    <TreatmentAdminMenuButton data-testid="edit-group" style={{color:'green'}} onClick={handleEdit} >!</TreatmentAdminMenuButton>
  </TreatmentGroupListItemWrapper>;
}

export default TreatmentGroupItem;
