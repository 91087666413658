import React, {FormEvent} from 'react';

export interface TipsyInputProps {
  value : string;
  onChange ?: (e: FormEvent<HTMLInputElement>) => void;
  testid? : string;
  id ?: string;
  name? : string;
}

const TipsyInput = ({value,onChange,testid,id,name}:TipsyInputProps) => {
    return (
      <input  className="tipsy-input" id={id} name={name} type="text" value={value} onChange={onChange} data-testid={testid} />
    );
};

export default TipsyInput;
