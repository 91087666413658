import React from 'react';
import PropTypes from 'prop-types';
import {PropertyLookupItemButton} from './Atoms';

const PropertyLookupItem = ({customer,onClickItem}) => {
    const clickItem = () => {
      onClickItem(customer);
    };
    return (
      <div>
        <PropertyLookupItemButton onClick={clickItem}>{customer.name} - {customer.telephone}</PropertyLookupItemButton>
      </div>
    );
};

PropertyLookupItem.propTypes = {
  customer : PropTypes.object.isRequired,
  onClickItem : PropTypes.func.isRequired
};

export default PropertyLookupItem;
