import styled from 'styled-components';


export const TreatmentsAdminPanel = styled.div`
  background-color:pink;
  padding:10px;
  flex-grow: 1;
`;

export const TreatmentsGroupHeader = styled.div`
  font-weight: bold;
  padding:10px;
`;

export const TreatmentGroupItemLabel = styled.label`
  flex-grow: 1;
`

export const TreatmentGroupListItemWrapper = styled.div<TreatmentGroupItemWrapperProps>`
  background-color:${props=>props.evenRow?'lightgrey':'white'};
  justify-self : center;
  padding-left:2px;
  display:flex;
`;

export const TreatmentAdminMenuButton = styled.button`
  border:none;
  background-color : transparent;
  cursor:pointer;
  &:focus {outline:0;}
  font-weight:bold;
`;

export const TreatmentButtonBarWrapper = styled.div`
  margin-top:5px;
`

export const ActionMenuText = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  font-size: 2em;
`;

export const AddTreamentGroupPanelWrapper = styled.div`
  display:flex;
  flex-direction: column;
  padding:15px;
  align-items: center;
`

export const AddTreatmentInputGroup = styled.div`
  margin-top: 10px;
  display:flex;
  flex-direction: column;
`
export const TreatmentButtonBar = styled.div`
  margin-top:5px;
  display:flex;
  padding:5px;
  justify-content: flex-start;
`

export const TreatmentButtonBox = styled.div`
  padding-right:15px;
  margin-top: 10px;
`


export const TreamentGroupPanelWrapper = styled.div`
  display:flex;
  flex-direction: column;
  padding:15px;
  align-items: center;
`
export const TreatmentListTableGroup = styled.div`
  margin-top: 10px;
  display:flex;
  flex-direction: column;
`

export const TreatmentAdminListHeader = styled.div`
  font-weight:bold;
`;

export const TreatmentTable = styled.div`
  display:grid;
  grid-template-columns : 6fr 6fr 1fr 1fr;
  padding:10px;
  width:100%
`;

interface TreatmentGroupItemWrapperProps {
  evenRow:boolean
}

export const TreatmentGroupItemWrapper = styled.div<TreatmentGroupItemWrapperProps>`
  background-color:${props=>props.evenRow?'lightgrey':'white'};
  padding-left:2px;
`;

export const AddTreatmentPanelWrapper= styled.div`
  display:flex;
  flex-direction: column;
  padding:15px;
  align-items: center;
`
