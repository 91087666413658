import React from 'react';
import PropTypes from 'prop-types';

const TipsyButton = ({text,onClick,disabled,className,color,testid}) => {
    const classes = "tipsy-button "+(className?className:'');
    return (
      <input className={classes} type="button" onClick={onClick} value={text} disabled={disabled} style={{background:color}} data-testid={testid} />
    );
};


TipsyButton.propTypes = {
  text : PropTypes.string.isRequired,
  onClick : PropTypes.func.isRequired,
  disabled : PropTypes.bool,
  className : PropTypes.string,
  color:PropTypes.string,
  testid : PropTypes.string
};

export default TipsyButton;
