import {addCustomer, fetchCustomers} from '../api/customersApi';
import {CUSTOMER_ADDED_SUCCESS, CUSTOMERS_LOADED_SUCCESS} from '../constants/actionTypes';
import * as _ from 'lodash';

export const customersLoadedSuccess = customers => {
    const customersArray = _.values(customers);
    return {type: CUSTOMERS_LOADED_SUCCESS,customers:customersArray};
};

export const customerAddedSuccess = customer => {
    return {type: CUSTOMER_ADDED_SUCCESS,customer};
};

export const loadCustomers = () => {
  return dispatch => {
      fetchCustomers().then(customers => {
          dispatch(customersLoadedSuccess(customers));
      });
  };
};

export const addNewCustomer = customer => {
    return dispatch => {
        addCustomer(customer).then (() => {
          dispatch(customerAddedSuccess(customer));
        });
    };
};
