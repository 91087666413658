import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {DatePickerCalendarCellButton} from './Atoms';

const CalendarCell = ({value,updateField}) => {
    const update = () => {
      updateField(value);
    };
    const thisMonth = moment().isSame(value, 'month');
    const today = moment().isSame(value, 'd');
    return (
      <DatePickerCalendarCellButton thismonth={thisMonth} today={today} onClick={update}>{value.date()}</DatePickerCalendarCellButton>
    );
};

CalendarCell.propTypes = {
  value : PropTypes.object.isRequired,
  updateField : PropTypes.func.isRequired
};

export default CalendarCell;
