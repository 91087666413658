import styled from 'styled-components';

const NavBarWrapper = styled.div`
   height:1.5em;
  background-color: gray;
  padding-top: 5px;
  > a {
    margin: 0 10px 0 10px;
    color: black;
  }
`;

export default NavBarWrapper;
