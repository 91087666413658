import {Bookings} from "../model/Bookings";
import {updateObjectProperty} from "./objectPropertyEditor";
import {UnavailabilitySlots} from "../model/UnavailabilitySlots";
import * as moment from "moment";
import {Rule} from "../model/Rule";
import {resolveRules} from "./ruleMatcher";
import {RuleCandidate} from "../model/RuleCandidate";
import {removeSlot} from "./slotRemover";

export const makeTechnicianUnavailable = (bookings:Bookings, technician:string, slotNumber:number):Bookings => {
  const theTechnician = bookings[technician];
  if(!theTechnician){
    return bookings;
  }
  return updateObjectProperty(bookings,`${technician}.unavailability.${slotNumber}`,{start:slotNumber,end:slotNumber});
};

export const makeTechnicianAvailable = (bookings:Bookings, technician:string, slotNumber:number):Bookings => {
  const theTechnician = bookings[technician];
  if(!theTechnician){
    return bookings;
  }
  return updateObjectProperty(bookings,`${technician}.unavailability`,removeSlot(theTechnician.unavailability,slotNumber));
};

export const calculateTechnicianUnavailabilityFromRules = (technician:string,date:moment.Moment,rules:Rule[]):UnavailabilitySlots=>{
  const candidate : RuleCandidate = {
    technician,
    dayOfWeek : date.format('dddd')
  };
  return resolveRules(candidate,rules);
};
