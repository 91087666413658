import React from 'react';
import {AuditLogItemBodyWrapper} from "./Atoms";
import {AuditEntry} from "../../../model/AuditEntry";

export interface AuditLogItemBodyProps {
  audit : AuditEntry
}

const AuditLogItemBody:React.FunctionComponent<AuditLogItemBodyProps> = ({audit}) => {
    const isMake = (audit.type === "Make Booking");
    const isUpdate = (audit.type === "Update Booking");
    const isAvailability = (audit.type === "Set Available" || audit.type === "Set Unavailable");
    return (
        <AuditLogItemBodyWrapper>
          {(isMake) && <label><strong>Customer</strong>:{audit.customerName}</label>}
          {(isMake) && <label><strong>Duration</strong>:{audit.duration}</label>}
          {(isMake) && <label><strong>Treatment</strong>:{audit.treatment}</label>}
          {(isMake) && <label><strong>Extra Time</strong>:{audit.extraTime}</label>}
          {(isMake) && <label><strong>Telephone</strong>:{audit.telephone}</label>}
          {(isUpdate) && audit.changes.map(change=>{
            return(
              <label key={change.field}><strong >{change.field}</strong>:{JSON.stringify(change.value)}</label>
            );
          }) }
          {(isAvailability) && <label><strong>Slot Time</strong>:{audit.slotStartTime}</label>}
        </AuditLogItemBodyWrapper>
    );
};

export default AuditLogItemBody;
