import styled from 'styled-components';

export const EditBookingPageForm = styled.form`
  display:flex;
  flex-direction: column;
  height:100%
`;

export const EditBookingTitle = styled.div`
  text-align: center;
  margin: 5px;
  font-size: 1.2em;
  height:50px;
`;

export const EditBookingPageBody = styled.div`
  flex-grow:1;
  display:grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
`;

export const EditBookingGrouping = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin:10px;
  &> input {
    width: 140px;
  }
`;

export const EditBookingButtonPanel = styled.div`
  text-align: center;
  margin:10px;
  height:50px;
  display:flex;
  justify-content: space-evenly;
`;

export const EvenBookingTextArea = styled.textarea`
  resize: none;
`;
