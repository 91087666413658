import {Bookings} from "../model/Bookings";
import {Technician} from "../model/Technician";
import {produce} from 'immer';
import moment, {Moment} from "moment";

const correctBookingTechnicians = (bookings:Bookings,technicians:Technician[],date:Moment)=>{
  const newBookings = produce(bookings,draftBookings=>{
    Object.keys(technicians).forEach(techKey => {
      const technician = technicians[techKey];
      if(draftBookings[technician.name] && Object.keys(draftBookings[technician.name].slots||{}).length===0 && technician.activeUntil && moment(technician.activeUntil).isBefore(date)){
        delete draftBookings[technician.name];
      }else if(!draftBookings[technician.name] && technician.activeFrom && !moment(technician.activeFrom).isAfter(date) && (!technician.activeUntil || !moment(technician.activeUntil).isBefore(date))){
        draftBookings[technician.name]={
          technician : technician.name,
          slots:{},
          sortOrder:technician.sortOrder||99,
          unavailability:{}
        }
      }
    });
  });

  return newBookings;
}

export default correctBookingTechnicians;
