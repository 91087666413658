import React from 'react';
import PropTypes from 'prop-types';
import {ReportsBodyWrapper} from './Atoms';

const ReportsBody = ({children}) => {
    return (
      <ReportsBodyWrapper>
        {children}
      </ReportsBodyWrapper>
    );
};

ReportsBody.propTypes = {
  children : PropTypes.array.isRequired
};

export default ReportsBody;
