import db from '../services/indexedb';
import {bookingTemplate} from '../common/bookingTemplates';

export const loadBookingsOffline = (date) => {
  const bookingDate = date.format("YYYY-MM-DD");
  const result = new Promise((resolve,reject)=>{
    db.table('bookings')
      .get(bookingDate)
      .then(booking =>{
          if(booking){
            resolve(booking);
          }else{
            resolve(bookingTemplate(date));
          }
      })
      .catch(error => {
        reject(error);
      });
  });
  return result;
};
