import React from 'react';
import {connect} from 'react-redux';
import {SearchPageWrapper} from "./Atoms";
import SearchPanel from "./SearchPanel";

const SearchPage = () => {
  return (<SearchPageWrapper>
    <SearchPanel />
  </SearchPageWrapper>);
}

export default connect()(SearchPage);
