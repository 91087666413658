import React from 'react';
import PropTypes from 'prop-types';
import {CalendarHeader, CalendarHeaderButton} from './Atoms';

const CalendarColumnTitle = ({title,onEditTechnician}) => {
    return (
      <CalendarHeader>
        <CalendarHeaderButton type="Button" value={title} onClick={onEditTechnician} />
      </CalendarHeader>
    );
};

CalendarColumnTitle.propTypes = {
    title  : PropTypes.string.isRequired,
    onEditTechnician  : PropTypes.func.isRequired
};

export default CalendarColumnTitle;
