import React from 'react';
import TipsyButton from '../../common/TipsyButton';
import TipsyInput from '../../common/TipsyInput';
import {
  AcceptPaymentBody,
  AcceptPaymentButtonPanel,
  AcceptPaymentGrouping,
  AcceptPaymentHeader,
  AcceptPaymentPageWrapper
} from './Atoms';
import {color2} from '../../../constants/color';

import {Payment} from "../../../model/Payment";

type AcceptPaymentPageState ={
  cashValue : string,
  cardValue : string,
  voucherValue : string,
  note : string
}

type AcceptPaymentPageProps ={
  onAccept : (payment:Payment)=>void,
  onClose : ()=>void,
  paid : Payment
}

class AcceptPaymentPage extends React.Component<AcceptPaymentPageProps,AcceptPaymentPageState> {

    state = {
      cashValue : '',
      cardValue : '',
      voucherValue : '',
      note : '',
    };

    constructor (props : AcceptPaymentPageProps){
      super(props);

      this.updateCashPayment=this.updateCashPayment.bind(this);
      this.acceptPayment=this.acceptPayment.bind(this);
      this.updateCardPayment=this.updateCardPayment.bind(this);
      this.updateVoucherPayment=this.updateVoucherPayment.bind(this);
      this.state.cashValue=(this.props.paid.cashValue||0).toString();
      this.state.cardValue= (this.props.paid.cardValue||0).toString();
      this.state.voucherValue= (this.props.paid.voucherValue||0).toString();
      this.state.note= this.props.paid.note||'';
    }

    updateCashPayment (e: React.FormEvent<HTMLInputElement>){
      if(e.currentTarget.value === '' || this.numericFieldIsValid(e.currentTarget.value))
        this.setState ({...this.state,cashValue:e.currentTarget.value});
    }

    updateCardPayment (e: React.FormEvent<HTMLInputElement>){
      if(e.currentTarget.value === '' || this.numericFieldIsValid(e.currentTarget.value))
        this.setState ({...this.state,cardValue:e.currentTarget.value});
    }

    updateVoucherPayment (e: React.FormEvent<HTMLInputElement>){
      if(e.currentTarget.value === '' || this.numericFieldIsValid(e.currentTarget.value))
        this.setState ({...this.state,voucherValue:e.currentTarget.value});
    }

    acceptPayment (){
      this.props.onAccept({
        cashValue : parseFloat(this.state.cashValue),
        cardValue : parseFloat(this.state.cardValue),
        voucherValue : parseFloat(this.state.voucherValue),
        note : this.state.note
      });
    }

    numericFieldIsValid (fieldValue : string):boolean {
      return /^[\d]+[.]*[\d]{0,2}$/.test(fieldValue);
    }

    formIsValid():boolean {
      if(!this.numericFieldIsValid(this.state.cashValue))
        return false;
      if(!this.numericFieldIsValid(this.state.cardValue))
        return false;
      if(!this.numericFieldIsValid(this.state.voucherValue))
        return false;
      return true;
    }

    render() {
      return (
        <AcceptPaymentPageWrapper>
          <AcceptPaymentHeader>Accept Payment</AcceptPaymentHeader>
          <AcceptPaymentBody>
            <AcceptPaymentGrouping>
              <label>Cash:</label>
              <TipsyInput testid='amount' value={this.state.cashValue} onChange={this.updateCashPayment}/>
            </AcceptPaymentGrouping>
            <AcceptPaymentGrouping>
              <label>Credit Card:</label>
              <TipsyInput testid='card' value={this.state.cardValue} onChange={this.updateCardPayment} />
              </AcceptPaymentGrouping>
            <AcceptPaymentGrouping>
              <label>Voucher:</label>
              <TipsyInput testid='voucher' value={this.state.voucherValue} onChange={this.updateVoucherPayment} />
            </AcceptPaymentGrouping>
            {this.state.note && <AcceptPaymentGrouping>
              <label>Note:</label>
              <TipsyInput testid='note' value={this.state.note} />
            </AcceptPaymentGrouping>}
          </AcceptPaymentBody>
          <AcceptPaymentButtonPanel >
            <TipsyButton id="accept-button" testid="accept-button" text="Accept" color={color2} onClick={this.acceptPayment} disabled={!this.formIsValid()} />
            <TipsyButton id="close-button" text="Close" color="red" onClick={this.props.onClose}/>
          </AcceptPaymentButtonPanel>
        </AcceptPaymentPageWrapper>
      );
    }
}

export default AcceptPaymentPage;
