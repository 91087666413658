import initialState from './initialState';
import {SET_WIZARD_PAGE} from '../constants/actionTypes';

export default function (state = initialState.editWizard,action) {
  switch (action.type){
    case SET_WIZARD_PAGE:
      return Object.assign({},state,{page:action.page});
    default:
      return state;
  }

}
