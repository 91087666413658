import React from 'react';
import {HeaderText, MoveBookingDetails, MoveBookingWrapper} from "./Atoms";
import {WizardButton, WizardButtonContainer} from "../../common/Atoms";

interface MyProps{
  availableTechnicians : string[];
  onExit : ()=>void;
  onSelectTechnician : (ignored:string)=>void;
  children : React.ReactNode;
}

const MoveBookingToTechnician = ({availableTechnicians,onExit,onSelectTechnician,children}:MyProps)=>{
  const handleSelect = (e)=>{
    onSelectTechnician(e.target.value);
  };
  return (
    <MoveBookingWrapper>
      <MoveBookingDetails>
        {children}
      </MoveBookingDetails>
      <HeaderText>Move Booking to a new technician</HeaderText>
      <WizardButtonContainer>
        {availableTechnicians.map(technician=>{
          return <WizardButton key={technician} type="button" value={technician} onClick={handleSelect}/>;
        })}
        <WizardButton type="button" value="Close" onClick={onExit}/>
      </WizardButtonContainer>

    </MoveBookingWrapper>
  );
};

export default MoveBookingToTechnician;
