import db from '../services/indexedb';
import {isOffline} from '../services/offlineStatus';
import {loadBookingsOffline} from './offlineBookingsApi';
import fetchJson from './fetchJson';
import {organizationalUrl} from "../services/urlProvider";
import {mapBookingsToDto} from "../services/mappers/mapBookingsToDto";
import {mapDtoToBookings} from "../services/mappers/mapDtoToBookings";
import {createBookingSet} from "../services/bookingSetCreator";
import * as _ from 'lodash';
import {putToBackend} from "./backendApi";
import moment from "moment";
import correctBookingTechnicians from "../services/correctBookingTechnicians";
import {BookingPage} from "../model/BookingPage";

const persistBookingToCache = (date, booking)=> {
  db.table('bookings').put(booking,date);
};

export function updateBookings (newBookingPage : BookingPage){
  const bookingsDto = mapBookingsToDto(newBookingPage);
  const bookingDate = newBookingPage.date.format("YYYY-MM-DD");
  persistBookingToCache(bookingDate,bookingsDto);
  const url = organizationalUrl();
  return putToBackend(`${url}/bookings/${bookingDate}.json`,bookingsDto);
}

export function loadBookingsFromDb(date) {

  if (isOffline()){
    return loadBookingsOffline(date);
  }else {
    return loadBookingsFromOnlineDb(date);
  }
}

export function loadBookingsFromOnlineDb(date) {
  const bookingDate = date.format("YYYY-MM-DD");
  return new Promise(async (resolve,reject)=>{
    const url = organizationalUrl();
    try{
      const bookingDto = await fetchJson(`${url}/bookings/${bookingDate}.json`);
      const technicians = await fetchJson(`${url}/technicians.json`) || {};
      if (bookingDto) {
        persistBookingToCache(bookingDate,bookingDto);
        const bookings = mapDtoToBookings(bookingDto);
        const correctedBookings = correctBookingTechnicians(bookings,technicians,date);
        resolve(correctedBookings);
      } else {
        const rules = await fetchJson(`${url}/rules/unavailability.json`);
        resolve(createBookingSet(_.orderBy(technicians,'sortOrder').filter(technician=>!technician.activeUntil||moment(technician.activeUntil).isAfter(date)).map(technician=>technician.name),date,rules));
      }
    }catch(error){
      reject(error);
    }
  });
}
