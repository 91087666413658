import {BookingSlots} from "./BookingSlots";
import {UnavailabilitySlots} from "./UnavailabilitySlots";

export class Booking {
  unavailability: UnavailabilitySlots;
  slots?: BookingSlots;
  technician: string;
  sortOrder: number;

  constructor(technician : string,slots?:BookingSlots){
    this.technician = technician;
    this.slots = slots||{};
    this.sortOrder = 0;
    this.unavailability = {}
  }
}
