import React from 'react';
import PropTypes from 'prop-types';
import {SidePanelMenuWrapper} from './Atoms';

//import './sidePanelMenu.scss';

const SidePanelMenu = ({children}) => {
    return (
      <SidePanelMenuWrapper>
        {children}
      </SidePanelMenuWrapper>
    );
};

SidePanelMenu.propTypes = {
  children :  PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default SidePanelMenu;
