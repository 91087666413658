import React from 'react';
import PropTypes from 'prop-types';
//import './wizardStartPage.scss';
import {
  ACCEPT_PAYMENT_PAGE,
  CANCEL_BOOKING_PAGE,
  EDIT_BOOKING_PAGE, MOVE_BOOKING_TECHNICIAN_PAGE,
  NO_SHOW_PAGE,
  TOGGLE_CONFIRMATION_PAGE
} from '../../../constants/editPages';
import {WizardStartText, WizardStartWrapper} from './Atoms';
import {WizardButton, WizardButtonContainer} from '../../common/Atoms';

const WizardStartPage = ({noShow,setWizardPage,closeModal,confirmed}) => {
  const cancelBooking =  ()=> {
    setWizardPage(CANCEL_BOOKING_PAGE);
  };

  const editBooking = ()=>{
    setWizardPage(EDIT_BOOKING_PAGE);
  };

  const acceptPayment= ()=>{
    setWizardPage(ACCEPT_PAYMENT_PAGE);
  };

  const setNoShow=()=>{
    setWizardPage(NO_SHOW_PAGE);
  };

  const toggleConfirm = ()=>{
    setWizardPage(TOGGLE_CONFIRMATION_PAGE);
  };

  const moveBooking = ()=>{
    setWizardPage(MOVE_BOOKING_TECHNICIAN_PAGE);
  };

  return (
    <WizardStartWrapper>
      <WizardStartText>
        <span>Manage booking</span>
      </WizardStartText>
      <WizardButtonContainer>
        <WizardButton type="button" value="Edit Booking" onClick={editBooking} />
        <WizardButton alert type="button" value="Cancel Booking" onClick={cancelBooking} />
        {!noShow &&  <WizardButton type="button" value="Accept Payment" onClick={acceptPayment} />}
        {!noShow &&  <WizardButton type="button" value="No Show" onClick={setNoShow} />}
        <WizardButton type="button" value={confirmed?"Un-confirm Booking":"Confirm Booking"} onClick={toggleConfirm} />
        <WizardButton type="button" value="Move Booking" onClick={moveBooking} />
        <WizardButton type="button" value="Return to Calendar" onClick={closeModal} />
      </WizardButtonContainer>
    </WizardStartWrapper>
  );
};

WizardStartPage.propTypes = {
    closeModal : PropTypes.func.isRequired,
    setWizardPage : PropTypes.func.isRequired,
    noShow : PropTypes.bool.isRequired,
    confirmed : PropTypes.bool.isRequired
};

export default WizardStartPage;
