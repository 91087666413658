import styled from 'styled-components';

export const CalendarWrapper = styled.div`
   flex-grow : 1;
  display : flex;
  flex-direction: row;
`;

export const CalendarTimeLineWrapper = styled.div`
   width : 70px;
  background-color: pink;
`;

export const CalendarTimeLineSpacer = styled.div`
   border:1px solid black;
  height:24px;
  width:70px;
  box-sizing: border-box;
`;

export const CalendarBody = styled.div`
   display:grid;
  flex-direction: row;
  width:290px;
  overflow-x: auto;
  grid-template-columns: repeat(6,145px);
  @media screen and (min-width: 361px) {
    width:940px;
  }

`;

export const CalendarColumnWrapper = styled.div`
   width:145px;
  max-width:150px;
  display:flex;
  flex-direction: column;
`;

export const CalendarHeader = styled.div`
   border:1px solid black;
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
  height : 24px;
  box-sizing: border-box;
`;

export const CalendarCellButton = styled.button`
   width:100%;
  height:100%;
  background: transparent;
  border: none !important;
  font-size:0.8em;
  margin:0;
  top:0px;
  text-align: left;
  padding: 0;
  position: absolute;
  top:-1px;
  left:1px;
  &:hover {
    cursor:pointer;
  }
  color: ${props => props.textcolor};
  overflow:hidden;
`;

export const CalendarHeaderButton = styled.input`
   width:100%;
  height:100%;
  background: transparent;
  border: none !important;
  font-size:0.85em;
  margin:0;
  top:0px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
`;

export const CalendarCellWrapper = styled.div`
  border:1px solid lightgray;
  border-left: 1px solid black;
  border-right: 1px solid black;
  height:15px;
  padding-left: 5px;
  box-sizing: border-box;
  font-size: 0.8em;
  position: relative;
  overflow: hidden;
  border-top: ${props=>props.first?'2px solid black':'1px solid lightgray'};
  border-bottom: ${props=>props.last?'2px solid black':'1px solid lightgray'};
  background-color: ${props=>props.available?'lightgreen':props.occupied?'lightgray':'inherited'} ;
  background: ${props=>props.unavailable?'repeating-linear-gradient(135deg,pink,#fff3f5 10px, #ff8da1 3px,#da0026 1px)':''} ;
  color: ${props => props.textcolor}
`;

export const ConfirmedIcon = styled.span`
color:#ff00f1;
    font-weight:bold;
    font-size:12px;
`;
