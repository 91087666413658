import {isSlotAvailable} from "./slotAvailability";
import {BookingSlot} from "../model/BookingSlot";
import {Bookings} from "../model/Bookings";
import {getBookingStart} from "./slotUtils";
import {removeSlots} from "./slotRemover";

export function getSlotByIdAndTechnician (bookings : Bookings,slotNumber : number, technician : string):BookingSlot{
  const theTechnician = bookings[technician]
  if(!theTechnician){
    return undefined;
  }
  const startSlot = getBookingStart(technician,slotNumber,bookings);
  if (startSlot == null)
    return undefined;

  return theTechnician.slots[startSlot];
}

export function getAlternativeSlotWithTechnician(bookings : Bookings,technicianName:string,currentSlotNumber : number,rangeSize:number=48) : number[]{
  const technician = bookings[technicianName];
  if (!technician || !technician.slots){
    return [];
  }
  const startSlot = getBookingStart(technicianName,currentSlotNumber,bookings);
  const {[startSlot]:currentSlot,...otherOccupiedSlots} = technician.slots;
  if (!currentSlot){
    return [];
  }
  const slotWidth = currentSlot.end-currentSlot.start+1;
  const unavailability = removeSlots(technician.unavailability?technician.unavailability:[],currentSlot.start,currentSlot.end);

  let booleans = isSlotAvailable([
    ...(Object.keys(otherOccupiedSlots).map(key => otherOccupiedSlots[key]))
    ,...(Object.keys(unavailability).map(key => unavailability[key]))]
    ,rangeSize,slotWidth);
  return booleans.reduce((acc, cur, idx)=>cur?[...acc,idx]:acc,[]);
}
