import moment from 'moment';
import fetchJson from './fetchJson';
import {mapFromDtoToAudit} from '../services/dtoToAuditMapper';
//import type {AuditData, AuditDto, AuditEntry} from "../common/types/audit";
import {organizationalUrl} from "../services/urlProvider";
import {postToBackend} from "./backendApi";
import {AuditEntry} from "../model/AuditEntry";
import {AuditDto} from "../model/dtos/audit/AuditDto";
import {AuditAction} from "../model/dtos/AuditAction";
import {AuditBookingDataDto} from "../model/dtos/audit/AuditBookingDataDto";

export const addAudit = (user:string,action:AuditAction,entityId:string,data:AuditBookingDataDto) => {
  const url = organizationalUrl();
  const dto= {user,action,entityId,data,timestamp:moment().format("YYYY-MM-DD HH:mm:ss")};

  return postToBackend(`${url}/audit.json`,dto);
};

export const readAudits = ():Promise<AuditEntry[]> =>{
  const url = organizationalUrl();
  return fetchJson(`${url}/audit.json`).then(auditsObj=>{
    const keys = Object.keys(auditsObj);
    const audits:AuditDto[] = [];
    keys.forEach(key => {
        audits.push({...auditsObj[key],key});
    });
    audits.sort((a,b)=>a.timestamp>b.timestamp?1:-1);
    return audits.map(mapFromDtoToAudit);
  });
};
