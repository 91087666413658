import React from 'react';
import PropTypes from 'prop-types';

const DailyReportTechnicianRow = ({technician}) => {
    return (
      <React.Fragment key={technician.name}>
        <div>{technician.name}</div>
        <div>{technician.timeBooked}</div>
        <div>{technician.paymentReceived}</div>
        <div>{technician.noShows}</div>
      </React.Fragment>
    );
};

DailyReportTechnicianRow.propTypes = {
    technician : PropTypes.object.isRequired
};

export default DailyReportTechnicianRow;
