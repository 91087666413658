import {CLOSE_ALL_MODALS, CLOSE_MODAL, OPEN_MODAL} from '../constants/actionTypes';
import initialState from './initialState';

export default function (state = initialState.modalState, action) {
  switch(action.type){
    case OPEN_MODAL:
      return {...state,render:[...state.render,action.renderFunction],modalParameters:[...state.modalParameters,action.modalParameters]};
    case CLOSE_MODAL:
      return {...state,render:state.render.slice(0,state.render.length-1),
        modalParameters:state.modalParameters.slice(0,state.modalParameters.length-1)};
    case CLOSE_ALL_MODALS:
      return {...state,render:[],modalParameters:[]};
    default:
      return state;
  }
}
