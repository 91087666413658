import * as React from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {loadUsers, toggleUsersActive} from "../../../actions/userAdminActions";
import UserListItem from "./UserListItem";
import {UserAdminListHeader, UserAdminPageWrapper, UserList} from "./Atoms";
import {closeModal, openModal} from "../../../actions/modalActions";
import UserAdminActionMenu from "./UserAdminActionMenu";

class UserAdmin extends React.Component{
  constructor(props,context){
    super(props,context);
    this.actionButtonClicked = this.actionButtonClicked.bind(this);
    this.userToggleButtonClicked = this.userToggleButtonClicked.bind(this);
  }

  componentDidMount(){
    this.props.actions.loadUsers();
  }

  actionButtonClicked(user){
    this.props.actions.openModal(()=> <UserAdminActionMenu user={user} onCloseWindow={this.props.actions.closeModal} onToggleUsersActive={this.userToggleButtonClicked} />,{});
  }

  userToggleButtonClicked(user){
    this.props.actions.closeModal();
    this.props.actions.toggleUsersActive(this.props.users,user.name);
  }

  render(){
    return (<UserAdminPageWrapper>
      <UserList>
        <UserAdminListHeader data-testid="admin-header-user-name">User Name</UserAdminListHeader>
        <UserAdminListHeader>Last Logged In</UserAdminListHeader>
        <UserAdminListHeader>Active</UserAdminListHeader>
        <UserAdminListHeader/>
        {this.props.users.slice().sort((a,b)=>a.name>b.name?1:-1).map((user,index) => {
          return <UserListItem key={user.name} user={user} evenRow={index%2==0} onClickActionMenu={this.actionButtonClicked}/>;
        })}
      </UserList>
    </UserAdminPageWrapper>);
  }
}

UserAdmin.propTypes = {
  actions : PropTypes.object.isRequired,
  users : PropTypes.array.isRequired
};

const mapStateToProps = (state)=>{
  return {
    users:state.admin.users
  };
};

const mapDispatchToProps = (dispatch)=>{
  return {
    actions: {
      loadUsers : () => {dispatch(loadUsers());},
      openModal : (renderFunction) => {dispatch(openModal(renderFunction));},
      closeModal : () => {dispatch(closeModal());},
      toggleUsersActive : (users,userToToggle) => {dispatch(toggleUsersActive(users,userToToggle));}
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAdmin);
