import {authenticateWithFireBase} from "./authApi";

export const putToBackend = (url,payload):Promise<Response>=>{
  return updateBackend(url,payload,'put');
}

export const postToBackend = (url,payload)=>{
  return updateBackend(url,payload,'post');
}

export const deleteFromBackend = (url,payload)=>{
  return updateBackend(url,payload,'delete');
}

const updateBackend = (url,payload,method):Promise<Response>=>{
  return new Promise(async (resolve,reject)=> {
    const token = await authenticateWithFireBase();
    fetch(url+`?auth=${token}`,{
      method,
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: JSON.stringify(payload)
    }).then(response=>{
      if (response.status !== 200){
        reject (response.statusText);
      }
      response.json().then(resolve).catch(reject);
    }).catch(reject);
  });
}
