import styled from 'styled-components';
import {color3, color5, color6} from '../../../constants/color';

export const DatePickerWrapper = styled.div`
  position: relative;
`;

export const DatePickerCalendarBody = styled.div`
  background: lighten($color5,10%);
  height : 180px;
  width : 280px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 15% 17% 17% 17% 17% 17%;
  z-index: 1000;
  grid-gap: 0 0;
`;

export const DatePickerCalendarHeader = styled.div`
  text-align: center;
  background-color:${color5};
  color: black;
  padding-top: 5px;
`;

export const RightSmallTriangle = styled.input`
  width:0px;
  height:0px;
  border-left: 10px solid ${color6} ;
  border-top: 10px solid transparent ;
  border-bottom: 10px solid transparent ;
  border-right: 10px solid transparent ;
  background-color: transparent;
  padding : 0;
  margin: 3px 0 3px 0;
  &:focus {
    outline : none;
  }
`;

export const LeftSmallTriangle = styled.input`
  width:0px;
  height:0px;
  border-right: 10px solid ${color6} ;
  border-top: 10px solid transparent ;
  border-bottom: 10px solid transparent ;
  border-left: 10px solid transparent ;
  background-color: transparent;
  padding : 0;
  margin: 3px 0 3px 0;
  &:focus {
    outline : none;
  }
`;

export const DatePickerMonthYearPicker = styled.div`
  background-color: ${color5};
  width: 270px;
  color:black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 5px 5px 5px;
`;

export const DatePickerCalendarCellButton = styled.button`
  background-color: ${props=>props.today?color3:props.thismonth?color5:'#f9f8fa'};
  color: black;
  border : 1px solid black;
  margin:0;
  &:hover {
    background-color: ${color6};
    cursor:pointer;
  }
`;
