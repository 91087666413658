import styled from 'styled-components';
import {color3} from '../../../constants/color';

export const SpinnerBackPlane = styled.div`
  isplay: block;
  position: fixed;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.6);
  left: 0px;
  top:0;
  width: 100%;
  height: 100%;
  padding : 20px;
  z-index: 101;
`;

export const SpinnerBody = styled.div`
  width: 150px;
  height: 150px;
  margin: 15% auto;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid ${color3};
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
`;

export const SpinnerText = styled.div`
  font-size: 50px;
  position:absolute;
  left:50%;
  top:35%;
  margin-left: -130px; /* margin is -0.5 * dimension */
  margin-top: -30px;
`;
