import {isSlotAvailable} from './slotAvailability';
import {SlotCell} from "../model/SlotCell";
import {BookingSlots} from "../model/BookingSlots";
import {UnavailabilitySlots} from "../model/UnavailabilitySlots";
import {BookingSlot} from "../model/BookingSlot";
import {Payment} from "../model/Payment";
import {TimeSlot, TimeSlots} from "../model/TimeSlot";

export const mapSlots = (size:number, slots:BookingSlots, unavailability:UnavailabilitySlots,treatmentDuration?:number,equipmentSlots:TimeSlot[]=[]):SlotCell[] => {
  const cells = [];
  const availableSlotsForTreatment = isSlotAvailable([...(Object.keys(slots).map(key=>slots[key])),...(Object.keys(unavailability).map(key=>unavailability[key])),...equipmentSlots],size,treatmentDuration);
  for (let i=0; i< size;i++){
    const bookedSlot =findMatchingSLot(slots,i);
    const unavailableSlot =findMatchingSLot(unavailability,i);
    if(bookedSlot){
      cells[i] = mapBookedSlot(i,bookedSlot);
    }else if(unavailableSlot){
      cells[i] = {type:'unavailable',index:i} as any;
    }else{
      cells[i] = {type:'unoccupied',text:'',index:i,isAvailableForTreatment:availableSlotsForTreatment[i]};
    }

  }
  return cells;
};

const isMultiPayment = (payment:Payment):boolean => {
  return (payment.cashValue> 0 && payment.cardValue > 0)
  ||(payment.cashValue> 0 && payment.voucherValue > 0)
  ||(payment.voucherValue> 0 && payment.cardValue > 0)
};

const padValue=(value:number):string=>{
  const valueString = value.toString();
  if(valueString.indexOf('.')<0)
    return valueString;
  return value.toFixed(2);
};

const mapBookedSlot = (slotNumber:number,bookedSlot:BookingSlot):SlotCell => {
  let cell:SlotCell = {
    isFirstCell:false,
    isLastCell:false,
    text:'',
    index:slotNumber,
    extraClasses:'',
    type:'booked',
    paid : false,
    slot : bookedSlot,
    multiPayment : false
  };

  if(slotNumber === bookedSlot.start){
    cell.isFirstCell = true;
    cell.text = (bookedSlot.customerName);
  }
  if(slotNumber === bookedSlot.end){
    cell.isLastCell = true;
  }
  if(slotNumber === bookedSlot.start+1){
    cell.text = bookedSlot.treatment||'';
    if(bookedSlot.paid){
      const payment = bookedSlot.paid as Payment;
      if((payment.cashValue+payment.cardValue+payment.voucherValue) != 0){
        cell.text += ` - \u20AC${padValue(payment.cashValue+payment.cardValue+payment.voucherValue)} ${payment.cardValue>0&&!isMultiPayment(payment)?'card':''}${payment.voucherValue>0&&!isMultiPayment(payment)?'voucher':''}${isMultiPayment(payment)?'multi':''}`;
        cell.paid = true;
      }
      if(isMultiPayment(payment)){
        cell.multiPayment = true;
      }
    }
    if(bookedSlot.noShow){
      cell.text += ` - NO SHOW`;
    }
  }
  if(cell.isFirstCell && cell.isLastCell){
    if (bookedSlot.paid){
      const payment = bookedSlot.paid as Payment;
      if(( payment.cashValue+payment.cardValue+payment.voucherValue) != 0){
        cell.paid = true;
      }
      if(isMultiPayment(payment)){
        cell.multiPayment = true;
      }
    }

  }
  if(cell.text == "undefined"){
    cell.text = '';
  }


  return cell;
};

const findMatchingSLot = (slots:TimeSlots,index) => {
  let busy = null;
  Object.keys(slots).forEach((key:string) => {
    if (index >= slots[key].start && index <= slots[key].end){
      busy= slots[key];
    }
  });
  return busy;
};
