import fire from './fire';
import {getAuth,signInAnonymously} from 'firebase/auth';
import {getDatabase,ref,orderByKey,limitToLast,query,onChildChanged} from 'firebase/database';

import store from '../store/storeProvider';

const startRtu = async (cb) => {
  const {authentication} = store.getState();
  const organization = authentication.organization;
  const auth=getAuth();
  await signInAnonymously(auth).catch(function(error) {
    console.log(error);
  });
  const db = getDatabase(fire);
  let bookingsRef = await query(ref(db,`${organization}/bookings`),limitToLast(250),orderByKey());
  onChildChanged(bookingsRef, snapshot => {
    cb(snapshot.key,snapshot.val());
  });
};

export default startRtu;

