import {TimeSlot} from "../model/TimeSlot";

export function isSlotAvailable (slots:TimeSlot[], totalDuration:number, treatmentDuration:number):boolean[]{
  const slotAvailability =[];
  for (let i=0; i < totalDuration;i++){
    slotAvailability[i] = false;
  }
  for (let i=0; i < totalDuration-treatmentDuration+1;i++){
    slotAvailability[i] = true;
  }
  slots.forEach(slot => {
    for (let i=Math.max(slot.start-treatmentDuration+1,0); i <= slot.end;i++){
      slotAvailability[i] = false;
    }
  });
  return slotAvailability;
}
