import initialState from './initialState';
import {SET_SYSTEM_DATE} from '../constants/actionTypes';

export default (state=initialState.viewDate,action) => {
  switch(action.type){
    case SET_SYSTEM_DATE:
      return action.newDate;
    default:
      return state;
  }
};
