import initialState from './initialState';
import {CUSTOMER_ADDED_SUCCESS, CUSTOMERS_LOADED_SUCCESS} from '../constants/actionTypes';

export default function (state=initialState.customers, action) {
  switch (action.type) {
    case CUSTOMERS_LOADED_SUCCESS:
        return action.customers;
    case CUSTOMER_ADDED_SUCCESS:
        return [...state,action.customer];
    default:
      return state;
  }

}
