import {
  DISPLAY_TREATMENT_PANEL,
  HIDE_CUSTOMER_LOOKUP,
  HIDE_TREATMENT_PANEL,
  LOAD_TREATMENTS_SUCCESS,
  RESET_TREATMENT_PANEL,
  UPDATE_INPUT_CUSTOMER_FIRST_TIME,
  UPDATE_INPUT_CUSTOMER_NAME,
  UPDATE_INPUT_CUSTOMER_TELEPHONE,
  UPDATE_INPUT_EXTRA_TIME,
  UPDATE_INPUT_TREATMENT,
  UPDATE_INPUT_TREATMENT_TYPE,
  UPDATE_TREATMENTS_LIST_SUCCESS
} from '../constants/actionTypes';
import {loadTreatmentsFromDb, updateTreatmentsToDb} from '../api/treatmentApi';
import {hideSpinner, showSpinner} from "./spinnerActions";
import {ThunkAction} from "redux-thunk";

export function displayTreatmentPanel (){
  return (dispatch) => {
    dispatch ({type:DISPLAY_TREATMENT_PANEL});
  };
}

export function hideTreatmentPanel ():ThunkAction<void, any, any, any>{
  return (dispatch) => {
    dispatch ({type:HIDE_TREATMENT_PANEL});
  };
}

export function updateCustomerName (name){
  return (dispatch) => {
    dispatch ({type:UPDATE_INPUT_CUSTOMER_NAME,name});
  };
}

export function updateCustomerFirstTime (firstTime):ThunkAction<void, any, any, any>{
  return (dispatch) => {
    dispatch ({type:UPDATE_INPUT_CUSTOMER_FIRST_TIME,firstTime});
  };
}

export function updateCustomerTelephone (phoneNumber):ThunkAction<void, any, any, any>{
  return (dispatch) => {
    dispatch ({type:UPDATE_INPUT_CUSTOMER_TELEPHONE,phoneNumber});
  };
}

export function updateTreatment (name){
  return (dispatch) => {
    dispatch ({type:UPDATE_INPUT_TREATMENT,name});
  };
}

export function updateTreatmentType (name){
  return (dispatch) => {
    dispatch ({type:UPDATE_INPUT_TREATMENT_TYPE,name});
  };
}

export function updateExtraTime (count){
  return (dispatch) => {
    dispatch ({type:UPDATE_INPUT_EXTRA_TIME,extraTime:count});
  };
}

export function loadTreatmentsSuccess(treatments){
  return {type:LOAD_TREATMENTS_SUCCESS,treatments};
}

export function updateTreatmentsListSuccess(treatments){
  return {type:UPDATE_TREATMENTS_LIST_SUCCESS,treatments};
}

export function loadTreatments() {
  return dispatch => {
    loadTreatmentsFromDb().then(treatments => {
        dispatch (loadTreatmentsSuccess(treatments));
      });
  };
}

export const addTreatmentGroup = (newGroup)=>{
  return (dispatch,getState) => {
    dispatch(showSpinner());
    const treatments = [...getState().treatments,{name:newGroup,items:[]}];
    updateTreatmentsToDb(treatments).then(updatedTreatments => {
      dispatch (updateTreatmentsListSuccess(updatedTreatments));
      dispatch(hideSpinner());
    });
  };
};

export const deleteTreatmentGroup = (groupToDelete)=>{
  return (dispatch,getState) => {
    dispatch(showSpinner());
    const treatments = [...getState().treatments.filter(treatment=>treatment.name !== groupToDelete)];
    updateTreatmentsToDb(treatments).then(updatedTreatments => {
      dispatch (updateTreatmentsListSuccess(updatedTreatments));
      dispatch(hideSpinner());
    });
  };
};

export const addTreatmentToGroup = (groupName,newTreatment)=>{
  return (dispatch,getState) => {
    dispatch(showSpinner());
    const treatments = [...getState().treatments];
    const updatedGroup = {...treatments.filter(group => group.name == groupName)[0]};
    updatedGroup.items = [...updatedGroup.items||[],newTreatment];
    const newTreatments = [...treatments.filter(group => group.name != groupName),updatedGroup];
    updateTreatmentsToDb(newTreatments).then(updatedTreatments => {
      dispatch (updateTreatmentsListSuccess(updatedTreatments));
      dispatch(hideSpinner());
    });
  };
};

export const deleteTreatmentFromGroup = (groupName,treatmentToDelete)=>{
  return (dispatch,getState) => {
    dispatch(showSpinner());
    const treatments = [...getState().treatments];
    const updatedGroup = {...treatments.filter(group => group.name == groupName)[0]};
    updatedGroup.items = [...(updatedGroup.items||[]).filter(groupItem=>groupItem.name!==treatmentToDelete)];
    const newTreatments = [...treatments.filter(group => group.name != groupName),updatedGroup];
    updateTreatmentsToDb(newTreatments).then(updatedTreatments => {
      dispatch (updateTreatmentsListSuccess(updatedTreatments));
      dispatch(hideSpinner());
    });
  };
};

export function resetTreatmentPanel (){
  return (dispatch) => {
    dispatch ({type:RESET_TREATMENT_PANEL});
  };
}

export function hideCustomerLookup (){
  return (dispatch) => {
    dispatch ({type:HIDE_CUSTOMER_LOOKUP});
  };
}
