import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {fetchTheme} from "../api/themeApi";
import {Theme} from "../model/Theme";
import {THEME_LOADED_SUCCESS} from "../constants/actionTypes";

export interface ThemeAction {
  type : THEME_LOADED_SUCCESS,
  theme : Theme
}

function themeloadedSuccess(theme: Theme) : ThemeAction {
  return {type:THEME_LOADED_SUCCESS,theme};
}

export const loadTheme = ():ThunkAction<void,null,null,null> => {
  return (dispatch:ThunkDispatch<null,null,ThemeAction>)=>{
    fetchTheme().then(theme=>{
      dispatch(themeloadedSuccess(theme));
    });
  };
};
