import {base_url} from '../common/config';
import {SHA256} from 'crypto-js';
import db from '../services/indexedb';
import {isOffline} from '../services/offlineStatus';
import fetchJson from './fetchJson';
import moment from "moment";
import {putToBackend} from "./backendApi";

const persistUserToCache = (name, password)=> {
  const encryptedPassword = SHA256(password);

  db.table('users').put(encryptedPassword,name);
};

const authenticateUserOnline= (name, password)=>{
  return new Promise((resolve,reject)=>{
    fetchJson(`${base_url}/logins/${name.toLowerCase()}.json`).then(user =>{
          if(user &&  user.password===password ){
              if(user.disabled){
                reject("User is disabled.");
              }
              persistUserToCache(name,password);
              updateUsers({...user,lastLoggedIn:moment().format("YYYY-MM-DD HH:mm:SS")});
              resolve({name,password,roles:user.roles?user.roles:{},organization:user.organization});
          }else{
            reject("Invalid user/password combinations");
          }
    });
  });
};

function authenticateUserOffline(name, password) {
  return new Promise((resolve,reject)=>{
    db.table('logins')
      .get(name)
      .then(cachedPassword => {
        if (!cachedPassword){
          reject("Invalid user/password combinations");
        }else{
          const encryptedPassword = SHA256(password);
          if (JSON.stringify(cachedPassword.words) === JSON.stringify(encryptedPassword.words)){
            resolve({name,password});
          }else {
            reject("Invalid user/password combinations");
          }
        }
      });
  });
}

export const authenticateUser = (name, password)=>{
  if (isOffline()){
    return authenticateUserOffline(name,password);
  }else{
      return authenticateUserOnline(name,password);
  }
};

export const fetchUsers = ()=>{
  return fetchJson(`${base_url}/logins.json`).then(logins => {
    const loginsArray = [];
    Object.keys(logins).forEach(login => {
      loginsArray.push(logins[login]);
    });
    return loginsArray;
  });
};

export const updateUsers = (updatedUser)=>{
  return putToBackend(`${base_url}/logins/${updatedUser.name.toLowerCase()}.json`,updatedUser);
};
