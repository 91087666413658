import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {START_PAGE} from '../../constants/editPages';
import EditTechnicianStartPage from './EditTechnicianStartPage';
import {
  closeTechnicianWizard, makeTechnicianAvailable, makeTechnicianUnavailable,
  setTechnicianWizardPage
} from '../../actions/technicianWizardActions';
import {TECHNICIAN_AVAILABLE_PAGE, TECHNICIAN_UNAVAILABLE_PAGE} from '../../constants/editTechnican';
import YesNoConfirmation from '../common/confirmation/YesNoConfirmation';

export class EditTechnician extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.makeUnavailable = this.makeUnavailable.bind(this);
        this.makeAvailable = this.makeAvailable.bind(this);
        this.returnToStartPage = this.returnToStartPage.bind(this);
    }

    makeUnavailable () {
      this.props.actions.makeTechnicianUnavailable(this.props.bookings,this.props.data.technician);
    }

    makeAvailable () {
      this.props.actions.makeTechnicianAvailable(this.props.bookings,this.props.data.technician);
    }

    returnToStartPage (){
      this.props.actions.setWizardPage(START_PAGE);
    }

    render() {
      switch (this.props.page) {
        case START_PAGE:
          return (
            <EditTechnicianStartPage closeModal={this.props.actions.closeModal} setWizardPage={this.props.actions.setWizardPage} technician={this.props.data.technician} />
          );
        case TECHNICIAN_UNAVAILABLE_PAGE:
          return (
            <YesNoConfirmation onYes={this.makeUnavailable}
                               onNo={this.returnToStartPage}
                               message="Are you sure you want to make this technician unavailable all day?"
            >
              <span>Technician: {this.props.data.technician}</span>
            </YesNoConfirmation>
          );
        case TECHNICIAN_AVAILABLE_PAGE:
          return (
            <YesNoConfirmation onYes={this.makeAvailable}
                               onNo={this.returnToStartPage}
                               message="Are you sure you want to make this technician available all day?"
            >
              <span>Technician: {this.props.data.technician}</span>
            </YesNoConfirmation>
          );
        default:
          return (
            <div/>
          );
      }
    }
}

EditTechnician.propTypes = {
    actions : PropTypes.object.isRequired,
    bookings : PropTypes.array.isRequired,
    page : PropTypes.string.isRequired,
    data : PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
      page: state.editTechnician.page,
      bookings: state.bookings,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
          closeModal : () => dispatch(closeTechnicianWizard()),
          setWizardPage : (page) => dispatch(setTechnicianWizardPage(page)),
          makeTechnicianAvailable : (bookings,technician) => dispatch(makeTechnicianAvailable(bookings,technician)),
          makeTechnicianUnavailable : (bookings,technician) => dispatch(makeTechnicianUnavailable(bookings,technician)),
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTechnician);
