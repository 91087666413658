import {PaymentV3Dto} from "../../model/dtos/PaymentV3Dto";
import {Payment} from "../../model/Payment";
import {ValueAndNotes} from "./mapDtoToPayment.old";

const zeroPayment:Payment = {
  cashValue:0,
  cardValue:0,
  voucherValue:0,
  note:''
};

const noteOnlyPayment = (note:string):Payment =>{
  return {
    cashValue:0,
    cardValue:0,
    voucherValue:0,
    note
  }
};

const isTypeV3 =(payment : string | PaymentV3Dto | Payment):payment is PaymentV3Dto => {
  return (payment as PaymentV3Dto).value !== undefined;
};

const isTypeV4 =(payment : string | Payment):payment is Payment => {
  return (payment as Payment).cashValue !== undefined;
};

const isMixedPaymentString =(paymentString : string):boolean => {
  return isCardAndVoucherSplit(paymentString) || isVoucherAndCardSplit(paymentString);
};

function processType3(dto: PaymentV3Dto) {
  if(isMixedPaymentString(dto.value)){
    return processMixedPayments(dto.value);
  }
  if(startsWithAmount(dto.value) || startsWithAmountAndKeywordCash(dto.value)){
    const parsedValues = pullSingleValueFromString(dto.value,/^[\d.]+[ ]*(?:cash)?/);
    return {cashValue:(!dto.cardPayment && !dto.voucherPayment)?parsedValues.value:0,cardValue:dto.cardPayment?parsedValues.value:0,voucherValue:dto.voucherPayment?parsedValues.value:0,note:parsedValues.note};
  }
  return noteOnlyPayment(dto.value);
}

const processMixedPayments =(paymentString:string):Payment=>{
  if(isCardAndVoucherSplit(paymentString)){
    let parsedValues = pullSingleValueFromString(paymentString,/^[\d.]+[€]*[ ]*[on]*[ ]*card/);
    const cardValue = parsedValues.value;
    parsedValues = pullSingleValueFromString(parsedValues.note.replace(/[ ]*\+[ ]*/,''),/^[\d.]+[€]*[ ]*[on]*[ ]*voucher/);
    return {cashValue:0,cardValue,voucherValue:parsedValues.value,note:parsedValues.note};
  }

  if(isVoucherAndCardSplit(paymentString)){
    let parsedValues = pullSingleValueFromString(paymentString,/^[\d.]+[€]*[ ]*[on]*[ ]*voucher/);
    const voucherValue = parsedValues.value;
    parsedValues = pullSingleValueFromString(parsedValues.note.replace(/[ ]*\+[ ]*/,''),/^[\d.]+[€]*[ ]*[on]*[ ]*card/);
    return {cashValue:0,cardValue:parsedValues.value,voucherValue,note:parsedValues.note};
  }
}

const pullSingleValueFromString=(paymentString:string,regexp:RegExp):ValueAndNotes=>{
  let value = parseFloat(paymentString);
  value = isNaN(value)?0:value;
  const note = paymentString.trim().replace( regexp,'').trim();
  return {value,note};
};

const startsWithAmount = (paymentString:string):boolean =>{
  return /^[\d.]+/.test(paymentString);
};

const startsWithAmountAndKeywordCash = (paymentString:string):boolean =>{
  return /^[\d.]+[ ]*cash/.test(paymentString);
};

const startsWithAmountAndCard = (paymentString:string):boolean =>{
  return /^[\d.]+[€]*[ ]*[on]*[ ]*card/.test(paymentString);
};

const startsWithAmountAndVoucher = (paymentString:string):boolean =>{
  return /^[\d.]+[€]*[ ]*voucher/.test(paymentString);
};

const startsWithAmountAndOwed = (paymentString:string):boolean =>{
  return /^[\d.]+[€]*[ ]*[Oo]wed/.test(paymentString);
};

const isCashAndCardSplit = (paymentString:string):boolean =>{
  return /^[\d.]+[€]*[ ]*[+][ ]*[\d.]+[ ]*card/.test(paymentString);
};

const isCashAndVoucherSplit = (paymentString:string):boolean =>{
  return /^[\d.]+[€]*[ ]*[+][ ]*[\d.]+[ ]*voucher/.test(paymentString);
};

const isCardAndVoucherSplit = (paymentString:string):boolean =>{
  return /^[\d.]+[€]*[ ]*card[ ]*[+][ ]*[\d.]+[ ]*voucher/.test(paymentString);
};

const isVoucherAndCardSplit = (paymentString:string):boolean =>{
  return /^[\d.]+[€]*[ ]*voucher[ ]*[+][ ]*[\d.]+[ ]*card/.test(paymentString);
};

const mapStringDtoToPayment = (dto:string):Payment => {
  if(isMixedPaymentString(dto)){
    return processMixedPayments(dto);
  }

  if(startsWithAmountAndCard(dto)){
    const parsedValues = pullSingleValueFromString(dto,/^[\d.]+[€]*[ ]*[on]*[ ]*card/);
    return {cashValue:0,cardValue:parsedValues.value,voucherValue:0,note:parsedValues.note};
  }
  if(startsWithAmountAndVoucher(dto)){
    const parsedValues = pullSingleValueFromString(dto,/^[\d.]+[€]*[ ]*voucher/);
    return {cashValue:0,cardValue:0,voucherValue:parsedValues.value,note:parsedValues.note};
  }
  if(startsWithAmountAndOwed(dto)){
    return noteOnlyPayment(dto);
  }
  if(isCashAndCardSplit(dto)){
    let parsedValues = pullSingleValueFromString(dto,/^[\d.]+/);
    const cashValue = parsedValues.value;
    parsedValues = pullSingleValueFromString(parsedValues.note.replace(/[ ]*\+[ ]*/,''),/^[\d.]+[€]*[ ]*[on]*[ ]*card/);
    return {cashValue,cardValue:parsedValues.value,voucherValue:0,note:parsedValues.note};
  }
  if(isCashAndVoucherSplit(dto)){
    let parsedValues = pullSingleValueFromString(dto,/^[\d.]+/);
    const cashValue = parsedValues.value;
    parsedValues = pullSingleValueFromString(parsedValues.note.replace(/[ ]*\+[ ]*/,''),/^[\d.]+[€]*[ ]*[on]*[ ]*voucher/);
    return {cashValue,cardValue:0,voucherValue:parsedValues.value,note:parsedValues.note};
  }
  if(startsWithAmount(dto)){
    const parsedValues = pullSingleValueFromString(dto,/^[\d.]+/);
    return {cashValue:parsedValues.value,cardValue:0,voucherValue:0,note:parsedValues.note};
  }
  return noteOnlyPayment(dto);
};

function processType4(dto: Payment):Payment {
  return dto;
}

const mapDtoToPayment = (dto:undefined|string|boolean|PaymentV3Dto|Payment):Payment=>{
  if(dto == undefined || dto === false|| dto === true || dto === '')
    return zeroPayment;
  if(isTypeV3(dto)){
    return processType3(dto);
  }
  if(isTypeV4(dto)){
    return processType4(dto);
  }
  return mapStringDtoToPayment(dto);
};

export default mapDtoToPayment;
