import initialState from './initialState';
import {HIDE_SPINNER, SHOW_SPINNER} from '../constants/actionTypes';

function actionTypeEndsInSuccess(type) {
  return type.substring(type.length-8) == '_SUCCESS';
}

export default function (state=initialState.spinner,action){
  if (actionTypeEndsInSuccess(action.type))
    return false;
  switch(action.type){
    case SHOW_SPINNER:
      return true;
    case HIDE_SPINNER:
      return false;
    default:
      return state;
  }
}
