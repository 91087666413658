import React from 'react';
import PropTypes from 'prop-types';
import PropertyLookupItem from './PropertyLookupItem';
import {TreatmentPanelLookupWrapper} from './Atoms';

const PropertyLookupPanel = ({filter,customers,onClickItem}) => {
    return (
      <TreatmentPanelLookupWrapper>
        {customers.
          filter(item => (item.name.toLowerCase().indexOf(filter.toLowerCase()) === 0))
          .slice(0,3)
          .map(item => <PropertyLookupItem key={item} customer={item} onClickItem={onClickItem} />)}
      </TreatmentPanelLookupWrapper>
    );
};

PropertyLookupPanel.propTypes = {
    filter : PropTypes.string.isRequired,
    customers : PropTypes.array.isRequired,
    onClickItem : PropTypes.func.isRequired
};

export default PropertyLookupPanel;
