import React from 'react';
import {SearchPanelWrapper} from "./Atoms";
import AuditLogs from "../admin/auditLog/AuditLogs";

export interface SearchPanelProps{
  children : number;
}

const SearchPanel=()=>{
  return (<SearchPanelWrapper>
    {<AuditLogs />}
  </SearchPanelWrapper>);
}

export default SearchPanel;
