import {LOAD_BOOKING_SUCCESS, UPDATE_BOOKINGS_SUCCESS} from '../constants/actionTypes';
import {addBooking, moveSlotToDifferentTechnician, removeBooking, updateCalendarBooking} from '../services/booking';
import {hideTreatmentPanel} from './treatmentPanelActions';
import {loadBookingsFromDb, updateBookings} from '../api/bookingsApi';
import {showSpinner} from './spinnerActions';
import {addAudit} from '../api/auditApi';
import {errorOccurred} from './errorActions';
import {Treatment} from "../model/Treatment";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {AuditAction} from "../model/dtos/AuditAction";
import {BookingPage} from "../model/BookingPage";
import {Authentication} from "../model/Authentication";
import {Bookings} from "../model/Bookings";
import moment from "moment";
import {BookingSlotUpdate} from "../model/BookingSlotUpdate";

export interface BookingAction extends Action {
  type : UPDATE_BOOKINGS_SUCCESS,
  bookingPage : BookingPage
}

export interface LoadBookingAction {
  type : LOAD_BOOKING_SUCCESS,
  bookingPage : BookingPage
}

interface IState {
  authentication : Authentication
}

export function updateBookingsSuccess(bookingPage:BookingPage) : BookingAction {
  return {type:UPDATE_BOOKINGS_SUCCESS,bookingPage};
}

export function makeBooking (bookingPage:BookingPage,technician:string,customerName:string,treatment:Treatment,startSlot:number,extraTime:number,telephone:string,firstTime:boolean):ThunkAction<Promise<any>,IState,null,null>{
  return async (dispatch:ThunkDispatch<null,null,BookingAction>,getState:any) => {
    dispatch(showSpinner());
    const newBookings = addBooking(bookingPage, technician, customerName, treatment, startSlot, extraTime, telephone,firstTime);
    const {authentication}:{authentication:Authentication} = getState();
    try {
      await updateBookings(newBookings);
      await addAudit(authentication.userName, AuditAction.MakeBooking, bookingPage.date.format("YYYY-MM-DD"), {
        technician,
        customerName,
        treatment,
        startSlot,
        extraTime,
        telephone
      });
      dispatch(updateBookingsSuccess(newBookings));
      dispatch(hideTreatmentPanel());
    } catch (exp) {
        dispatch (errorOccurred(exp));
    }
  };
}

export function loadBookingsSuccess(bookingPage:BookingPage):LoadBookingAction {
  return {type:LOAD_BOOKING_SUCCESS,bookingPage};
}

export function loadBookings (date:moment.Moment):ThunkAction<void,IState,null,null> {
 return (dispatch:ThunkDispatch<null,null,LoadBookingAction>) => {
   dispatch(showSpinner());
   console.log(loadBookingsFromDb);
   loadBookingsFromDb(date).then((bookings:Bookings) =>{
      dispatch(loadBookingsSuccess({bookings,date}));
     });
 };
}

export function cancelBooking(bookingPage:BookingPage,technician:string,slotNumber:number):ThunkAction<void,IState,null,null>{
  return async (dispatch:ThunkDispatch<null,null,BookingAction>,getState:any) => {
    dispatch(showSpinner());
    const newBookings = removeBooking(bookingPage.bookings,technician,slotNumber);
    const newBookingPage = {...bookingPage,bookings:newBookings};
    const {authentication} = getState();
    try{
      await updateBookings(newBookingPage);
      await addAudit(authentication.userName, AuditAction.RemoveBooking, bookingPage.date.format("YYYY-MM-DD"), {
        technician,
        slotNumber
      });
      dispatch(updateBookingsSuccess(newBookingPage));
    }catch (e) {
      dispatch (errorOccurred(e));
    }
  };
}

export function updateBooking(bookingPage:BookingPage,technician:string,slotNumber:number,newBookingSlot:BookingSlotUpdate):ThunkAction<void,IState,null,null>{
  return async (dispatch:ThunkDispatch<null,null,BookingAction>,getState:any) => {
    dispatch(showSpinner());
    const serverBooking = await loadBookingsFromDb(bookingPage.date);
    const newBookings = updateCalendarBooking(serverBooking,technician,slotNumber,newBookingSlot);
    const newBookingPage = {...bookingPage,bookings:newBookings}
    const {authentication} = getState();
    try {
      await updateBookings(newBookingPage);
      await addAudit(authentication.userName,AuditAction.UpdateBooking, bookingPage.date.format("YYYY-MM-DD"), {
        technician,
        slotNumber,
        changes:newBookingSlot
      });
      dispatch(updateBookingsSuccess(newBookingPage));
    }catch (e) {
      dispatch (errorOccurred(e));
    }
  };
}

export function moveSlotToOtherTechnician(bookingPage:BookingPage,fromTechnician:string,toTechnician:string,slotNumber:number):ThunkAction<void,IState,null,null>{
  return async (dispatch:ThunkDispatch<null,null,BookingAction>,getState:any)=>{
    dispatch(showSpinner());
    const newBookings =moveSlotToDifferentTechnician(bookingPage.bookings,fromTechnician,toTechnician,slotNumber);
    const newBookingPage = {...bookingPage,bookings:newBookings};
    const {authentication} = getState();
    try{
      await updateBookings(newBookingPage);
      await addAudit(authentication.userName, AuditAction.UpdateBooking, bookingPage.date.format("YYYY-MM-DD"), {
        technician:fromTechnician,
        slotNumber,
        changes:{technician:toTechnician}
      });
      dispatch(updateBookingsSuccess(newBookingPage));
    }catch(e){
      dispatch (errorOccurred(e));
    }
  };
}
