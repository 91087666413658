export const sundayTemplate = [
  {
    technician:"Rachel" ,
    sortOrder:1,
    slots:[],
    unavailability:[{start:0,end:47}]
  },
  {
    technician:"Rina" ,
    sortOrder:2,
    slots:[],
    unavailability:[{start:0,end:47}]
  },
  {
    technician:"Karen",
    sortOrder:3,
    slots:[],
    unavailability:[{start:0,end:47}]
  } ,
  {
    technician:"Sharon" ,
    sortOrder:4,
    slots:[],
    unavailability:[{start:0,end:47}]
  }
];
