import treatmentPanel from './treatmentPanelReducer';
import bookings from './bookingsReducer';
import mode from './modeReducer';
import editWizard from './editWizardReducer';
import editTechnician from './editTechnicianReducer';
import viewDate from './viewDateReducer';
import authentication from './authenticationReducer';
import treatments from './treatmentsReducer';
import spinner from './spinnerReducer';
import customers from './customersReducer';
import dailySummary from './dailySummaryReducer';
import admin from './adminReducer';
import { combineReducers } from 'redux';
import modalState from './modalReducer';
import theme from './themeReducer';

const rootReducer = combineReducers({
  treatmentPanel,
  bookings,
  mode,
  modalState,
  editWizard,
  viewDate,
  authentication,
  spinner,
  treatments,
  editTechnician,
  customers,
  dailySummary,
  admin,
  theme
});

export default rootReducer;
