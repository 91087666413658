import {NORMAL_MODE} from '../common/modes';
import {START_PAGE} from '../constants/editPages';
import moment from 'moment';
import {USER_ADMIN_PAGE} from '../constants/adminPages';

export default {
  treatmentPanel : {
    customerName  :"",
    treatmentType : "",
    defaultTreatmentType : "",
    treatment : {},
    defaultTreatment : {},
    extraTime : 0,
    customerTelephone:'',
    showCustomerLookup : false
  },
  treatments :[],
  bookings : {
    bookings: {}
  },
  mode : NORMAL_MODE,
  modalState : {render:[],modalParameters:{}},
  editWizard : {page:START_PAGE},
  editTechnician : {page:START_PAGE},
  viewDate : moment(),
  authentication : {
    authenticated:false,
    userName : '',
    password : '',
    roles : {},
    error : '',
    organization : ''
  },
  customers : [],
  spinner : false,
  dailySummary:[{name:'Claudia',paymentReceived:72.8,timeBooked:10.5},{name:'Rachel',paymentReceived:82.8,timeBooked:11.75},
    {name:'Rina',paymentReceived:59.8,timeBooked:6.25},{name:'Sharon',paymentReceived:0,timeBooked:0}],
  admin : {
    page : USER_ADMIN_PAGE,
    users : []
},
  theme:{
    title : 'No Title'
  }
};
