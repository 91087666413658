import { initializeApp } from 'firebase/app';

const config = {
  apiKey: "AIzaSyDl8VlJ1HPqF38cWPGUcTZzuTBYCZlXiSE",
  authDomain: "tipsy-nails.firebaseapp.com",
  databaseURL: "https://tipsy-nails.firebaseio.com",
  projectId: "tipsy-nails",
  storageBucket: "tipsy-nails.appspot.com",
  messagingSenderId: "1023372295852"
};
const fire = initializeApp(config);

export default fire;
