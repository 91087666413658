import React from 'react';
import {AVAILABILITY_MODE, BOOKING_MODE} from '../../common/modes';
import PropTypes from 'prop-types';
import {CalendarCellButton, CalendarCellWrapper} from './Atoms';

const CalendarCellEmpty = ({mode,cellData,onMakeBooking,onMakeUnavailable}) => {
  const doButtonAction = () => {
    switch (mode){
      case BOOKING_MODE:
        captureBooking();
        break;
      case AVAILABILITY_MODE:
        makeUnavailable ();
        break;
    }
  };

  const captureBooking= ()=> {
    onMakeBooking(cellData.index);
  };

  const makeUnavailable=()=> {
    onMakeUnavailable(cellData.index);
  };

  const showButton =(mode===BOOKING_MODE && cellData.isAvailableForTreatment) || (mode == AVAILABILITY_MODE);
  return (
    <CalendarCellWrapper first={cellData.IsFirstCell} last={cellData.IsLastCell} available={mode==BOOKING_MODE && cellData.isAvailableForTreatment}>
      {showButton && <CalendarCellButton onClick={doButtonAction} />}
    </CalendarCellWrapper>
  );
};

CalendarCellEmpty.propTypes = {
  mode : PropTypes.string.isRequired,
  onMakeBooking : PropTypes.func.isRequired,
  onMakeUnavailable : PropTypes.func.isRequired,
  cellData : PropTypes.object.isRequired
};

export default CalendarCellEmpty;
