import initialState from './initialState';
import {
  DISPLAY_AVAILABILITY_PANEL, DISPLAY_TREATMENT_PANEL, HIDE_AVAILABILITY_PANEL,
  HIDE_TREATMENT_PANEL
} from '../constants/actionTypes';
import {BOOKING_MODE, NORMAL_MODE, AVAILABILITY_MODE} from '../common/modes';

export default function (state = initialState.mode, action) {
  switch (action.type) {
    case DISPLAY_TREATMENT_PANEL:
      return BOOKING_MODE;
    case HIDE_TREATMENT_PANEL:
      return NORMAL_MODE;
    case DISPLAY_AVAILABILITY_PANEL:
      return AVAILABILITY_MODE;
    case HIDE_AVAILABILITY_PANEL:
      return NORMAL_MODE;
    default:
      return state;
  }
}
