import React from 'react';
import {AVAILABILITY_MODE} from '../../common/modes';
import PropTypes from 'prop-types';
import {CalendarCellButton, CalendarCellWrapper} from './Atoms';

const CalendarCellUnavailable = ({slotNumber,mode,onMakeAvailable}) => {
  const makeAvailable= ()=>{
    onMakeAvailable(slotNumber);
  };

  const showButton = (mode == AVAILABILITY_MODE);
  return (
    <CalendarCellWrapper unavailable>
      {showButton && <CalendarCellButton value="" onClick={makeAvailable} />}
    </CalendarCellWrapper>
  );
};

CalendarCellUnavailable.propTypes = {
  slotNumber : PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired,
  onMakeAvailable: PropTypes.func.isRequired
};

export default CalendarCellUnavailable;
