import initialState from './initialState';
import {
  LOGIN_FAILURE, LOGIN_SUCCESS, SET_LOGIN_ORGANIZATION, SET_LOGIN_PASSWORD, SET_LOGIN_ROLES,
  SET_LOGIN_USER
} from '../constants/actionTypes';

export default function (state = initialState.authentication, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return Object.assign({},state,{authenticated: true,error:''});
    case LOGIN_FAILURE:
      return Object.assign({},state,{authenticated: false,error:action.error});
    case SET_LOGIN_USER:
      return Object.assign({},state,{userName:action.userName});
    case SET_LOGIN_ROLES:
      return Object.assign({},state,{roles:action.roles});
      case SET_LOGIN_ORGANIZATION:
      return Object.assign({},state,{organization:action.organization});
      case SET_LOGIN_PASSWORD:
      return Object.assign({},state,{password:action.password});
    default:
      return state;
  }
}
