import fetchJson from './fetchJson';
import {organizationalUrl} from "../services/urlProvider";
import {postToBackend} from "./backendApi";

export const fetchCustomers = () => {
  const url = organizationalUrl();
  return new Promise((resolve,reject) => {
      fetchJson(`${url}/customers.json`).then(resolve,reject);
  });
};

export const addCustomer = (customer,) => {
  const url = organizationalUrl();

  return postToBackend(`${url}/customers.json`,customer);
};
