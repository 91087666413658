import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import LoginPage from './authentication/LoginPage';
import ModalContainer from './common/modalContainer/ModalContainer';
import Spinner from './common/spinner/Spinner';
import NavBar from './common/navBar/NavBar';
import BookingPage from './BookingPage';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import ReportsPage from './reports/ReportsPage';
import AdminPage from './admin/AdminPage';
import SearchPage from "./search/SearchPage";

export class TipsyApp extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <Router basename="/tipsy/">
        <div className="tipsy-app" tabIndex="1">
          {this.props.authenticated && <div className="container">
            <NavBar userRoles={this.props.userRoles}/>
            <Switch>
            {this.props.userRoles["reports"]?<Route path="/Reports" component={ReportsPage}/>:<Redirect from="/Reports" to="/"/>}
            {this.props.userRoles["admin"]?<Route path="/Admin" component={AdminPage}/>:<Redirect from="/Admin" to="/"/>}
            {this.props.userRoles["search"]?<Route path="/Search" component={SearchPage}/>:<Redirect from="/Search" to="/"/>}
              <Route component={BookingPage}/>
            </Switch>
            <ModalContainer/>
            <Spinner/>
          </div>}
          {!this.props.authenticated && <LoginPage/>}
        </div>
      </Router>
    );
  }
}

TipsyApp.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  viewDate: PropTypes.object.isRequired,
  userRoles : PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    authenticated: state.authentication.authenticated,
    viewDate: state.viewDate,
    userRoles : state.authentication.roles
  };
}

function mapDispatchToProps() {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TipsyApp);
