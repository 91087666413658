import React from 'react';
import {AuditLogItemHeaderWrapper} from "./Atoms";
import {AuditEntry} from "../../../model/AuditEntry";

export interface AuditLogItemHeaderProps {
  audit : AuditEntry
}

const AuditLogItemHeader:React.FunctionComponent<AuditLogItemHeaderProps> = ({audit}) => {
    const isBookingEntry = (audit.type === 'Cancel Booking' || audit.type === 'Update Booking'|| audit.type === 'Make Booking');
    return (
        <AuditLogItemHeaderWrapper>
          <label><strong>Date</strong>:{audit.timeStamp.format("YYYY-MM-DD HH:mm:ss")}</label>
          <label><strong>Booking Date</strong>:{audit.bookingDate}</label>
          {isBookingEntry &&<label><strong>Booking Time:</strong>{audit.bookingTime}</label>}
          <label><strong>User</strong>:{audit.user}</label>
          <label><strong>Action</strong>:{audit.type}</label>
          <label><strong>Technician</strong>:{audit.technician}</label>
        </AuditLogItemHeaderWrapper>
    );
};

export default AuditLogItemHeader;
