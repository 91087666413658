export class BookingSummary{
  name : string;
  timeBooked : number;
  paymentReceived : number;
  noShows : number

  constructor(name:string='',timeBooked:number=0,paymentReceived:number=0,noShows:number=0){
    this.name = name;
    this.timeBooked = timeBooked;
    this.paymentReceived = paymentReceived;
    this.noShows = noShows;
  }
}
