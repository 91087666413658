import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {loadDailyReportSummary} from '../../../actions/dailyReportsActions';
import DailyReportToolbar from './DailyReportToolbar';
import DailyReportTechnicianRow from './DailyReportTechnicianRow';
import {ReportContainer, ReportsTotalCell} from '../Atoms';
import {ReportColumnHeader} from './Atoms';

export class DailyReportPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.setDate = this.setDate.bind(this);
  }

  setDate(date) {
    this.setState(state => {
      return {...state, currentDate: date, showDatePicker: false};
    });
    this.props.actions.loadSummary(date);
  }

  roundToTwoFigures(value){
    return Math.round(value*100)/100;
  }

  render() {
    return (
      <div>
        <DailyReportToolbar setDate={this.setDate} />
        <ReportContainer>
          <ReportColumnHeader/>
          <ReportColumnHeader>Booked Time</ReportColumnHeader>
          <ReportColumnHeader>Payment Accepted</ReportColumnHeader>
          <ReportColumnHeader>No Shows</ReportColumnHeader>
          {this.props.dailySummary.map(technician => {
            return <DailyReportTechnicianRow key={technician.name} technician={technician}/>;
          })}
          <ReportsTotalCell>Total</ReportsTotalCell>
          <ReportsTotalCell>{this.roundToTwoFigures(this.props.dailySummary.reduce((prev, currentTechnician) => {
            return prev + currentTechnician.timeBooked||0;
          }, 0))}</ReportsTotalCell>
          <ReportsTotalCell>{this.roundToTwoFigures(this.props.dailySummary.reduce((prev, currentTechnician) => {
            return prev + currentTechnician.paymentReceived||0;
          }, 0))}</ReportsTotalCell>
          <ReportsTotalCell>{this.props.dailySummary.reduce((prev, currentTechnician) => {
            return prev + currentTechnician.noShows||0;
          }, 0)}</ReportsTotalCell>
        </ReportContainer>
      </div>
    );
  }
}

DailyReportPage.propTypes = {
  actions: PropTypes.object.isRequired,
  dailySummary: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    dailySummary: state.dailySummary
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadSummary: date => {
        dispatch(loadDailyReportSummary(date));
      }
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DailyReportPage);
