import styled from 'styled-components';

export const LoginSection = styled.section`
  padding: 5px;
  width:300px;
`;

export const LoginPageText = styled.div`
  color:black;
  font-size: 1.3em;
  padding: 5px;
`;

export const LoginForm = styled.form`
  display: grid;
  padding: 5px;
`;

export const LoginPageInput = styled.input`
  margin: 5px;
  border-radius: 5px;
`;

export const LoginPageError = styled.div`
  color: red;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 1.2em;
`;
