import initialState from './initialState';
import {
  DISPLAY_TREATMENT_PANEL,
  HIDE_CUSTOMER_LOOKUP,
  LOAD_TREATMENTS_SUCCESS,
  RESET_TREATMENT_PANEL,
  UPDATE_INPUT_CUSTOMER_FIRST_TIME,
  UPDATE_INPUT_CUSTOMER_NAME,
  UPDATE_INPUT_CUSTOMER_TELEPHONE,
  UPDATE_INPUT_EXTRA_TIME,
  UPDATE_INPUT_TREATMENT,
  UPDATE_INPUT_TREATMENT_TYPE
} from '../constants/actionTypes';

export default (state = initialState.treatmentPanel,action) => {
  switch (action.type){
    case UPDATE_INPUT_CUSTOMER_NAME:
      return {...state, customerName : action.name,showCustomerLookup:action.name.length>2};
    case UPDATE_INPUT_CUSTOMER_TELEPHONE:
      return {...state, customerTelephone : action.phoneNumber};
      case UPDATE_INPUT_CUSTOMER_FIRST_TIME:
        return {...state, firstTime : action.firstTime};
    case UPDATE_INPUT_TREATMENT:
      return {...state, treatment : action.name};
    case UPDATE_INPUT_TREATMENT_TYPE:
      return {...state, treatmentType : action.name};
      case UPDATE_INPUT_EXTRA_TIME:
      return {...state, extraTime : action.extraTime};
    case DISPLAY_TREATMENT_PANEL:
      return {...state, customerName:'',customerTelephone:'',extraTime:0};
    case LOAD_TREATMENTS_SUCCESS:{
      const treatmentType = action.treatments[0].name;
      const treatment = action.treatments[0].items[0];
      return {...state, treatmentType: treatmentType, treatment: treatment,defaultTreatmentType:treatmentType,defaultTreatment:treatment};
    }
    case RESET_TREATMENT_PANEL:
      return {...state, extraTime:0,treatmentType:state.defaultTreatmentType,treatment:state.defaultTreatment,showCustomerLookup:false,firstTime: false};
    case HIDE_CUSTOMER_LOOKUP:
      return {...state,showCustomerLookup:false};
    default :
      return state;
  }
};
