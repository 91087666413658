import React from 'react';
import PropTypes from 'prop-types';

const MobileNavBar = ({onClick}) => {
    return (
        <div className="mobile-nav-bar">
          <button className="mobile-list-button" onClick={onClick}><i /></button>
        </div>
    );
};

MobileNavBar.propTypes = {
  onClick : PropTypes.func.isRequired
};

export default MobileNavBar;
