import React from 'react';
import PropTypes from 'prop-types';
import CalendarPanel from './CalendarPanel';
import datePanelService from './datePanelService';
import {DatePickerMonthYearPicker, DatePickerWrapper, LeftSmallTriangle, RightSmallTriangle} from './Atoms';
import moment from 'moment';

class DatePicker extends React.Component {
  constructor (props){
    super(props);
    this.state = {
      date : moment(props.date)  ,
      internalDate : moment(props.date)
    };
    this.decreaseMonth= this.decreaseMonth.bind(this);
    this.increaseMonth= this.increaseMonth.bind(this);
    this.decreaseYear= this.decreaseYear.bind(this);
    this.increaseYear= this.increaseYear.bind(this);
  }

  decreaseMonth (){
    this.setState(state => {return {internalDate:state.internalDate.subtract(1,'months'),date:state.date};});
  }

  increaseMonth (){
    this.setState(state => {return {internalDate:state.internalDate.add(1,'months'),date:state.date};});
  }

  decreaseYear (){
    this.setState(state => {return {internalDate:state.internalDate.subtract(1,'years'),date:state.date};});
  }

  increaseYear (){
    this.setState(state => {return {internalDate:state.internalDate.add(1,'years'),date:state.date};});
  }

  render () {
    const dateRange = datePanelService(this.state.internalDate);
    return (
      <div>
          <DatePickerWrapper>
            <DatePickerMonthYearPicker><LeftSmallTriangle type="button" onClick={this.decreaseMonth} />{this.state.internalDate.format("MMMM")}<RightSmallTriangle type="button"  onClick={this.increaseMonth} /></DatePickerMonthYearPicker>
            <DatePickerMonthYearPicker><LeftSmallTriangle type="button" onClick={this.decreaseYear} /><span>{this.state.internalDate.format("YYYY")}</span><RightSmallTriangle type="button" onClick={this.increaseYear} /></DatePickerMonthYearPicker>
            <CalendarPanel startDay={dateRange.startDate} endDay={dateRange.endDate} updateDay={this.props.setDate}/>
          </DatePickerWrapper>
      </div>
    );
  }
}

DatePicker.propTypes = {
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired
};

export default DatePicker;
