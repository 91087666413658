import styled from 'styled-components';

export const TreatmentPanelWrapper = styled.div`
  background-color: grey;
  padding  :15px;
  display:grid;
  grid-template-columns: repeat(auto-fit, 150px)
`;

export const TreatmentPanelGrouping = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  &> select {
    border: 1px solid black;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 2px 2px 3px #666;
  }
`;

export const TreatmentPanelLookupWrapper = styled.div`
  background-color: white;
`;

export const TreatmentPanelButtonsWrapper = styled.div`
  text-align: center;
`;

export const PropertyLookupItemButton = styled.button`
  background-color: transparent;
  border: none !important;
  border-bottom: 1px black dotted !important;
  cursor: pointer;
`;

//PropertyLookupItemButton.displayName = 'PropertyLookupItemButton';
