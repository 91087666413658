import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {AVAILABILITY_MODE} from '../../common/modes';
//import './Availability.scss';
import * as actions from '../../actions/availabilityActions';
import TipsyButton from '../common/TipsyButton';
import {AvailabilityPanelText, AvailabilityPanelWrapper} from './Atoms';

export class AvailabilityPanel extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
      if(this.props.mode == AVAILABILITY_MODE){
        return (
          <AvailabilityPanelWrapper>
            <AvailabilityPanelText>Update your availability</AvailabilityPanelText>
            <div>
              <TipsyButton text="Done" onClick={this.props.actions.hideAvailabilityPanel}/>
            </div>
          </AvailabilityPanelWrapper>
        );
      }else{
        return (
          <div />
        );
      }
    }
}

AvailabilityPanel.propTypes = {
    mode : PropTypes.string.isRequired,
    actions : PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        mode: state.mode
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityPanel);
