import styled from 'styled-components';

export const ReportsPageWrapper = styled.div`
   display: flex;
  width: 100%;
  min-height: 600px;
  height: 100%;
`;

export const ReportContainer = styled.div`
   display : grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 10px;
`;


export const ReportsBodyWrapper = styled.div`
  height: 100%;
  border: 1px solid grey;
  flex: 1;
`;

export const ReportsTotalCell = styled.div`
  font-weight: bold;
`;
