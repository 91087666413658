import React from 'react';
import CalendarCellEmpty from './CalendarCellEmpty';
import CalendarCellUnavailable from './CalendarCellUnavailable';
import CalendarCellOccupied from './CalendarCellOccupied';
import {mapSlots} from '../../services/slotsToCellsMapper';
import CalendarColumnTitle from './CalendarColumnTitle';
import {CalendarColumnWrapper} from './Atoms';
import {TimeSlot} from "../../model/TimeSlot";
import {BookingSlots} from "../../model/BookingSlots";
import {UnavailabilitySlots} from "../../model/UnavailabilitySlots";

/* eslint-disable react/no-multi-comp */

export interface BookingCalendarColumnProps{
  title: string;
  slots: BookingSlots;
  treatmentDuration : number;
  unavailability : UnavailabilitySlots;
  mode : string;
  onMakeBooking : (title:string,startSlot:number)=>void;
  onMakeUnavailable : (title:string,slotNumber:number)=>void;
  onMakeAvailable : (title:string,slotNumber:number)=>void;
  onEditTechnician : (title:string)=>void;
  equipmentSlots : TimeSlot[];
}

const BookingCalendarColumn = ({title,slots,treatmentDuration,unavailability,mode,onMakeBooking,onMakeUnavailable,onMakeAvailable,
                                 onEditTechnician,equipmentSlots}:BookingCalendarColumnProps) => {

    const makeBooking = (startSlot) => {
      onMakeBooking(title,startSlot);
    };

    const makeUnavailable = (slotNumber) => {
      onMakeUnavailable(title,slotNumber);
    };

    const makeAvailable = (slotNumber) => {
      onMakeAvailable(title,slotNumber);
    };

    const editTechnician = () => {
        onEditTechnician(title);
    };

    const slotCount = 48;

    const getTimeSlots = () => {
      const divs = [];
      const cells = mapSlots(slotCount,slots,unavailability,treatmentDuration,equipmentSlots);
      for(let cell of cells){
        switch (cell.type){
          case 'booked':
            divs.push(<CalendarCellOccupied technician={title}
                                            key={cell.index}
                                            mode={mode}
                                            cellData={cell}
                                            slot={cell.slot}
            />);
            break;
          case 'unoccupied':
            divs.push(<CalendarCellEmpty key={cell.index}
                                         cellData={cell}
                                         onMakeBooking={makeBooking}
                                         mode={mode}
                                         onMakeUnavailable={makeUnavailable}
            />);
            break;
          case "unavailable":
            divs.push(<CalendarCellUnavailable mode={mode}
                                               slotNumber={cell.index}
                                               key={cell.index}
                                               onMakeAvailable={makeAvailable}
            />);
            break;
        }
      }
      return divs;

    };
    return (
      <CalendarColumnWrapper>
        <CalendarColumnTitle title={title} onEditTechnician={editTechnician} />
        {getTimeSlots()}
      </CalendarColumnWrapper>
    );
};

export default BookingCalendarColumn;
