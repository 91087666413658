import React, {FunctionComponent, useRef} from "react";
import TipsyButton from '../../common/TipsyButton';
import {
  ActionMenuText,
  TreamentGroupPanelWrapper,
  TreatmentAdminListHeader, TreatmentButtonBar, TreatmentButtonBox,
  TreatmentListTableGroup,
  TreatmentTable
} from "./Atoms";
import TreatmentItem from "./TreatmentItem";
import {color2} from '../../../constants/color';

interface TreatmentGroupPanelProps {
  name:String;
  items:any[];
  onClose:()=>void;
  onAdd:(groupName:String)=>void;
  onDelete:(group:String,treatmentName:String)=>void;
}

const TreatmentGroupPanel:FunctionComponent<TreatmentGroupPanelProps> = ({name,items,onClose,onAdd,onDelete})=>{
  const treatments = useRef(items);
  const handleAdd = ()=>{
    onAdd(name);
  }

  return <TreamentGroupPanelWrapper>
    <ActionMenuText data-testid="user-action-menu-header"><span>Treatment Group:{name}</span></ActionMenuText>
    <TreatmentListTableGroup>
      <TreatmentTable>
        <TreatmentAdminListHeader>Treatment</TreatmentAdminListHeader>
        <TreatmentAdminListHeader>Duration</TreatmentAdminListHeader>
        <TreatmentAdminListHeader />
        <TreatmentAdminListHeader />
        {treatments.current.map((item,index) => <TreatmentItem key={item.name} groupName={name} evenRow={index%2 == 0} treatment={item} onDelete={onDelete} />)}
      </TreatmentTable>
    </TreatmentListTableGroup>
    <TreatmentButtonBar>
      <TreatmentButtonBox>
        <TipsyButton text="Add" color={color2} onClick={handleAdd} />
      </TreatmentButtonBox>
      <TreatmentButtonBox>
        <TipsyButton text="Close" color="#ff4d4d" onClick={onClose} />
      </TreatmentButtonBox>
    </TreatmentButtonBar>
  </TreamentGroupPanelWrapper>;
}

export default TreatmentGroupPanel;
