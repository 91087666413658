import React, {FunctionComponent, useState} from "react";
import TipsyButton from '../../common/TipsyButton';
import TipsyInput from '../../common/TipsyInput';
import {
  ActionMenuText,
  AddTreamentGroupPanelWrapper,
  TreatmentButtonBar,
  TreatmentButtonBox,
  AddTreatmentInputGroup
} from "./Atoms";
import {color2} from '../../../constants/color';

interface AddTreatmentGroupPanelProps{
  exclusionList:String[];
  onAdd:(groupName:String)=>void,
  onClose:()=>{}
}

const AddTreatmentGroupPanel:FunctionComponent<AddTreatmentGroupPanelProps> = ({exclusionList,onAdd,onClose})=>{
  const [newGroup,setNewGroup] = useState('');
  const [addEnabled,setAddEnabled] = useState(false);
  const handleChange = (e)=>{
    const newGroupName = e.target.value;
    setNewGroup(newGroupName);
    if(newGroupName!=='' && !exclusionList.includes(newGroupName)){
      setAddEnabled(true);
    }else{
      setAddEnabled(false);
    }
  }

  const handleSubmit = ()=>{
    onAdd(newGroup);
  }

  return <AddTreamentGroupPanelWrapper>
    <ActionMenuText data-testid="user-action-menu-header"><span>Add New Treatment Group</span></ActionMenuText>
    <AddTreatmentInputGroup>
      <label htmlFor="group-name">Group Name:</label>
      <TipsyInput id="group-name" value={newGroup} onChange={handleChange} ></TipsyInput>
    </AddTreatmentInputGroup>
    <TreatmentButtonBar>
      <TreatmentButtonBox>
        <TipsyButton text="Add" disabled={!addEnabled} color={color2} onClick={handleSubmit} />
      </TreatmentButtonBox>
      <TreatmentButtonBox>
        <TipsyButton text="Close" color="#ff4d4d" onClick={onClose} />
      </TreatmentButtonBox>
    </TreatmentButtonBar>

  </AddTreamentGroupPanelWrapper>
}

export default AddTreatmentGroupPanel;
