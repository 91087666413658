import styled from 'styled-components';

export const DailyReportsToolbarWrapper = styled.div`
   background-color: lightgrey;
  height:35px;
  display:flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding-top: 5px;
`;

export const ReportDateControlWrapper = styled.div`
   display: flex;
  flex-direction: row;
`;

export const ReportDatePickerWrapper = styled.input`
   width: 110px;
  text-align: center;
  margin: 0 5px 0 5px;
  border: 1px solid black;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 2px 2px 3px #666;
`;

export const ReportColumnHeader = styled.div`
   font-weight: bold;
`;

