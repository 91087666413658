import {Bookings} from "../model/Bookings";
import {BookingSummary} from "../model/BookingSummary";
import {BookingSlot} from "../model/BookingSlot";

export default (bookings:Bookings):BookingSummary[] => {
    return Object.keys(bookings).map ((technician:string):BookingSummary => {
      const booking = bookings[technician];
      let timeBooked = 0;
      let paymentReceived = 0;
      let noShows = 0;
      if(!booking.slots){
        return new BookingSummary(technician);
      }
      Object.keys(booking.slots).forEach(slotKey => {
        const slot:BookingSlot = booking.slots[slotKey];
        timeBooked += (slot.end-slot.start+1)/4;
        paymentReceived += slot.paid?(slot.paid.cashValue+slot.paid.cardValue+slot.paid.voucherValue):0;
        noShows += slot.noShow?1:0;
      });
      return new BookingSummary(technician,timeBooked,paymentReceived,noShows);
    });
};
