import {dayStart} from '../common/config';
import moment from 'moment';
import _ from 'lodash';
import {TimeSlot} from "../model/TimeSlot";
import {Bookings} from "../model/Bookings";

export const getSlotTime = (slotNumber:number):string => {
    const startOfDay = moment('2010-01-01T'+dayStart);
    startOfDay.add(slotNumber*15,'minutes');
    return startOfDay.format('HH:mm');
};

export const getBookingStart = (technician:string,slotNumber:number,bookings:Bookings):number|null=>{
  const currentTechnicianBookings = bookings[technician];
  if(!currentTechnicianBookings){
    return null;
  }
  const currentBooking = _.find(currentTechnicianBookings.slots,(slot:TimeSlot)=>{return slot.start<= slotNumber && slot.end >= slotNumber;});
  if(!currentBooking){
    return null;
  }
  return currentBooking.start;
};
