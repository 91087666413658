import React from 'react';
import PropTypes from 'prop-types';
import {
  ConfirmationButtonBar,
  ConfirmationButtonContainer, ConfirmationButtonWrapper,
  ConfirmationDetails,
  ConfirmationPageWrapper,
  ConfirmationPrompt
} from './Atoms';

const YesNoConfirmation = ({children,message,onNo,onYes}) => {
  return (
    <ConfirmationPageWrapper>
      <ConfirmationDetails>
        {children}
      </ConfirmationDetails>
      <ConfirmationPrompt><span>{message}</span></ConfirmationPrompt>
      <ConfirmationButtonBar>
        <ConfirmationButtonContainer>
          <ConfirmationButtonWrapper id ="yes"><button type="button" onClick={onYes}><i/> YES</button></ConfirmationButtonWrapper>
        </ConfirmationButtonContainer>
        <ConfirmationButtonContainer>
          <ConfirmationButtonWrapper id ="no"><button type="button" onClick={onNo}><i/> NO</button></ConfirmationButtonWrapper>
        </ConfirmationButtonContainer>
      </ConfirmationButtonBar>
    </ConfirmationPageWrapper>
  );
};

YesNoConfirmation.propTypes = {
  onNo : PropTypes.func.isRequired,
  onYes : PropTypes.func.isRequired,
  message : PropTypes.string.isRequired,
  children :PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default YesNoConfirmation;
