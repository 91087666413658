/* eslint-disable no-async-promise-executor */

import {authenticateWithFireBase} from "./authApi";

export default url =>{
  return new Promise(async (resolve,reject)=>{
    const token = await authenticateWithFireBase();
      fetch(url+`?auth=${token}`).then(response=>{
          if (response.status !== 200){
              reject (response.error);
          }
          response.json().then(resolve).catch(reject);
      }).catch(reject);
  });
};

/* eslint-enable no-async-promise-executor */
