import {TimeSlot} from "../model/TimeSlot";

export const equipmentTypeMaxedSlots =(occupancy:number[],maxOccupancy:number):TimeSlot[]=>{
  const result = [];
  let inSlot = false;
  let slotStart = -1
  for (let i = 0;i < occupancy.length;i++){
    if(occupancy[i]===maxOccupancy && !inSlot){
      inSlot = true;
      slotStart=i;
    }
    if(occupancy[i] !== maxOccupancy && inSlot){
      inSlot=false;
      result.push({
        start : slotStart,
        end : i-1
      })
    }
  }
  if(inSlot){
    result.push({
      start : slotStart,
      end : occupancy.length-1
    })
  }
  return result;
}
