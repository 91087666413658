import React from 'react';
import PropTypes from 'prop-types';

const TimelineTimeSlot = ({hour}) => {
    return (
      <div className="timeline-time-slot">
        <div className="timeline-time-slot-hour">{hour}</div>
        <div className="timeline-time-slot-minute">00</div>
        <div className="timeline-time-slot-minute">15</div>
        <div className="timeline-time-slot-minute">30</div>
        <div className="timeline-time-slot-minute">45</div>
      </div>
    );
};

TimelineTimeSlot.propTypes = {
  hour : PropTypes.number.isRequired
};

export default TimelineTimeSlot;
