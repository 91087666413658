import {fetchUsers, updateUsers} from "../api/usersApi";
import {USERS_LOADED_SUCCESS, USER_UPDATED} from "../constants/actionTypes";
import {hideSpinner, showSpinner} from "./spinnerActions";
import _ from 'lodash';

export const loadUsers = ()=> {
  return dispatch => {
    dispatch(showSpinner());
    fetchUsers().then(users=>{
      const usersDto = users.map(user => {
        return {
          name: user.name,
          password: user.password,
          roles: user.roles,
          lastLoggedIn:user.lastLoggedIn || '1970-01-01',
          disabled:user.disabled
        };
      });
      dispatch({type:USERS_LOADED_SUCCESS,users:usersDto});
      dispatch(hideSpinner());
    });
  };
};

export const toggleUsersActive = (users,userToToggle)=>{
  return dispatch => {
    dispatch(showSpinner());
    const unToggledUser = _.find(users,user => (user.name === userToToggle));
    const updatedUser = {...unToggledUser,disabled : !unToggledUser.disabled};
    updateUsers(updatedUser).then(()=>{
      dispatch({type:USER_UPDATED,updatedUser});
      dispatch(hideSpinner());
    });
  };
};
