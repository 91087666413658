import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {closeWizardPage} from '../../../actions/editWizardAction';
import {updateBooking} from '../../../actions/calendarActions';
import TipsySubmitButton from '../../common/TipsySubmitButton';
import TipsyButton from '../../common/TipsyButton';
import TipsyInput from '../../common/TipsyInput';
import {
  EditBookingButtonPanel,
  EditBookingGrouping,
  EditBookingPageBody,
  EditBookingPageForm,
  EditBookingTitle, EvenBookingTextArea
} from './Atoms';
import {getAlternativeSlotWithTechnician, getSlotByIdAndTechnician} from "../../../services/bookingServices";
import {getSlotTime} from "../../../services/slotUtils";
import {BookingPage} from "../../../model/BookingPage";
import {BookingSlotUpdate} from "../../../model/BookingSlotUpdate";

type MyProps = {
  comments: string;
  customerTelephone: string;
  bookingPage : BookingPage;
  slotNumber:number;
  technician:string;
  customerName:string;
}

interface DispatchProps{
  updateBooking : typeof updateBooking;
  closeWizard : typeof closeWizardPage;
}

export type EditBookingPageProps = MyProps & DispatchProps

interface  IState{
  slots:any;
  duration: number;
  start:number;
  firstTime : boolean;
}

export const EditBookingPage = (props:EditBookingPageProps)=>{
  const [state,setState] = useState<IState>(undefined);
  const [formData,setFormData] = useState<BookingSlotUpdate>(undefined);
  const [isLoading,setIsLoading] = useState(true);

  useEffect(()=>{
    const currentSlot = getSlotByIdAndTechnician(props.bookingPage.bookings,props.slotNumber,props.technician);

    setState({
      slots: getAlternativeSlotWithTechnician(props.bookingPage.bookings, props.technician, props.slotNumber).map(slot => {
        return {number: slot, slotName: getSlotTime(slot)};
      }),
      start: currentSlot.start,
      duration: currentSlot.end - currentSlot.start + 1,
      firstTime: currentSlot.firstTime
    });
    setFormData({
      customerName: props.customerName,
      customerTelephone: props.customerTelephone,
      start: currentSlot.start,
      end:currentSlot.end,
      comments: props.comments,
      firstTime: currentSlot.firstTime
    });
    setIsLoading(false);
  },[props]);

  const handleInputChange=(event)=> {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setFormData(data=>({...data,[name]:value}));
  };

  const doUpdate= (e)=>{
    e.preventDefault();
    const newBooking = {...formData};
    props.updateBooking(props.bookingPage,props.technician,props.slotNumber,newBooking);
    props.closeWizard();
  };

  const checkFormDirty=()=> {
    if(!formData)
      return false;
    if ((formData.customerName !== props.customerName)
      || (formData.customerTelephone !== props.customerTelephone)
      || (formData.start !== state.start)
      || (formData.comments !== props.comments)
      || (formData.firstTime !== state.firstTime)
    ){
      return true;
    }
    return false;
  };

  const updateStartTime=(e)=>{
    const newStart = parseInt(e.target.value);
    setFormData({...formData,start:newStart,end:newStart+state.duration-1});
  };

  const formDirty = checkFormDirty ();

  if(!isLoading)
  return (
    <EditBookingPageForm onSubmit={doUpdate}>
      <EditBookingTitle>Edit Booking</EditBookingTitle>
      <EditBookingPageBody>
        <EditBookingGrouping>
          <label htmlFor="customerName">Client:</label>
          <TipsyInput id="customerName" name="customerName" value={formData.customerName} onChange={handleInputChange}/>
        </EditBookingGrouping>
        <EditBookingGrouping>
          <label htmlFor="customerTelephone">Telephone:</label>
          <TipsyInput id ="customerTelephone" name ="customerTelephone" value={formData.customerTelephone} onChange={handleInputChange}/>
        </EditBookingGrouping>
        <EditBookingGrouping>
          <label>Start:</label>
          <select onChange={updateStartTime} value={formData.start}>
            {state.slots.map(slot=><option value={slot.number} key={slot.number}>{slot.slotName}</option>)};
          </select>
        </EditBookingGrouping>
        <EditBookingGrouping>
          <EvenBookingTextArea name ="comments" value={formData.comments} onChange={handleInputChange}  />
        </EditBookingGrouping>
      </EditBookingPageBody>
      <EditBookingButtonPanel>
        <div>
          <TipsySubmitButton onClick={doUpdate} text="Update" disabled={!formDirty} />
        </div>
        <div>
          <TipsyButton onClick={props.closeWizard} text="Close"/>
        </div>
      </EditBookingButtonPanel>
    </EditBookingPageForm>
  );
  return (<></>);
};

function mapStateToProps() {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
          closeWizard: () => { dispatch(closeWizardPage());},
          updateBooking : (bookings,technician,slotNumber,newBooking) => {dispatch(updateBooking(bookings,technician,slotNumber,newBooking));}
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBookingPage);
