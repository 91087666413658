import {
  LOGIN_FAILURE, LOGIN_SUCCESS, SET_LOGIN_ORGANIZATION, SET_LOGIN_PASSWORD, SET_LOGIN_ROLES,
  SET_LOGIN_USER
} from '../constants/actionTypes';
import {authenticateUser} from '../api/usersApi';

export const login = (userName,password) =>{
  return dispatch => {

    return authenticateUser(userName,password).then(({roles,organization})=>{
      dispatch({type:SET_LOGIN_ROLES,roles});
      dispatch({type:SET_LOGIN_ORGANIZATION,organization});
      dispatch({type:LOGIN_SUCCESS});
    })
      .catch(error =>{
        dispatch({type:LOGIN_FAILURE,error});
      });
  };
};

export const updateLoginUser = (user) => {
  return dispatch => {
    dispatch({type:SET_LOGIN_USER,userName:user});
  };
};

export const updateLoginPassword = (password) => {
  return dispatch => {
    dispatch({type:SET_LOGIN_PASSWORD,password});
  };
};
