import {CLOSE_ALL_MODALS, CLOSE_MODAL, OPEN_MODAL} from '../constants/actionTypes';

export function closeModalRequest() {
  return {type:CLOSE_MODAL};
}


export function closeAllModalRequest() {
  return {type:CLOSE_ALL_MODALS};
}

export function openModalRequest(renderFunction,modalParameters) {
  return {type:OPEN_MODAL,renderFunction,modalParameters};
}

export function closeModal () {

  return (dispatch) => {
    dispatch(closeModalRequest());
  };
}

export function openModal (renderFunction,modalParameters) {
  return (dispatch) => {
    dispatch(openModalRequest(renderFunction,modalParameters));
  };
}

export function closeAllModals () {

  return (dispatch) => {
    dispatch(closeAllModalRequest());
  };
}
