import React from 'react';
import {ActionMenuText, ActionMenuWrapper} from "./Atoms";
import {WizardButton, WizardButtonContainer} from "../../common/Atoms";
import PropTypes from "prop-types";

const jsUcfirst= (string)=>
{
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const UserAdminActionMenu = ({user,onCloseWindow,onToggleUsersActive}) => {
  const toggleUserActive = ()=>{
    onToggleUsersActive(user);
  };

  return (<ActionMenuWrapper data-testid="user-action-menu">
    <ActionMenuText data-testid="user-action-menu-header"><span>Edit User {jsUcfirst(user.name)}</span></ActionMenuText>
    <WizardButtonContainer>
      <WizardButton data-testid="user-action-activate" type="button" value={user.disabled?"Activate":"Deactivate"} onClick={toggleUserActive} />
      <WizardButton type="button" value="Close" onClick={onCloseWindow} />
    </WizardButtonContainer>
  </ActionMenuWrapper>);
};


UserAdminActionMenu.propTypes = {
  user : PropTypes.object.isRequired,
  onCloseWindow : PropTypes.func.isRequired,
  onToggleUsersActive : PropTypes.func.isRequired
};

export default UserAdminActionMenu;
