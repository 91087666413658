import React from 'react';
import {connect} from 'react-redux';
import {updateCustomerName,updateCustomerFirstTime,updateCustomerTelephone,
  updateTreatment,updateTreatmentType,updateExtraTime,hideCustomerLookup,
  hideTreatmentPanel} from '../../actions/treatmentPanelActions';
import {BOOKING_MODE} from '../../common/modes';
import TipsyButton from '../common/TipsyButton';
import TipsyInput from '../common/TipsyInput';
import PropertyLookupPanel from './PropertyLookupPanel';
import {TreatmentPanelButtonsWrapper, TreatmentPanelGrouping, TreatmentPanelWrapper} from './Atoms';
import TipsyCheckbox from "../common/TipsyCheckbox";
import {TreatmentType} from "../../model/TreatmentType";
import {Treatment} from "../../model/Treatment";
import {ThunkDispatch} from "redux-thunk";

export interface MyProps{
  treatmentType:string;
  treatmentTypes:TreatmentType[];
  mode:number;
  customerName:string;
  customerTelephone:string;
  extraTime:number;
  treatment:Treatment;
  showCustomerLookup:boolean;
  customers:any[];
  firstTime:boolean;
}

export interface DispatchProps {
  updateCustomerName: (name:string) => void;
  updateCustomerFirstTime: (firstTime:boolean) => void;
  updateCustomerTelephone: (telephone:string) => void;
  updateTreatment: (treatment:Treatment) => void;
  updateTreatmentType: (treatment:Treatment) => void;
  updateExtraTime: (extraTime:number) => void;
  hideCustomerLookup: () => void;
  hideTreatmentPanel: () => void;
}

export type TreatmentPanelProps = MyProps&DispatchProps;
export interface TreatmentPanelState{}

export class TreatmentPanel extends React.Component<TreatmentPanelProps,TreatmentPanelState> {
    constructor(props, context) {
        super(props, context);
        this.updateCustomerName = this.updateCustomerName.bind(this);
        this.updateCustomerFirstTime = this.updateCustomerFirstTime.bind(this);
        this.updateCustomerTelephone = this.updateCustomerTelephone.bind(this);
        this.updateTreatment = this.updateTreatment.bind(this);
        this.updateTreatmentType = this.updateTreatmentType.bind(this);
        this.updateExtraTime = this.updateExtraTime.bind(this);
        this.selectSavedCustomer = this.selectSavedCustomer.bind(this);
        this.closeLookup = this.closeLookup.bind(this);
    }

    updateCustomerName (e){
      this.props.updateCustomerName(e.target.value);
    }

    updateCustomerFirstTime (e){
      this.props.updateCustomerFirstTime(e.target.checked);
    }

    updateCustomerTelephone (e){
      this.props.updateCustomerTelephone(e.target.value);
    }

    updateTreatment (e){
      const filteredTreatmentTypes = this.props.treatmentTypes.filter(treatmentType => {
        return treatmentType.name === this.props.treatmentType;
      });
      if(filteredTreatmentTypes.length != 1){
        return;
      }
      const treatmentType = filteredTreatmentTypes [0];
      const filteredTreatments = treatmentType.items.filter(treatment => {
        return treatment.name == e.target.value;
      });
      if(filteredTreatments.length != 1){
        return;
      }
      const treatment = filteredTreatments[0];
      this.props.updateTreatment(treatment);
    }

    updateTreatmentType (e){
      const newTreatmentType = e.target.value;
      this.props.updateTreatmentType(newTreatmentType);
      const filteredTreatments = this.props.treatmentTypes.filter(treatmentType => {
        return treatmentType.name === newTreatmentType;
      });
      if (filteredTreatments.length == 1){
        const treatmentType = filteredTreatments [0];
        this.props.updateTreatment(treatmentType.items[0]);
      }
    }

    updateExtraTime (e){
      this.props.updateExtraTime(Number.parseInt(e.target.value));
    }

    selectSavedCustomer (customer) {
      this.props.updateCustomerName(customer.name);
      this.props.updateCustomerTelephone(customer.telephone);
      this.props.hideCustomerLookup();
    }

    closeLookup(e) {
        const currentTarget = e.currentTarget;

        let that = this;
        setTimeout(function() {
          if (!currentTarget.contains(document.activeElement)) {
            that.props.hideCustomerLookup();
          }
        }, 1);
    }

    render() {
      if(this.props.mode === BOOKING_MODE){
        const showLookup = this.props.showCustomerLookup;
        return (
          <TreatmentPanelWrapper>
            <TreatmentPanelGrouping onBlur={this.closeLookup}>
              <label>Client:</label>
              <TipsyInput value={this.props.customerName} onChange={this.updateCustomerName}/>
              {showLookup && <PropertyLookupPanel onClickItem={this.selectSavedCustomer} filter={this.props.customerName} customers={this.props.customers} />}
            </TreatmentPanelGrouping>
            <TreatmentPanelGrouping>
              <label>Type:</label>
              <select onChange={this.updateTreatmentType} value={this.props.treatmentType}>
                {this.props.treatmentTypes.map(treatment=><option value={treatment.name} key={treatment.name}>{treatment.name}</option>)};
              </select>
            </TreatmentPanelGrouping>
            <TreatmentPanelGrouping>
              <label>Treatment:</label>
              <select onChange={this.updateTreatment} value={this.props.treatment.name}>
                {this.props.treatmentTypes.length > 0 && this.props.treatmentTypes.filter(treatmentType => {
                    return treatmentType.name === this.props.treatmentType;
                }) [0].items.map(treatment=><option value={treatment.name} key={treatment.name}>{treatment.name}</option>)};
              </select>
            </TreatmentPanelGrouping>

            <TreatmentPanelGrouping>
              <label>Telephone:</label>
              <TipsyInput value={this.props.customerTelephone} onChange={this.updateCustomerTelephone}/>
            </TreatmentPanelGrouping>
            <TreatmentPanelGrouping>
              <label>Extra Time:</label>
              <select onChange={this.updateExtraTime} value={this.props.extraTime}>
                <option value="0">*None*</option>
                <option value="1">15 Minutes</option>
                <option value="2">30 Minutes</option>
                <option value="3">45 Minutes</option>
                <option value="4">1 Hour</option>
              </select>
            </TreatmentPanelGrouping>
            <TreatmentPanelGrouping>
              <label>New Client:</label>
              <TipsyCheckbox value={this.props.firstTime} onChange={this.updateCustomerFirstTime}/>
            </TreatmentPanelGrouping>
            <TreatmentPanelButtonsWrapper>
              <TipsyButton text="Cancel" onClick={this.props.hideTreatmentPanel}/>
            </TreatmentPanelButtonsWrapper>
          </TreatmentPanelWrapper>
        );
      }else{
        return <div />;
      }
    }
}

function mapStateToProps(state):MyProps {
    return {
        mode: state.mode,
        customerName: state.treatmentPanel.customerName,
        customerTelephone: state.treatmentPanel.customerTelephone,
        extraTime: state.treatmentPanel.extraTime,
        treatmentType  :state.treatmentPanel.treatmentType,
        treatmentTypes : state.treatments,
        treatment : state.treatmentPanel.treatment,
        showCustomerLookup : state.treatmentPanel.showCustomerLookup,
        customers : state.customers,
        firstTime : state.firstTime
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<{}, {}, any>):DispatchProps {
    return {
      updateCustomerName : (customerName)=>{dispatch(updateCustomerName(customerName));},
      updateCustomerFirstTime : (firstTime)=>{dispatch(updateCustomerFirstTime(firstTime));},
      updateCustomerTelephone : (telephone)=>{dispatch(updateCustomerTelephone(telephone));},
      updateTreatment : (treatment)=>{dispatch(updateTreatment(treatment));},
      updateTreatmentType : (treatmentType)=>{dispatch(updateTreatmentType(treatmentType));},
      updateExtraTime : (extraTime)=>{dispatch(updateExtraTime(extraTime));},
      hideCustomerLookup : ()=>{dispatch(hideCustomerLookup());},
      hideTreatmentPanel : ()=>{dispatch(hideTreatmentPanel());},
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentPanel);
