import initialState from './initialState';
import {SET_TECHNICIAN_WIZARD_PAGE} from '../constants/actionTypes';

export default function (state = initialState.editTechnician, action) {
  switch (action.type){
    case SET_TECHNICIAN_WIZARD_PAGE:
        return {...state,page:action.page};
    default :
      return state;
  }
}
